<template>
  <div>
    <div v-if="onlyView" class="symbol  symbol-30px">
      <div v-if="selectIcon">
        <i v-if="isElementIcon(selectIcon)" :class="selectIcon"></i>
        <feather-icon v-else :icon="selectIcon" class="symbol-label text-success" size="12"></feather-icon>
      </div>
      <span v-else class="text-muted">暂无</span>
    </div>
    <b-input-group v-else :disabled="disabled">
      <b-form-input placeholder="请选择菜单图标" v-model="selectIcon" disabled/>
      <b-input-group-append >
        <div class="btn btn-light-primary" :class="disabled ? 'disabled' : ''" @click="show = !disabled && true">
          <div v-if="selectIcon">
            <i v-if="isElementIcon(selectIcon)" :class="selectIcon"></i>
            <feather-icon v-else :icon="selectIcon"></feather-icon>
          </div>
          <feather-icon v-else icon="SearchIcon"></feather-icon>
        </div>
      </b-input-group-append>
    </b-input-group>
    <b-modal
      size="xl"
      :visible="show"
      title-class="x-text-bold"
      hide-footer
      title="选择图标"
      centered
      body-class="x-scrollbar x-min-h-800px"
      scrollable
      @hidden="hide"
      @ok="enter">
      <b-tabs align="center">
        <b-tab :title="`Feather ( ${featherIcons.length}个 )`" lazy :active="isElementIcon(selectIcon)">
          <b-row class="d-flex justify-content-center">
            <b-col cols="12" md="8">
              <b-input-group class="mt-1 mb-2">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" class="text-primary"/>
                </b-input-group-prepend>
                <b-form-input class="ml-n1"  v-model="filterFeatherName" placeholder="搜索Feather按钮"/>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row class="ml-2 mr-2">
            <b-col cols="6" md="2" class="x-anim-fade-in" @click="clearHandle()">
              <div class="card card-body x-dashed-border x-border-color-danger x-anim-card d-flex flex-column align-items-center justify-content-center btn btn-light-danger">
                <feather-icon icon="XIcon" size="24"></feather-icon>
                <span class="mt-50 ">清除</span>
              </div>
            </b-col>
            <b-col v-for="(icon,index) in featherIcons"  :key="index" cols="6" md="2" class="x-anim-fade-in" @click="clickHandle(icon)">
              <div :class="selectIcon === icon ? 'x-border-color-primary bg-primary' : ''"
                class="card card-body x-border x-anim-card d-flex flex-column align-items-center justify-content-center" :key="index">
                <feather-icon :icon="icon" size="24" :class="selectIcon === icon ? 'text-white' : 'text-primary'"></feather-icon>
                <span class="mt-50" :class="selectIcon === icon ? 'text-white' : 'text-muted'">{{ icon }}</span>
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab :title="`Element ( ${elIcons.length}个 )`" lazy :active="isElementIcon(selectIcon)">
          <b-row class="d-flex justify-content-center">
            <b-col cols="12" md="8">
              <b-input-group class="mt-1 mb-2">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" class="text-primary"/>
                </b-input-group-prepend>
                <b-form-input class="ml-n1"  v-model="filterElName" placeholder="搜索Element按钮"/>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row class="ml-2 mr-2">
            <b-col cols="6" md="2" class="x-anim-fade-in" @click="clearHandle()">
              <div class="card card-body x-dashed-border x-border-color-danger x-anim-card d-flex flex-column align-items-center justify-content-center btn btn-light-danger">
                <feather-icon icon="XIcon" size="24"></feather-icon>
                <span class="mt-50 ">清除</span>
              </div>
            </b-col>
            <b-col v-for="(icon,index) in elIcons"  :key="index" cols="6" md="2"  class="x-anim-fade-in" @click="clickHandle(icon)">
              <div :class="selectIcon === icon ? 'x-border-color-primary bg-primary' : ''"
                   class="card card-body x-border x-anim-card d-flex flex-column align-items-center justify-content-center" :key="index">
                <span :class="`${icon} font-medium-5 ${selectIcon === icon ? 'text-white' : 'text-primary'}`"></span>
                <span class="mt-50 x-text-cut" :class="selectIcon === icon ? 'text-white' : 'text-muted'">{{ getElementIconName(icon) }}</span>
              </div>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BRow, BCol, BTabs, BTab, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormInput } from 'bootstrap-vue'
import { useClipboard } from '@vueuse/core'
import FeatherIcons from './featrer-icon'
import ElmentIcons from './element'

export default {
  name: 'FeatherIconSelector',
  components: {
    BModal,
    BRow,
    BCol,
    BTabs,
    BTab,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // 只显示
    onlyView: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    copyable: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    visible() {
      this.show = this.visible
    },
    value() {
      this.selectIcon = this.value
    },
  },
  computed: {
    featherIcons() {
      const filterFeatherName = this.filterFeatherName.toLowerCase()
      return FeatherIcons.filter(item => item.toLowerCase().includes(filterFeatherName))
    },
    elIcons() {
      const filterElName = this.filterElName.toLowerCase()
      return ElmentIcons.filter(item => item.toLowerCase().includes(filterElName))
    },
  },
  model: {
    prop: 'value',
    event: 'update:value',
  },
  data() {
    return {
      // featherIcons: FeatherIcons,
      // elIcons: ElmentIcons,
      selectIcon: this.value,
      filterFeatherName: '',
      filterElName: '',
      show: this.visible,
    }
  },
  methods: {
    clickHandle(icon) {
      this.selectIcon = icon
      this.$emit('update:value', this.selectIcon)
      if (this.copyable) {
        const { copy } = useClipboard()
        copy(icon).then(() => {
          this.$xtoast.success('拷贝成功！')
        })
      }
      if (this.selectable) {
        this.$emit('select', icon)
        this.hide()
      }
    },
    hide() {
      this.show = false
      this.$emit('update:visible', false)
      this.$emit('hide')
    },
    enter() {
      this.$emit('update:visible', false)
      this.$emit('enter')
    },
    isElementIcon(icon) {
      return icon && icon.substr(0, 7) === 'el-icon'
    },
    getElementIconName(icon) {
      return icon && icon.substr(8)
    },
    clearHandle() {
      this.clickHandle('')
    },
  },
}
</script>

<style scoped>

</style>
