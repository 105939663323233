<template>
  <div>
    <x-el-table
      :options="options"
      @search="searchHandle"
      @rowEdit="rowEdit">
      <template slot="form-content" slot-scope="scope">
        <froala-editor :value="scope.row.content" @blur="editBlur"/>
      </template>
    </x-el-table>
  </div>
</template>

<script>

import XElTable from '@core/components/cx/table/XElTable.vue'
import froalaEditor from '@core/components/cx/emailEditor/froalaEditor.vue'
import {
  getList, submit,
} from '@/api/data/deal'

export default {
  name: 'Dept',
  components: {
    XElTable,
    froalaEditor,
  },
  data() {
    return {
      value: undefined,
      options: {
        formWidth: 'xl',
        addBtn: false,
        actions: [
          { action: 'view', tip: '查看', icon: 'EyeIcon', permission: 'param_view' },
          { action: 'edit', tip: '编辑', icon: 'EditIcon', permission: 'param_edit' },
        ],
        columns: [
          {
            label: '协议名称',
            prop: 'name',
            searchShow: false,
            addShow: false,
            editShow: false,
            viewShow: false,
          },
          {
            label: '协议内容',
            prop: 'content',
            searchShow: false,
            addShow: false,
            rowShow: false,
          },
        ],
      },
    }
  },
  methods: {
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    editBlur(value) {
      let msg = value.replace('<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>', '') // 执行替换成空字符
      this.value = msg
    },
    rowEdit(data, done) {
      data.content = this.value
      submit(data).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
  },
}
</script>

<style scoped>

</style>
