import Vue from 'vue'

export function setUpload(data, column, isAdd) {
  column.upload.name = column.upload.name ? column.upload.name : 'file'
  column.upload.limit = column.upload.limit ? column.upload.limit : 1
  column.upload.beforeUpload = column.upload.beforeUpload ? column.upload.beforeUpload : () => {}
  column.upload.onRemove = column.upload.onRemove ? column.upload.onRemove : () => {}
  column.upload.onSuccess = column.upload.onSuccess ? column.upload.onSuccess : () => {}
  column.upload.onError = column.upload.limit ? column.upload.onError : () => this.$xtoast.error('上传失败')
  if (isAdd || !data[column.prop]) {
    data[column.prop] = []
  } else if (typeof data[column.prop] === 'string') {
    data[column.prop] = [{
      name: Vue.prototype.$x.getFilename(data[column.prop]),
      url: data[column.prop],
    }]
  }
}

export const setAddFormData = (data, item, expandAll) => {
  if (item.children) {
    item.____visible = expandAll !== false
    data[item.prop] = {}
    item.children.forEach(child => setAddFormData(data[item.prop], child))
  } else if (item.type === 'checkbox') {
    data[item.prop] = []
  } else if (item.type === 'switch') {
    data[item.prop] = false
  } else if (item.type === 'repeater') {
    data[item.prop] = [{}]
  } else if (item.type === 'upload') {
    setUpload(data, item, true)
  } else {
    data[item.prop] = null
  }
}
