<template>
  <div>
    <form-wizard
      :color="$x.color.primary"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="提交"
      next-button-text="下一步"
      back-button-text="上一步"
      class="mb-3"
      @on-complete="formSubmitted"
    >

      <!-- 基础信息 -->
      <tab-content
        title="基础信息"
        :before-change="validationForm"
      >
        <x-form-validator ref="baseForm">
          <b-row class="vw-90">
            <b-col class="col-sm-6">
              <x-form-feild label="所属租户" require >
                <v-select
                  style="width: 14.5rem"
                  v-model="formData.tenantId"
                  :options="tenants"
                  label="tenantName"
                  placeholder="请选择所属租户"
                  :reduce=" option => option.tenantId"
                  :disabled="isView"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </x-form-feild>
            </b-col>
            <b-col class="col-sm-6">
              <x-form-feild label="用户平台" require>
                <v-select
                  style="width: 14.5rem"
                  v-model="formData.userType"
                  :options="userTypes"
                  label="dictValue"
                  placeholder="请选择用户平台"
                  :reduce=" option => option.dictKey"
                  :disabled="isView"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </x-form-feild>
            </b-col>
          </b-row>
          <b-row>
            <b-col :cols="isNew ? '6' : '10'">
              <x-form-feild label="登录账号" require>
                <b-form-input
                  v-model="formData.account"
                  placeholder="请输入登录账号"
                  :readonly="isView"
                />
              </x-form-feild>
            </b-col>
            <b-col cols="6">
              <x-form-feild label="用户密码" v-if="isNew ? 'require' : ''"  v-show="isNew">
                <b-form-input
                  v-model="formData.password"
                  placeholder="请输入用户密码"
                  :readonly="isView"
                />
              </x-form-feild>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <x-form-feild label="用户确认密码" v-if="isNew ? 'require' : ''" v-show="isNew">
                <b-form-input
                  v-model="formData.password2"
                  placeholder="请输入用户确认密码"
                />
              </x-form-feild>
            </b-col>
          </b-row>
        </x-form-validator>
      </tab-content>

      <!-- 详细信息 -->
      <tab-content
        title="详细信息"
        :before-change="validationFormInfo"
      >
        <x-form-validator ref="detailForm">
          <b-row>
            <b-col cols="6">
              <x-form-feild label="用户昵称" require>
                <b-form-input
                  v-model="formData.name"
                  placeholder="请输入用户昵称"
                  :readonly="isView"
                />
              </x-form-feild>
            </b-col>
            <b-col cols="6">
              <x-form-feild label="用户姓名" require>
                <b-form-input
                  v-model="formData.realName"
                  placeholder="请输入用户姓名"
                  :readonly="isView"
                />
              </x-form-feild>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <x-form-feild label="手机号码" phone>
                <b-form-input
                  type="number"
                  v-model="formData.phone"
                  placeholder="请输入手机号码"
                  :readonly="isView"
                />
              </x-form-feild>
            </b-col>
            <b-col cols="6">
              <x-form-feild label="电子邮箱" email>
                <b-form-input
                  type="email"
                  v-model="formData.email"
                  placeholder="请输入电子邮箱"
                  :readonly="isView"
                />
              </x-form-feild>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <x-form-feild label="用户性别">
                <v-select
                  style="width: 14.5rem"
                  v-model="formData.sex"
                  :options="sexs"
                  label="sexName"
                  placeholder="请选择用户性别"
                  :reduce=" option => option.sex"
                  :disabled="isView"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </x-form-feild>
            </b-col>
            <b-col cols="6">
              <x-form-feild label="用户生日">
                <el-date-picker
                  v-model="formData.birthday"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="请选择用户生日"
                  :disabled="isView">
                </el-date-picker>
              </x-form-feild>
            </b-col>
          </b-row>
        </x-form-validator>
      </tab-content>

      <!-- 职责信息  -->
      <tab-content
        title="职责信息"
        :before-change="validationFormAddress"
      >
        <x-form-validator ref="dutyForm">
          <b-row>
            <b-col cols="6">
              <x-form-feild label="用户编号">
                <b-form-input
                  v-model="formData.code"
                  placeholder="请输入用户编号"
                  :readonly="isView"
                />
              </x-form-feild>
            </b-col>
            <b-col cols="6">
              <x-form-feild label="所属角色" require>
                <el-cascader
                  v-model="formData.roleId"
                  :options="roles"
                  :props="{ label: 'title', value: 'id', multiple: true, emitPath: false }"
                  :disabled="isView">
                </el-cascader>
              </x-form-feild>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <x-form-feild label="所属机构" require>
                <el-cascader
                  v-model="formData.deptId"
                  :options="depts"
                  :props="{ label: 'title', value: 'id', multiple: true, emitPath: false }"
                  :disabled="isView">
                </el-cascader>
              </x-form-feild>
            </b-col>
            <b-col cols="6">
              <x-form-feild label="所属岗位" require>
                <v-select
                  style="width: 14.5rem"
                  v-model="formData.postId"
                  :options="posts"
                  multiple
                  label="postName"
                  placeholder="请选择用户性别"
                  :reduce=" option => option.id"
                  :disabled="isView"
                >
                  <template slot="no-options">
                    {{ $t('noData') }}
                  </template>
                </v-select>
              </x-form-feild>
            </b-col>
          </b-row>
        </x-form-validator>
      </tab-content>
    </form-wizard>

  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { getTenants, getUserType, roleTree, deptTree, getPosts, add, update } from '@/api/system/user'
import {
  BRow,
  BCol,
  BFormInput,
} from 'bootstrap-vue'
import { codeIcon } from './code'

export default {
  components: {
    XFormFeild,
    XFormValidator,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormInput,
    vSelect,
  },
  props: {
    formData: {
      type: Object,
      default: () => {
      },
    },
    isView: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 岗位列表
      posts: [],
      // 机构树
      depts: [],
      // 角色树
      roles: [],
      sexs: [
        {
          sexName: '男',
          sex: 1,
        },
        {
          sexName: '女',
          sex: 2,
        },
        {
          sexName: '未知',
          sex: 3,
        },
      ],
      // 租户
      tenants: [],
      // 平台
      userTypes: [],
      codeIcon,
    }
  },
  created() {
    getTenants().then(resp => {
      this.tenants = resp.data.data
    })
    getUserType().then(resp => {
      this.userTypes = resp.data.data
    })
    roleTree().then(resp => {
      this.roles = resp.data.data
    })
    deptTree().then(resp => {
      this.depts = resp.data.data
    })
    getPosts().then(resp => {
      this.posts = resp.data.data
    })
  },
  methods: {
    formSubmitted() {
      if (!this.isView) {
        let deptIds = ''
        let postIds = ''
        let roleIds = ''
        this.formData.roleId.forEach((item, index) => {
          if (index < this.formData.roleId.length - 1) {
            roleIds += item + ','
          } else {
            roleIds += item
          }
        })
        this.formData.postId.forEach((item, index) => {
          if (index < this.formData.postId.length - 1) {
            postIds += item + ','
          } else {
            postIds += item
          }
        })
        this.formData.deptId.forEach((item, index) => {
          if (index < this.formData.deptId.length - 1) {
            deptIds += item + ','
          } else {
            deptIds += item
          }
        })
        this.formData.postId = postIds
        this.formData.roleId = roleIds
        this.formData.deptId = deptIds
        if (this.isNew) {
          add(this.formData).then(resp => {
            if (resp.data.success) {
              this.$xtoast.success('编辑成功！')
              this.$emit('editSuccess')
            }
          })
        } else {
          update(this.formData).then(resp => {
            if (resp.data.success) {
              this.$xtoast.success('编辑成功！')
              this.$emit('editSuccess')
            }
          })
        }
      } else {
        this.$emit('editSuccess')
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        if (this.isView) {
          resolve(true)
        } else {
          this.$refs.baseForm.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        }
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        if (this.isView) {
          resolve(true)
        } else {
          this.$refs.detailForm.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        }
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        if (this.isView) {
          resolve(true)
        } else {
          this.$refs.dutyForm.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
