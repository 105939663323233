<template>
  <div >
    <x-el-table
      :permissions="{ add: 'notice_add' }"
      :options="options"
      @search="searchHandle"
      @beforeShow="beforeShow"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete"
    ></x-el-table>
  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import { getList, remove, update, add } from '@/api/workbench/notice'

export default {
  name: 'Notice',
  components: {
    XElTable,
  },
  data() {
    return {
      options: {
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon', permission: 'notice_view' },
          { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon', permission: 'notice_edit' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon', permission: 'notice_delete' },
        ],
        columns: [
          {
            label: '通知标题',
            prop: 'title',
            rules: {
              rule: 'required',
              message: '请选择服务商',
            },
          },
          {
            label: '通知类型',
            prop: 'category',
            type: 'select',
            rowSelect: true,
            dictUrl: '/apollo-upms/dict/dictionary?code=notice',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            rules: {
              rule: 'required',
              message: '请选择通知类型',
            },
          },
          {
            label: '通知日期',
            prop: 'releaseTime',
            type: 'datetime',
            searchShow: false,
            placeholder: '请选择通知日期',
            pickerOptions: {},
            rules: {
              rule: 'required',
              message: '请选择通知日期',
            },
          },
          // {
          //   label: '通知日期',
          //   prop: 'releaseRangeTime',
          //   type: 'datetimerange',
          //   placeholder: '请选择通知日期',
          //   pickerOptions: {},
          //   rowShow: false,
          //   addShow: false,
          //   editShow: false,
          // },
          {
            label: '通知内容',
            prop: 'content',
            type: 'textarea',
            searchShow: false,
            rowShow: false,
          },
        ],
      },
    }
  },
  methods: {
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.records.length)
      })
    },
    beforeShow(type, formData) {
      if (type.isView || type.isEdit) {
        formData.category = formData.category ? formData.category.toString() : formData.category
      }
    },
    addHandle(data, done) {
      add(data).then(() => {
        done()
      })
    },
    rowEdit(data, done) {
      update(data).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
  },
}
</script>

<style scoped>

</style>
