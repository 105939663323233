import request from '@/auth/jwt/useJwt'

export function getList(current, size, params) {
  return request.axiosIns({
    url: '/apollo-upms/dict-biz/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}

export function getParentList(current, size, params) {
  return request.axiosIns({
    url: '/apollo-upms/dict-biz/parent-list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}

export function getChildList(current, size, parentId, params) {
  return request.axiosIns({
    url: '/apollo-upms/dict-biz/child-list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
      parentId,
    },
  })
}

export function remove(ids) {
  return request.axiosIns({
    url: '/apollo-upms/dict-biz/remove',
    method: 'post',
    params: {
      ids,
    },
  })
}

export function add(row) {
  return request.axiosIns({
    url: '/apollo-upms/dict-biz/submit',
    method: 'post',
    data: row,
  })
}

export function update(row) {
  return request.axiosIns({
    url: '/apollo-upms/dict-biz/submit',
    method: 'post',
    data: row,
  })
}

export function getDict(id) {
  return request({
    url: '/apollo-upms/dict-biz/detail',
    method: 'get',
    params: {
      id,
    },
  })
}
export function getDictTree() {
  return request({
    url: '/apollo-upms/dict-biz/tree?code=DICT',
    method: 'get',
  })
}

export function getDictionary(params) {
  return request({
    url: '/apollo-upms/dict-biz/dictionary',
    method: 'get',
    params,
  })
}
