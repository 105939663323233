<template>
  <div>
    <x-el-table
      ref="table"
      :options="options"
      @search="searchHandle">
      <template slot="amount" slot-scope="scope">
        <div>
          <span :class="scope.row.type === 4 ? 'text-danger' : 'text-success'" class="x-text-bold font-medium-2">
            {{(scope.row.type === 4 ? '- ' : '+ ') + (scope.row.amount > 0 ? scope.row.amount : '0') }}
          </span>
          <span class="text-secondary font-small-4 x-text-normal"> /元 </span>
        </div>
        <div v-if="scope.row.type === 5" >
          <div class="d-flex align-items-center">
            <span class="text-secondary font-small-4 x-text-normal">销售机器：</span>
            <span :class="scope.row.type === 4 ? 'text-danger' : 'text-success'" class="x-text-bold font-medium-2">
            {{ scope.row.count }}
          </span>
            <span class="text-secondary font-small-4 x-text-normal ml-50"> 台 </span>
            <span :class="scope.row.personal ? 'badge-light-success' : 'badge-light-danger'" class="badge ml-50">{{ scope.row.personal ? '个人版' : '商业版' }}</span>
          </div>
          <div>
            <span class="text-secondary font-small-4 x-text-normal">到期时间：</span>
            <span class="">{{ scope.row.validTime }}</span>
          </div>
          <span v-if="isExpiredSoon(scope.row.validTime)" class="text-danger x-text-bold">即将到期，请提醒续费！</span>
        </div>
      </template>
      <template slot="origin" slot-scope="scope">
          <b-avatar v-viewer :src="scope.row.avatar" variant="light-primary"></b-avatar>
          <span class="ml-1 text-primary x-link x-text-normal" @click="toProfile(scope.row)">{{ scope.row.nickName }}</span>
      </template>
      <template slot="actions">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-tooltip.hover="`此操作会重新按天统计计算所选时间内的商户和平台账单，请谨慎操作`" variant="danger" @click="showReStatisticModal">重新统计</b-button>
      </template>
    </x-el-table>
    <b-modal
      size="md"
      :visible="showStatisticModal"
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      ok-title="提交"
      cancel-title="取消"
      cancel-variant="flat-secondary"
      title="重新计算"
      centered
      @hidden="hideReStatisticModal"
      @ok="enterReStatisticModal">
      <x-table-form v-if="showStatisticModal" v-model="timeRangeFormData" ref="tableForm" :columns="timeRangeColumns">
      </x-table-form>
    </b-modal>
  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import XTableForm from '@core/components/cx/table/XTableForm.vue'
import { BAvatar, BButton, BModal } from 'bootstrap-vue'
import {
  getList,
} from '@/api/data/finance'
import {
  reStatisticsDashboard,
} from '@/api/dashboards'

export default {
  components: {
    XElTable,
    BAvatar,
    BButton,
    BModal,
    XTableForm,
  },
  props: {
    queryOrigin: {
      type: String,
      default: undefined,
    },
    queryMtId: {
      type: String,
      default: undefined,
    },
    queryType: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      showStatisticModal: false,
      timeRangeFormData: {},
      timeRangeColumns: [
        {
          label: '开始时间',
          prop: 'from',
          labelShow: true,
          type: 'date',
          format: 'yyyy-MM-dd',
          placeholder: '请选择开始时间',
          rules: {
            rule: 'required',
            message: '请选择开始时间',
          },
        },
        {
          label: '结束时间',
          prop: 'to',
          labelShow: true,
          type: 'date',
          format: 'yyyy-MM-dd',
          placeholder: '请选择结束时间',
        },
      ],
      options: {
        hideStyleSwitch: true,
        addBtn: false,
        actions: [
        ],
        columns: [
          {
            label: '流水号',
            prop: 'id',
            searchShow: false,
          },
          {
            label: '支付流水号',
            prop: 'orderId',
            searchShow: true,
          },
          // {
          //   label: '商户',
          //   prop: 'mtId',
          //   type: 'select',
          //   rowShow: false,
          //   searchShow: true,
          //   dictUrl: '/apollo-jxz/gm/merchant/all',
          //   props: {
          //     label: 'nickName',
          //     value: 'id',
          //   },
          // },
          {
            label: '来源',
            prop: 'origin',
            type: 'select',
            searchShow: true,
            dictUrl: '/apollo-jxz/gm/merchant/all',
            props: {
              label: 'nickName',
              value: 'id',
            },
          },
          {
            label: '类型',
            prop: 'type',
            searchShow: !this.queryType,
            type: 'select',
            rowSelect: true,
            dictData: [{ value: 4, label: '提现' }, { value: 5, label: '软件销售' }, { value: 6, label: '运营分销' }, { value: 8, label: '店铺运营' }, { value: 9, label: '其他' }],
            // dictUrl: '/apollo-upms/dict-biz/dictionary?code=billType',
            selectVariant: propValue => {
              if (propValue === 4) {
                return 'badge badge-light-danger'
              }
              if (propValue === 5) {
                return 'badge badge-light-success'
              }
              return 'badge badge-light-primary'
            },
          },
          {
            label: '金额',
            prop: 'amount',
            searchShow: true,
          },
          {
            label: '状态',
            prop: 'state',
            searchShow: true,
            type: 'select',
            dictUrl: '/apollo-upms/dict-biz/dictionary?code=withdrawalState',
            rowSelectDot: true,
            selectVariant: value => {
              if (value === 1) {
                return 'text-warning'
              }
              if (value === 2) {
                return 'text-success'
              }
              if (value === 3) {
                return 'text-danger'
              }
              return 'text-success'
            },
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
          },
          {
            label: '时间',
            prop: 'createTime',
            searchShow: false,
          },
          {
            label: '时间开始',
            type: 'datetime',
            prop: 'startTime',
            rowShow: false,
            searchShow: true,
          },
          {
            label: '时间结束',
            type: 'datetime',
            prop: 'endTime',
            rowShow: false,
            searchShow: true,
          },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    // 少于7天
    isExpiredSoon(time) {
      let expiredTime = new Date(time).getTime()
      return (expiredTime - new Date().getTime()) < 604800000
    },
    searchHandle(page, params, done) {
      if (this.queryOrigin) {
        params.origin = this.queryOrigin
      }
      if (this.queryMtId) {
        params.mtId = this.queryMtId
      }
      if (this.queryType) {
        params.type = this.queryType
      }
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    toProfile(row) {
      this.$router.push(row.type === 1 ? `/data/member/profile?id=${row.origin}` : `/data/merchant/profile?id=${row.origin}`)
    },
    showReStatisticModal() {
      this.showStatisticModal = true
      this.timeRangeFormData = {
        from: undefined,
        to: undefined,
      }
    },
    hideReStatisticModal() {
      this.showStatisticModal = false
    },
    enterReStatisticModal(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.tableForm.validate().then(result => {
        if (result) {
          reStatisticsDashboard(this.timeRangeFormData)
          this.$xtoast.success('提交成功！')
          this.showStatisticModal = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
</style>
