<template>
  <div>
    <x-el-table
      ref="table"
      :options="options"
      @search="searchHandle"
      @rowOpen="rowOpen"
      @rowScale="rowScale"
      @rowEdit="rowEdit"
      @rowActive="rowActive"
      @rowDelete="rowDelete">
      <template slot="avatar" slot-scope="scope">
        <b-avatar v-viewer :src="scope.row.avatar" variant="light-primary" size="50"></b-avatar>
      </template>
      <template slot='scale' slot-scope='scope'>
        <b-badge variant="light-primary">
          {{ scope.row.scale > 0 ? (scope.row.scale + ' %') : '无'}}
          <span class="ml-50 mr-50 x-text-bold">/</span>
          {{ scope.row.softwareScale > 0 ? (scope.row.softwareScale + ' %') : '无'}}
        </b-badge>
      </template>
      <template slot='authConfig' slot-scope='scope'>
        <div>
          <div class="x-text-bold font-medium-4">
            <span class="text-secondary font-small-4 x-text-normal">剩余：</span>
            {{ scope.row.authRemainCount }}
            <span class="text-secondary font-small-4 x-text-normal"> /次 </span>
          </div>
          <div class="x-text-bold text-secondary font-medium-4">
            <span class="text-secondary font-small-4 x-text-normal">已用：</span>
            {{ scope.row.authUsedCount }}
            <span class="text-secondary font-small-4 x-text-normal"> /次 </span>
          </div>
        </div>
      </template>
      <template slot="form-avatar" slot-scope="scope">
        <b-avatar v-viewer :src="scope.row.avatar" variant="light-primary" size="50"></b-avatar>
      </template>
      <template slot="nickName" slot-scope="scope">
        <div>
          <div class="text-primary x-text-normal x-link x-text-bold" @click="() => $refs.table.actionClick(options.actions[0],scope)">{{ scope.row.nickName }}</div>
          <div v-tooltip.hover="getReferrerId(scope.row)"
               v-clipboard:copy="getReferrerId(scope.row)"
               v-clipboard:success="$x.onCopy"
               v-clipboard:error="$x.onError"
               class="text-danger font-small-1 x-link d-flex align-items-center">点击拷贝分销ID<feather-icon size="16" icon="CopyIcon" class="ml-50"></feather-icon></div>
        </div>
      </template>
      <template slot="balance" slot-scope="scope">
        <div class="x-text-bold text-success font-medium-4">
          <span class="text-secondary font-small-4 x-text-normal"> ￥ </span>{{ scope.row.balance }}
        </div>
      </template>
      <template slot="topLimit" slot-scope="scope">
        <div class="x-text-bold text-primary font-medium-4">
          {{ scope.row.topLimit }}<span class="text-secondary font-small-4 x-text-normal"> /台 </span>
        </div>
      </template>
    </x-el-table>

    <b-modal
      cancel-variant="outline-secondary"
      ok-title="开通"
      cancel-title="取消"
      centered
      title="配置套餐"
      :visible.sync="openFlag"
      @hidden="sideHidden"
      @ok="handleOpen">
      <div class="">
         <x-form-validator ref="refFormObserver">
            <x-form-feild label="开通机器数量（台）" class="pb-1" require>
              <b-form-input placeholder="请输入开通机器数量" v-model="openCount"></b-form-input>
            </x-form-feild>
            <x-form-feild label="开通有效时间（月）" class="pb-1" require>
              <b-form-input placeholder="请输入有效时间" v-model="openMonth"></b-form-input>
            </x-form-feild>
         </x-form-validator>
      </div>
    </b-modal>

    <b-modal
      cancel-variant="outline-secondary"
      ok-title="设置"
      cancel-title="取消"
      centered
      title="抽成比例设置"
      :visible.sync="scaleFlag"
      @hidden="scaleHidden"
      @ok="handleScale">
      <div class="">
         <x-form-validator ref="scaleFormObserver">
            <x-form-feild label="运营抽成比例（%）" class="pb-1">
              <b-form-input v-model="scaleValue" type="range" min="0" max="80" step="0.1" placeholder="请选择运营抽成比例（%）"></b-form-input>
              <b-form-spinbutton  v-model="scaleValue" min="0" max="80" step="0.1" placeholder="请选择运营抽成比例（%）"></b-form-spinbutton>
            </x-form-feild>
            <x-form-feild label="软件抽成比例（%）" class="pb-1">
              <b-form-input v-model="softwareScaleValue" type="range" min="0" max="80" step="0.1" placeholder="请选择软件抽成比例（%）"></b-form-input>
              <b-form-spinbutton  v-model="softwareScaleValue" min="0" max="80" step="0.1" placeholder="请选择软件抽成比例（%）"></b-form-spinbutton>
            </x-form-feild>
         </x-form-validator>
      </div>
    </b-modal>

  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import { Base64 } from 'js-base64'

import {
  BAvatar, BModal, BFormInput, BFormSpinbutton, BBadge,
} from 'bootstrap-vue'
import {
  getList, remove, edit, open, setScale, setActiveCode,
} from '@/api/data/merchant'

export default {
  name: 'Merchant',
  components: {
    XElTable,
    BAvatar,
    BModal,
    BFormInput,
    XFormFeild,
    XFormValidator,
    BFormSpinbutton,
    BBadge,
  },
  data() {
    return {
      // 设置分销比例的id
      scaleId: undefined,
      scaleValue: 0,
      softwareScaleValue: 0,
      scaleFlag: false,
      openCount: undefined,
      openMonth: undefined,
      openFlag: false,
      openRow: undefined,
      options: {
        addBtn: false,
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon', permission: 'user_view', url: '/data/merchant/profile?id={id}' },
          // { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon', permission: 'user_edit' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon', permission: 'user_delete' },
          { action: 'scale', tip: '运营抽成比例设置', icon: 'SlidersIcon', customXForm: false },
          { action: 'open', tip: '开通', icon: 'CheckCircleIcon', name: '开通', customXForm: false },
          { action: 'active', tip: '激活码生成', name: '激活码', icon: 'ColumnsIcon' },
        ],
        actionFilter: (action, row) => {
          if (!row.activeCode) {
            return action === this.$x.biz.FormType.VIEW || action === this.$x.biz.FormType.DELETE || action === 'open' || action === 'scale' || action === 'active'
          }
          return action === this.$x.biz.FormType.VIEW || action === this.$x.biz.FormType.DELETE || action === 'open' || action === 'scale'
        },
        columns: [
          {
            label: '头像',
            prop: 'avatar',
            searchShow: false,
          },
          {
            label: '昵称',
            prop: 'nickName',
            searchShow: true,
          },
          {
            label: '手机号',
            prop: 'phone',
            searchShow: false,
          },
          {
            label: '推荐人',
            prop: 'referrerId',
            searchShow: true,
            type: 'select',
            rowSelect: true,
            dictUrl: '/apollo-jxz/gm/merchant/all',
            props: {
              label: 'nickName',
              value: 'id',
            },
            selectVariant: propValue => {
              if (propValue === -1) {
                return 'badge badge-light-secondary'
              }
              return 'badge badge-light-primary'
            },
            getDictLabel: option => {
              if (typeof option === 'object') {
                return option.label ? option.label : '无'
              }
              return option
            },
          },
          {
            label: '余额',
            prop: 'balance',
            searchShow: false,
          },
          {
            label: '机器数量',
            prop: 'topLimit',
            searchShow: false,
          },
          {
            label: '运营/软件抽成比例',
            prop: 'scale',
            searchShow: false,
          },
          {
            label: '人脸核身',
            prop: 'authConfig',
            searchShow: false,
          },
          {
            label: '激活码',
            prop: 'activeCode',
            searchShow: false,
          },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      let paramCopy = {}
      if (params.nickName && params.nickName !== '') {
        paramCopy.nickName = params.nickName
      }
      if (params.referrerId && params.referrerId !== '') {
        paramCopy.referrerId = params.referrerId
      }
      getList(page.pageIndex, page.pageSize, paramCopy).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    rowEdit(data, done) {
      edit(data).then(() => {
        done()
      })
    },
    rowActive(data, done) {
      setActiveCode(data.id).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    rowOpen(data) {
      this.openRow = data
      this.openFlag = true
    },
    rowScale(data) {
      this.scaleId = data.id
      this.scaleValue = data.scale === -1 ? 0 : data.scale
      this.softwareScaleValue = data.softwareScale === -1 ? 0 : data.softwareScale
      this.scaleFlag = true
    },
    handleOpen() {
      let that = this
      this.$refs.refFormObserver.validate().then(result => {
        if (result) {
          open(that.openRow.id, { count: that.openCount, month: that.openMonth }).then(resp => {
            if (resp.data.success) {
              that.$xtoast.success('开通成功！')
              that.$refs.table.__searchHandle()
              that.sideHidden()
            } else {
              that.$xtoast.error(resp.data.msg)
            }
          })
        }
      })
    },
    handleScale() {
      let that = this
      this.$refs.scaleFormObserver.validate().then(result => {
        console.log('aaasd', result)
        if (result) {
          setScale(that.scaleId, that.scaleValue, that.softwareScaleValue).then(resp => {
            if (resp.data.success) {
              that.$xtoast.success('设置成功！')
              that.$refs.table.__searchHandle()
              that.scaleHidden()
            } else {
              that.$xtoast.error(resp.data.msg)
            }
          })
        }
      })
    },
    sideHidden() {
      this.openFlag = false
      this.openRow = undefined
      this.openCount = undefined
      this.openMonth = undefined
    },
    scaleHidden() {
      this.scaleFlag = false
      this.scaleId = undefined
      this.scaleValue = 0
      this.softwareScaleValue = 0
    },
    getReferrerId(row) {
      return Base64.encode(row.id)
    },
  },
}
</script>

<style lang="scss">
</style>
