<template>
  <div>
    <x-el-table
      ref="table"
      :options="options"
      @search="searchHandle"
      @rowEdit="rowEdit"
      @rowUnbind="rowUnBind"
      @rowRecord="rowRecord"
      @rowQrcode="rowQrcode"
      @rowMemberQrcode="rowMemberQrcode"
      @rowDelete="rowDelete">
      <template slot="mtId" slot-scope="scope">
        <div v-if="scope.row.mtUser && scope.row.mtUser.id">
          <b-avatar v-viewer :src="scope.row.mtUser.avatar" variant="light-primary"></b-avatar>
          <span class="ml-1 text-primary x-link x-text-normal" @click="$router.push(`/data/merchant/profile?id=${scope.row.mtUser.id}`)">{{ scope.row.mtUser.nickName }}</span>
        </div>
        <div v-else>
          <b-avatar v-viewer variant="light-secondary"></b-avatar>
          <span class="ml-1 text-secondary">暂无</span>
        </div>
      </template>
      <template slot="shopId" slot-scope="scope">
        <div v-if="scope.row.shop && scope.row.shop.id">
          <span class="text-primary x-link x-text-normal" @click="$router.push(`/data/shop/profile?id=${scope.row.shop.id}`)">{{ scope.row.shop.name }}</span>
        </div>
        <div v-else>
          <span class="ml-1 text-secondary">暂无</span>
        </div>
      </template>
      <template slot='playState' slot-scope='scope'>
        <b-badge variant="light-success" v-if="scope.row.playingRecord && scope.row.playingRecord.id">
              游戏中
        </b-badge>
        <b-badge variant="light-info" v-else>
              空闲中
         </b-badge>
      </template>
      <template slot='status' slot-scope='scope'>
        <b-badge variant="light-success" v-if="scope.row.status === 'online'">
              在线
        </b-badge>
        <b-badge variant="light-danger" v-else-if="scope.row.status === 'offline'">
              离线
         </b-badge>
        <b-badge variant="light-warning" v-else>
              暂无状态
         </b-badge>
      </template>
      <template slot='row-unbind' slot-scope='scope'>
        <x-popconfirm
          :target="`${scope.row.id}-unbind`"
          content="确定要执行解绑操作吗？"
          triggers="click"
          @enter="rowUnBind(scope.row)">
        </x-popconfirm>
        <div :id="`${scope.row.id}-unbind`" v-tooltip.hover="`解绑`" class="btn btn-icon btn-flat-primary rounded-circle x-link btn-active-color-primary">
          <feather-icon icon="UnlockIcon" />
        </div>
      </template>
    </x-el-table>
    <b-sidebar
          id="sidebar-right"
          ref="slider"
          idebar-class="sidebar-xl"
          bg-variant="white"
          title="游戏记录"
          right
          backdrop
          shadow
          width="60%"
          :visible="recordFlag"
          @hidden="sideHidden"
        >
          <play-record v-if="machineId" :queryMachineId="machineId"/>
    </b-sidebar>

    <b-modal
      ref="codeModal"
      cancel-variant="flat-secondary"
      ok-title="下载"
      cancel-title="取消"
      centered
      title="商户机器码"
      :visible="codeFlag"
      @hidden="resetCodeModal"
      @ok="download"
    >
      <div class="mt-5 mb-5 d-flex justify-content-center">
        <div ref="qrcode" id="qrcode"></div>
      </div>
    </b-modal>

    <b-modal
      ref="memberCodeModal"
      cancel-variant="flat-secondary"
      ok-title="下载"
      cancel-title="取消"
      centered
      title="玩家机器码"
      :visible="memberCodeFlag"
      @hidden="resetMemberCodeModal"
      @ok="memberDownload"
    >
      <div class="mt-5 mb-5 d-flex justify-content-center">
        <div ref="memberCode" id="memberCode"></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import { BBadge, BSidebar, BAvatar } from 'bootstrap-vue'
import XPopconfirm from '@core/components/cx/popconfirm/XPopconfirm.vue'
import QRCode from 'qrcodejs2'
import {
  getList, remove, edit, unBind,
} from '@/api/data/machine'
import PlayRecord from '../game-record/index.vue'

export default {
  name: 'Machine',
  components: {
    XElTable,
    BBadge,
    BSidebar,
    BAvatar,
    PlayRecord,
    XPopconfirm,
  },
  props: {
    queryMtId: {
      type: String,
      default: undefined,
    },
    queryShopId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      recordFlag: false,
      machineId: undefined,
      codeFlag: false,
      memberCodeFlag: false,
      memberCodeModal: false,
      codeId: undefined,
      codeName: undefined,
      qrcode: undefined,
      memberCode: undefined,
      options: {
        addBtn: false,
        actions: [
          // { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon' },
          // { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon' },
          // { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon' },
          { action: 'unbind', tip: '解绑', icon: 'UnlockIcon' },
          { action: 'record', tip: '游戏记录', icon: 'CodeIcon' },
          { action: 'qrcode', tip: '商户机器码', icon: 'SlackIcon' },
          { action: 'memberQrcode', tip: '玩家机器码', icon: 'MinimizeIcon' },
        ],
        columns: [
          {
            label: '所属商户',
            prop: 'mtId',
            type: 'select',
            rowSelect: true,
            searchShow: !this.queryMtId,
            dictUrl: '/apollo-jxz/gm/merchant/all',
            props: {
              label: 'nickName',
              value: 'id',
            },
          },
          {
            label: '所属店铺',
            prop: 'shopId',
            type: 'select',
            rowSelect: true,
            searchShow: !this.queryShopId,
            dictUrl: '/apollo-jxz/shop/all',
            props: {
              label: 'name',
              value: 'id',
            },
          },
          {
            label: '机器名称',
            prop: 'name',
            searchShow: true,
          },
          {
            label: '机器编码',
            prop: 'id',
            searchShow: false,
          },
          {
            label: '机器状态',
            prop: 'state',
            type: 'select',
            rowSelect: true,
            searchShow: false,
            dictData: [
              {
                label: '正常',
                value: 'normal',
              },
              {
                label: '维护',
                value: 'maintain',
              },
              {
                label: '未绑定',
                value: 'unbind',
              },
            ],
            props: {
              label: 'label',
              value: 'value',
            },
            selectVariant: propValue => {
              if (propValue === 'normal') {
                return 'badge badge-light-success'
              }
              if (propValue === 'maintain') {
                return 'badge badge-light-secondary'
              }
              return 'badge badge-light-warning'
            },
          },
          {
            label: '在线状态',
            prop: 'status',
            rowSelect: true,
            searchShow: false,
          },
          {
            label: '游戏状态',
            prop: 'playState',
            searchShow: false,
          },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      if (this.queryMtId) {
        params.mtId = this.queryMtId
      }
      if (this.queryShopId) {
        params.shopId = this.queryShopId
      }
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    rowEdit(data, done) {
      edit(data).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    rowUnBind(data) {
      unBind(data.id).then(() => {
        this.$refs.table.__searchHandle()
      })
    },
    rowRecord(data) {
      this.recordFlag = true
      this.machineId = data.id
    },
    rowQrcode(data) {
      this.codeFlag = true
      this.codeId = data.id
      this.codeName = data.name
      this.$nextTick(function () {
        new QRCode(this.$refs.qrcode, {
          width: 256,
          height: 256,
          text: 'http://yicunqian.com/mtlogin?key=' + this.codeId + '&v=2',
        })
        this.qrcode = this.$refs.qrcode
      })
    },
    rowMemberQrcode(data) {
      this.memberCodeFlag = true
      this.codeId = data.id
      this.codeName = data.name
      this.$nextTick(function () {
        new QRCode(this.$refs.memberCode, {
          width: 256,
          height: 256,
          text: 'http://yicunqian.com/wlogin?key=' + this.codeId + '&v=2',
        })
        this.memberCode = this.$refs.memberCode
      })
    },
    download() {
      // 生成二维码到 canvas 元素
      new QRCode('qrcode', {
        text: 'http://yicunqian.com/mtlogin?key=' + this.codeId + '&v=2',
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      })

      // 获取 canvas 元素
      let canvas = document.querySelector('#qrcode canvas')

      // 将 canvas 转换为 dataURL（base64 格式）
      let dataURL = canvas.toDataURL('image/png')

      // 创建隐藏的可下载链接
      let link = document.createElement('a')
      link.download = this.codeName + '(商户)'
      link.href = dataURL
      link.style.display = 'none'

      // 插入到 DOM 中以便触发点击事件
      document.body.appendChild(link)

      // 触发点击事件进行下载
      link.click()

      // 移除该元素
      document.body.removeChild(link)
    },
    memberDownload() {
      // 生成二维码到 canvas 元素
      var qrcode = new QRCode('memberCode', {
        text: 'http://yicunqian.com/wlogin?key=' + this.codeId + '&v=2',
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      })

      // 获取 canvas 元素
      var canvas = document.querySelector('#memberCode canvas')

      // 将 canvas 转换为 dataURL（base64 格式）
      var dataURL = canvas.toDataURL('image/png')

      // 创建隐藏的可下载链接
      var link = document.createElement('a')
      link.download = this.codeName + '(玩家)'
      link.href = dataURL
      link.style.display = 'none'

      // 插入到 DOM 中以便触发点击事件
      document.body.appendChild(link)

      // 触发点击事件进行下载
      link.click()

      // 移除该元素
      document.body.removeChild(link)
    },
    resetCodeModal() {
      this.codeFlag = false
      this.codeId = undefined
      this.codeName = undefined
    },
    resetMemberCodeModal() {
      this.memberCodeFlag = false
      this.codeId = undefined
      this.codeName = undefined
    },
    sideHidden() {
      this.recordFlag = false
      this.machineId = undefined
    },
  },
}
</script>

<style lang="scss">
</style>
