<template>
  <div>
    <x-el-table
      ref="table"
      :options="options"
      @search="searchHandle"
      @rowEdit="rowEdit"
      @rowViewRealInfo="rowViewRealInfo"
      @rowDelete="rowDelete"
      @rowSetCoins="rowSetCoins">
      <template slot="avatar" slot-scope="scope">
        <b-avatar v-viewer :src="scope.row.avatar" variant="light-primary" size="50"></b-avatar>
      </template>
      <template slot="form-avatar" slot-scope="scope">
        <b-avatar v-viewer :src="scope.row.avatar" variant="light-primary" size="50"></b-avatar>
      </template>
      <template slot="nickName" slot-scope="scope">
        <div class="text-primary x-text-normal x-link" @click="() => $refs.table.actionClick(options.actions[0],scope)">{{ scope.row.nickName }}</div>
      </template>
      <template slot="mts" slot-scope="scope">
        <b-avatar-group size="50">
          <b-avatar v-for="(item, index) in scope.row.mts" class="mt-hover"
                    v-tooltip.hover="item.mtName" v-viewer :src="item.mtAvatar" :key="index"></b-avatar>
        </b-avatar-group>
      </template>
      <template slot="row-expand" slot-scope="scope">
        <div v-if="scope.row.mts && scope.row.mts.length > 0" class="d-flex align-items-center">
          <div v-for="(item, index) in scope.row.mts" :key="index" class="x-card x-corner x-anim-card p-2 mr-1">
            <div class="d-flex align-items-center">
              <b-avatar v-viewer :src="item.mtAvatar" size="50"></b-avatar>
              <div class="text-primary ml-50">
                <div class="x-link font-medium-1" @click="$router.push(`/data/merchant/profile?id=${item.mtId}`)">{{item.mtName}}</div>
                <div class="text-secondary d-flex align-items-center"><feather-icon class="mr-50" icon="ClockIcon"/>{{item.createTime}}</div>
              </div>
            </div>
            <div class="x-text-bold text-success font-medium-4 mt-1">
              {{ item.coin }} <span class="text-secondary font-small-4 x-text-normal"> /币</span>
            </div>
          </div>
        </div>
        <b-img v-else center class="mb-2 mt-2" height="80" :src="require('@/@core/assets/images/empty.svg')"/>
      </template>
    </x-el-table>
    <b-modal
      size="md"
      :visible="showRealInfo"
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      ok-title="取消"
      ok-only
      ok-variant="light-secondary"
      title="实名信息"
      centered
      @ok="hideRealInfoModal">
      <x-table-form v-if="showRealInfo" v-model="realInfo"  ref="tableForm" :isAdd="false" :isView="true" :columns="realInfoColumns">
      </x-table-form>
    </b-modal>
    <b-modal
      size="md"
      :visible="showSetCoinModal"
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      ok-title="提交"
      cancel-title="取消"
      cancel-variant="flat-secondary"
      title="改币"
      centered
      @hidden="hideSetCoinModal"
      @ok="enterSetCoinModal">
      <x-table-form v-if="showSetCoinModal" v-model="setCoinFormData"  ref="setCoinTableForm" :isAdd="false" :isView="false" :columns="setCoinFormColumns">
      </x-table-form>
    </b-modal>
  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import XTableForm from '@core/components/cx/table/XTableForm.vue'
import {
  BAvatar, BAvatarGroup, BImg, BModal,
} from 'bootstrap-vue'
import {
  getList, remove, edit, getMemberRealInfo, setCoins,
} from '@/api/data/member'

export default {
  name: 'Member',
  components: {
    XElTable,
    XTableForm,
    BAvatar,
    BAvatarGroup,
    BImg,
    BModal,
  },
  props: {
    queryMtId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      showRealInfo: false,
      showSetCoinModal: false,
      realInfo: {},
      setCoinFormData: {},
      realInfoColumns: [
        {
          label: '姓名',
          prop: 'name',
          labelShow: true,
        },
        {
          label: '身份证号',
          prop: 'idcard',
          labelShow: true,
        },
      ],
      setCoinFormColumns: [
        {
          label: '会员名称',
          prop: 'nickName',
          labelShow: true,
          editDisable: true,
        },
        {
          label: '商户',
          prop: 'mtId',
          labelShow: true,
          type: 'select',
          rules: {
            rule: 'required',
            message: '请选择商户',
          },
          placeholder: '请选择商户',
          getDictLabel: option => {
            if (typeof option === 'object') {
              return option.label + ''
            }
            return option
          },
          onDictSelected: (option, colums, formData) => {
            if (!option) {
              return
            }
            for (let item of colums[1].dictData) {
              if (item.value === option) {
                formData.coins = item.coin
                return
              }
            }
          },
        },
        {
          label: '币数',
          prop: 'coins',
          rules: {
            rule: 'required',
            message: '请输入币数',
          },
          placeholder: '请输入币数',
          labelShow: true,
        },
      ],
      options: {
        addBtn: false,
        rowExpand: true,
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon', permission: 'user_view', url: '/data/member/profile?id={id}' },
          // { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon', permission: 'user_edit' },
          { action: 'viewRealInfo', tip: '实名信息', icon: 'ShieldIcon', permission: 'user_delete' },
          { action: 'setCoins', tip: '改币', icon: 'StopCircleIcon', permission: 'user_delete' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon', permission: 'user_delete' },
        ],
        columns: [
          {
            label: '头像',
            prop: 'avatar',
            searchShow: false,
          },
          {
            label: '昵称',
            prop: 'nickName',
          },
          {
            label: '手机号',
            prop: 'phone',
          },
          {
            label: '所属商户',
            prop: 'mts',
            searchShow: false,
          },
          {
            label: '注册时间',
            prop: 'createTime',
            searchShow: false,
          },
          {
            label: '所属商户',
            prop: 'mtId',
            rowShow: false,
            searchShow: !this.queryMtId,
            type: 'select',
            dictUrl: '/apollo-jxz/gm/merchant/all',
            props: {
              label: 'nickName',
              value: 'id',
            },
          },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      if (this.queryMtId) {
        params.mtId = this.queryMtId
      }
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    rowEdit(data, done) {
      edit(data).then(() => {
        done()
      })
    },
    rowViewRealInfo(row) {
      getMemberRealInfo(row.id).then(resp => {
        const { data } = resp.data
        if (!data || !data.idcard || !data.name) {
          this.$xtoast.warning('该会员暂未实名!')
        } else {
          this.realInfo = data
          this.showRealInfo = true
        }
      })
    },
    hideRealInfoModal() {
      this.showRealInfo = false
      this.realInfo = {}
    },
    hideSetCoinModal() {
      this.showSetCoinModal = false
      this.setCoinFormData = {}
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    rowSetCoins(data) {
      this.setCoinFormData = {
        memberId: data.id,
        nickName: data.nickName,
        mtId: undefined,
        coins: undefined,
      }
      data.mts = data.mts || []
      this.setCoinFormColumns[1].dictData = data.mts.map(item => {
        return { label: item.mtName, value: item.mtId, coin: item.coin }
      })

      this.showSetCoinModal = true
    },
    enterSetCoinModal(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.setCoinTableForm.validate().then(result => {
        if (result) {
          setCoins(this.setCoinFormData).then(() => {
            this.$refs.table.__searchHandle()
            this.$xtoast.success('修改成功！')
            this.showSetCoinModal = false
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.mt-hover {
  transition: all 0.25s ease-in-out;
  &:hover {
    transform: translateX(-10px);
    z-index: 100000000000;
  }
}
</style>
