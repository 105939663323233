<template>
  <div>
    <x-el-table
      ref="table"
      :options="options"
      @search="searchHandle">
      <template slot="memberId" slot-scope="scope">
        <b-avatar v-viewer :src="scope.row.gmUser.avatar" variant="light-primary"></b-avatar>
        <span class="ml-1 text-primary x-link x-text-normal" @click="$router.push(`/data/member/profile?id=${scope.row.gmUser.id}`)">{{ scope.row.gmUser.nickName }}</span>
      </template>
      <template slot="mtId" slot-scope="scope">
        <b-avatar v-viewer :src="scope.row.mtUser.avatar" variant="light-primary"></b-avatar>
        <span class="ml-1 text-primary x-link x-text-normal" @click="$router.push(`/data/merchant/profile?id=${scope.row.mtUser.id}`)">{{ scope.row.mtUser.nickName }}</span>
      </template>
      <template slot="machineId" slot-scope="scope">
        <span class="badge badge-light-primary">{{ scope.row.machine.name }}</span>
      </template>
      <template slot="playDur" slot-scope="scope">
        <span class="text-primary x-text-bold">
        {{ playDur(scope.row.playDur) }}
        </span>
      </template>
      <template slot="cost" slot-scope="scope">
        <span class="x-text-bold text-danger font-medium-2"> {{ '- ' + ( scope.row.cost > 0 ? scope.row.cost : '0') }} </span>
        <span class="text-secondary font-small-4 x-text-normal"> /币 </span>
      </template>
    </x-el-table>
  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import { BAvatar } from 'bootstrap-vue'
import {
  getPage,
} from '@/api/data/game-area'

export default {
  name: 'PlayRecord',
  components: {
    XElTable,
    BAvatar,
  },
  props: {
    queryMemberId: {
      type: String,
      default: undefined,
    },
    queryMtId: {
      type: String,
      default: undefined,
    },
    queryMachineId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      options: {
        hideStyleSwitch: true,
        addBtn: false,
        actions: [
          // { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon' },
        ],
        columns: [
          {
            label: '会员',
            prop: 'memberId',
            type: 'select',
            rowSelect: true,
            searchShow: !this.queryMemberId,
            dictUrl: this.queryMtId ? `/apollo-jxz/gm/member/extend/list?mtId=${this.queryMtId}` : '/apollo-jxz/gm/member/all',
            props: {
              label: 'nickName',
              value: 'id',
            },
          },
          {
            label: '商户',
            prop: 'mtId',
            type: 'select',
            searchShow: !this.queryMtId,
            dictUrl: this.queryMtId ? `/apollo-jxz/gm/merchant/all?mtId=${this.queryMtId}` : '/apollo-jxz/gm/merchant/all',
            props: {
              label: 'nickName',
              value: 'id',
            },
          },
          {
            label: '机器名称',
            prop: 'machineId',
            type: 'select',
            rowSelect: true,
            searchShow: true,
            dictUrl: this.queryMtId ? `/apollo-jxz/machine/all?mtId=${this.queryMtId}` : '/apollo-jxz/machine/all',
            props: {
              label: 'name',
              value: 'id',
            },
          },
          {
            label: '上机时间',
            prop: 'upTime',
            searchShow: false,
          },
          {
            label: '下机时间',
            prop: 'endTime',
            searchShow: false,
          },
          {
            label: '游戏时长',
            prop: 'playDur',
            searchShow: false,
          },
          {
            label: '游戏币',
            prop: 'cost',
            searchShow: false,
          },
          {
            label: '时间开始',
            type: 'datetime',
            prop: 'startTime',
            rowShow: false,
            searchShow: true,
          },
          {
            label: '时间结束',
            type: 'datetime',
            prop: 'searchEndTime',
            rowShow: false,
            searchShow: true,
          },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      if (this.queryMachineId) {
        params.machineId = this.queryMachineId
      }
      if (this.queryMemberId) {
        params.memberId = this.queryMemberId
      }
      if (this.queryMtId) {
        params.mtId = this.queryMtId
      }
      getPage(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    playDur(seconds) {
      let d = parseInt(seconds / 86400, 10)
      let h = parseInt((seconds / 3600) % 24, 10)
      let m = parseInt((seconds / 60) % 60, 10)
      let s = parseInt(seconds % 60, 10)

      // h < 10 ? (h = '0' + h) : true
      // m < 10 ? (m = '0' + m) : true
      // s < 10 ? (d = '0' + s) : true
      let first = d > 0
      let str = first ? `${d}天` : ''

      first = first || (h > 0)
      str += first ? `${h}小时` : ''

      first = first || (m > 0)
      str += first ? `${m}分钟` : ''

      first = first || (s > 0)
      str += first ? `${s}秒` : ''
      return str
    },
  },
}
</script>

<style lang="scss">
</style>
