import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-jxz/shop/page',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const getAdminList = (current, size, params) => request.axiosIns({
  url: '/apollo-jxz/shop/admin/page',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const profile = id => request.axiosIns({
  url: `/apollo-jxz/shop/profile/${id}`,
  method: 'get',
})
