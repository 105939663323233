import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-jxz/gm/cdKey/page',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const getMtUsers = () => request.axiosIns({
  url: '/apollo-jxz/gm/merchant/all',
  method: 'get',
})

export const saveBatch = row => request.axiosIns({
  url: '/apollo-jxz/gm/cdKey/submit/batch',
  method: 'post',
  data: row,
})

export const edit = data => request.axiosIns({
  url: '/apollo-jxz/gm/cdKey/submit',
  method: 'put',
  data,
})

export const remove = id => request.axiosIns({
  url: `/apollo-jxz/gm/cdKey/remove/${id}`,
  method: 'delete',
})

// 销毁兑换码
export const destroy = mtId => request.axiosIns({
  url: `/apollo-jxz/gm/cdKey/destroy/${mtId}`,
  method: 'post',
})
