import request from '@/auth/jwt/useJwt'

export function getList(current, size, params) {
  let paramObj = {}
  if (params) {
    if (params.clientId && params.clientId !== '') {
      paramObj.clientId = params.clientId
    }
  }
  params = paramObj
  return request.axiosIns({
    url: '/apollo-upms/client/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}

export function getDetail(id) {
  return request.axiosIns({
    url: '/apollo-upms/client/detail',
    method: 'get',
    params: {
      id,
    },
  })
}

export function remove(ids) {
  return request.axiosIns({
    url: '/apollo-upms/client/remove',
    method: 'post',
    params: {
      ids,
    },
  })
}

export function add(row) {
  return request.axiosIns({
    url: '/apollo-upms/client/submit',
    method: 'post',
    data: row,
  })
}

export function update(row) {
  return request.axiosIns({
    url: '/apollo-upms/client/submit',
    method: 'post',
    data: row,
  })
}
