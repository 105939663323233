import request from '@/auth/jwt/useJwt'
import website from '@/config/website'
import { Base64 } from 'js-base64'
import { getToken } from '@core/auth/token'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-resource/attach/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const getDetail = id => request.axiosIns({
  url: '/apollo-resource/attach/detail',
  method: 'get',
  params: {
    id,
  },
})

export const remove = ids => request.axiosIns({
  url: '/apollo-resource/attach/remove',
  method: 'post',
  params: {
    ids,
  },
})

export const add = row => request.axiosIns({
  url: '/apollo-resource/attach/submit',
  method: 'post',
  data: row,
})

export const update = row => request.axiosIns({
  url: '/apollo-resource/attach/submit',
  method: 'post',
  data: row,
})

function getHeaders() {
  let hd = {
    Authorization: `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`,
  }
  hd[website.tokenHeader] = getToken()
  return hd
}

export const Headers = getHeaders()
