import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-upms/role/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const grantTree = () => request.axiosIns({
  url: '/apollo-upms/menu/grant-tree',
  method: 'get',
})

export const grant = (roleIds, menuIds, dataScopeIds, apiScopeIds) => request.axiosIns({
  url: '/apollo-upms/role/grant',
  method: 'post',
  data: {
    roleIds,
    menuIds,
    dataScopeIds,
    apiScopeIds,
  },
})

export const remove = ids => request.axiosIns({
  url: '/apollo-upms/role/remove',
  method: 'post',
  params: {
    ids,
  },
})

export const add = row => request.axiosIns({
  url: '/apollo-upms/role/submit',
  method: 'post',
  data: row,
})

export const update = row => request.axiosIns({
  url: '/apollo-upms/role/submit',
  method: 'post',
  data: row,
})

export const getRole = roleIds => request.axiosIns({
  url: '/apollo-upms/menu/role-tree-keys',
  method: 'get',
  params: {
    roleIds,
  },
})

export const getRoleTree = tenantId => request.axiosIns({
  url: '/apollo-upms/role/tree',
  method: 'get',
  params: {
    tenantId,
  },
})

export const getRoleTreeById = roleId => request.axiosIns({
  url: '/apollo-upms/role/tree-by-id',
  method: 'get',
  params: {
    roleId,
  },
})
