import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-jxz/game/area/page',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const remove = ids => request.axiosIns({
  url: '/apollo-jxz/machine/remove',
  method: 'post',
  params: {
    ids,
  },
})
