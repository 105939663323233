import request from '@/auth/jwt/useJwt'

export const getList = (current, size) => request.axiosIns({
  url: '/apollo-jxz/deal/page',
  method: 'get',
  params: {
    current,
    size,
  },
})

export const submit = data => request.axiosIns({
  url: '/apollo-jxz/deal/submit',
  method: 'post',
  data,
})
