<template>
  <div>
    <x-el-table
      ref="table"
      no-page
      :options="options"
      :permissions="searchPermissions"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowAuthz="rowAuthz"
      @rowDelete="rowDelete">
      <template slot="roleAlias" slot-scope="scope">
        <div class="text-primary x-text-normal x-link d-inline" @click="() => $refs.table.actionClick(options.actions[0],scope)">{{ scope.row.roleAlias }}</div>
      </template>
    </x-el-table>
    <b-sidebar
      :visible="showAuthzView"
      bg-variant="white"
      right
      backdrop
      shadow
      width="50%"
      @hidden="hideAuthzView">
      <div class="d-flex position-fixed x-bottom-0 x-end-0 p-2">

        <div class="mr-2 btn btn-light-danger mr-50" @click="hideAuthzView()">
          取消
        </div>
        <b-button variant="primary" @click="updateRolePermissions()">
          授权
        </b-button>
      </div>
      <b-tabs
        v-if="showAuthzView"
        vertical
        class="m-2"
        active-nav-item-class="bg-light-primary nav-vertical-w justify-content-start x-anim-fade-in"
        nav-wrapper-class="nav-vertical">
        <b-tab active>
          <template #title>
            <feather-icon icon="AlignJustifyIcon" size="18"  class="mr-50" />
            <span class="font-weight-bold">菜单权限</span>
          </template>
          <el-tree
            v-if="allPermissions"
            ref="treeMenu"
            :data="allPermissions.menu"
            show-checkbox
            node-key="id"
            :default-checked-keys="rolePermissions.menu"
            :props="permProps">
          </el-tree>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="Minimize2Icon" size="18"  class="mr-50" />
            <span class="font-weight-bold">接口权限</span>
          </template>
          <el-tree
            v-if="allPermissions"
            ref="treeApiScope"
            :data="allPermissions.apiScope"
            show-checkbox
            node-key="id"
            :default-checked-keys="rolePermissions.apiScope"
            :props="permProps">
          </el-tree>
        </b-tab>
        <b-tab >
          <template #title>
            <feather-icon icon="BookOpenIcon" size="18"  class="mr-50" />
            <span class="font-weight-bold">数据权限</span>
          </template>
          <el-tree
            v-if="allPermissions"
            ref="treeDataScope"
            :data="allPermissions.dataScope"
            show-checkbox
            node-key="id"
            :default-checked-keys="rolePermissions.dataScope"
            :props="permProps">
          </el-tree>
        </b-tab>
      </b-tabs>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BTabs, BTab, BButton,
} from 'bootstrap-vue'
import XElTable from '@core/components/cx/table/XElTable.vue'
import {
  getList, add, update, remove, grantTree, getRole, grant,
} from '@/api/permission/role'

export default {
  name: 'Role',
  components: {
    XElTable,
    BSidebar,
    BTabs,
    BTab,
    BButton,
  },
  data() {
    return {
      showAuthzView: false,
      authzRole: undefined,
      allPermissions: undefined,
      rolePermissions: {},
      searchPermissions: {
        add: 'role_add',
      },
      options: {
        hideStyleSwitch: true,
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon', permission: 'role_view' },
          { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon', permission: 'role_edit' },
          { action: 'authz', tip: '授权', icon: 'ShieldIcon' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon', permission: 'role_delete' },
        ],
        columns: [
          {
            label: '上级角色',
            prop: 'parentId',
            searchShow: false,
            rowShow: false,
            type: 'tree',
            rowSelect: true,
            dictUrl: '/apollo-upms/role/tree-by-id',
            editDisable: true,
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
            },
          },
          {
            label: '角色标识',
            prop: 'roleAlias',
            rules: {
              rule: 'required',
              message: '请输入角色标识',
            },
          },
          {
            label: '角色名称',
            prop: 'roleName',
            rules: {
              rule: 'required',
              message: '请输入角色名称',
            },
          },
          // {
          //   label: '所属租户',
          //   prop: 'tenantId',
          //   addShow: false,
          //   editDisable: true,
          //   type: 'select',
          //   rowSelect: true,
          //   dictUrl: '/apollo-upms/tenant/select',
          //   props: {
          //     label: 'tenantName',
          //     value: 'tenantId',
          //   },
          // },
          {
            label: '排序值',
            prop: 'sort',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入证书名称',
            },
          },
        ],
      },
      permProps: {
        label: 'title',
        value: 'key',
      },
    }
  },
  methods: {
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data, resp.data.data.length)
      })
    },
    addHandle(data, done) {
      if (data.parentId && data.parentId.length > 0) {
        data.parentId = data.parentId[data.parentId.length - 1]
      } else {
        data.parentId = ''
      }
      add(data).then(() => {
        done()
      })
    },
    rowEdit(data, done) {
      update(data).then(() => {
        done()
      })
    },
    rowAuthz(data) {
      this.authzRole = data
      if (!this.allPermissions) {
        grantTree().then(resp => {
          this.allPermissions = resp.data.data
        })
      }
      this.rolePermissions = {}
      getRole(data.id).then(resp => {
        this.rolePermissions = resp.data.data
      })
      this.showAuthzView = true
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    hideAuthzView() {
      this.showAuthzView = false
      this.authzRole = undefined
    },
    updateRolePermissions() {
      const menuList = this.$refs.treeMenu.getCheckedKeys()
      const dataScopeList = this.$refs.treeDataScope.getCheckedKeys()
      const apiScopeList = this.$refs.treeApiScope.getCheckedKeys()
      grant([this.authzRole.id], menuList, dataScopeList, apiScopeList).then(() => {
        this.$xtoast.info('授权成功')
        this.hideAuthzView()
      })
    },
  },
}
</script>

<style scoped>

</style>
