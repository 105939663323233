<template>
  <div>
    <x-el-table
      ref="table"
      :permissions="{ add: 'tenant_add' }"
      :options="options"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete"
      @rowSelected="rowSelected"
      @rowAuthzConfig="rowAuthzConfig"
      @rowProductConfig="rowProductConfig">
      <template slot="row-expand" slot-scope="{row}">
        <div class="d-flex m-50">
          <label class="text-muted x-w-100px font-medium-1">联系人</label><label class="x-text-bold text-primary ml-1 font-medium-1">{{ row.linkman }}</label>
        </div>
        <div class="d-flex m-50">
          <span class="text-muted x-w-100px font-medium-1">联系电话</span><span class="ml-1 font-medium-1">{{ row.contactNumber }}</span>
        </div>
        <div class="d-flex m-50">
          <span class="text-muted x-w-100px font-medium-1">联系地址</span><span class="ml-1 font-medium-1">{{ row.address }}</span>
        </div>
      </template>
      <template slot="packageId" slot-scope="{data, row}">
        <b-badge v-if="data" variant="light-primary">{{ getPackage(data).packageName }}</b-badge>
        <div
          v-else
          class="btn btn-light-danger btn-sm x-dashed-border x-border-danger" @click="rowProductConfig(row)">
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          去配置
        </div>
      </template>
      <template slot-scope="{row}" slot="accountNumber">
        <b-badge variant="light-primary">{{ '￥' + row.accountNumber }}</b-badge>
      </template>
      <template slot-scope="{row}" slot="expireTime">
        <div class="text-danger d-flex align-items-center">
          <feather-icon icon="ClockIcon"></feather-icon>
          <span class="x-ml-5px">{{ row.expireTime ? row.expireTime : '不限制' }}</span>
        </div>
      </template>
      <template slot-scope="{data}" slot="domain">
        <div :class="data ? '' : 'text-muted'">{{ data ? data : '无' }}</div>
      </template>
      <template slot="actions">
        <div
          v-permission="`user_reset`"
          v-ripple.400="$x.ripple.success"
          class="btn btn-light-success ml-1"
          @click="authzConfig">
          <feather-icon
            size="16"
            icon="ShieldIcon"
            class="mr-50"
          />授权配置
        </div>
        <div
          v-permission="`user_enable`"
          v-ripple.400="$x.ripple.danger"
          class="btn btn-light-danger ml-1"
          @click="productConfig">
          <feather-icon
            size="16"
            icon="PackageIcon"
            class="mr-50"
          />产品包配置
        </div>
                <div
                  v-permission="`user_reset`"
                  v-ripple.400="$x.ripple.info"
                  class="btn btn-light-info ml-1"
                  @click="handlePackageSetting">
                  <feather-icon
                    size="16"
                    icon="SidebarIcon"
                    class="mr-50"
                  />产品包管理
                </div>
      </template>
    </x-el-table>
    <b-modal
      cancel-variant="flat-secondary"
      ok-title="确定"
      cancel-title="取消"
      centered
      title="授权配置"
      :visible="showAuthzModal"
      @hidden="authzConfigHide()"
      @ok="authzConfigHandle()">
      <x-form-validator ref="authzRefFormObserver" class="card card-body x-no-shadow">
        <x-form-feild label="授权额度" require>
          <b-input-group
            prepend="￥"
            append="元"
            class="input-group-merge">
            <b-form-input
              v-model="authzFormData.accountNumber" type="number" placeholder="请输入授权额度" />
          </b-input-group>
        </x-form-feild>
        <x-form-feild label="过期时间" require>
          <el-date-picker
            v-model="authzFormData.expireTime"
            type="date"
            placeholder="请选择过期时间"
            format="yyyy年MM月dd日 HH时mm分ss秒"
            value-format="yyyy-MM-dd HH:mm:ss"
            class="w-100"
          >
          </el-date-picker>
        </x-form-feild>
      </x-form-validator>
    </b-modal>
    <b-modal
      cancel-variant="flat-secondary"
      ok-title="确定"
      cancel-title="取消"
      centered
      title="产品包配置"
      :visible="showProductModal"
      @hidden="productConfigHide()"
      @ok="productConfigHandle()">
      <x-form-validator ref="productRefFormObserver" class="card card-body x-no-shadow">
        <x-form-feild label="产品包" require>
          <v-select v-model="productFormData.packageId"
                    :options="allPackages"
                    label="packageName"
                    :reduce="option => option.id"
                    placeholder="请选择产品包">
            <template slot="no-options">{{$t('noData')}}</template>
          </v-select>
        </x-form-feild>
        <x-form-feild label="菜单集合">
          <el-cascader  :value="packageMenus"
              class="x-w-100"
              :options="allMenus" :props="{ label: 'title', value: 'id', multiple: true, emitPath: false }" disabled>
          </el-cascader>
        </x-form-feild>
      </x-form-validator>
    </b-modal>
    <b-modal
      size="lg"
      hide-footer
      ref="packageModal"
      width="lg"
      :visible="packageSettingBox"
      @hidden="handlePackagehide()"
    >
      <tenant-package/>
    </b-modal>

  </div>
</template>

<script>

import XElTable from '@core/components/cx/table/XElTable.vue'
import { BBadge, BModal, BInputGroup, BFormInput } from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import vSelect from 'vue-select'
import {
  getList, add, update, remove, authzSetting, packageSetting,
} from '@/api/system/tenant'
import {
  getMenuTree,
} from '@/api/system/menu'
import {
  getAll as getAllPackages,
} from '@/api/system/tenant-package'
import TenantPackage from './TenantPackage.vue'

export default {
  name: 'Tenant',
  components: {
    XElTable,
    BBadge,
    BModal,
    BInputGroup,
    BFormInput,
    XFormValidator,
    XFormFeild,
    vSelect,
    TenantPackage,
  },
  data() {
    return {
      packageSettingBox: false,
      showAuthzModal: false,
      authzFormData: {},
      showProductModal: false,
      productFormData: {},
      selectedRows: undefined,
      packageMenus: [],
      allMenus: [],
      allPackages: [],
      options: {
        rowSelectable: true,
        normalAddBtn: true,
        rowExpand: true,
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon', permission: 'tenant_view' },
          { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon', permission: 'tenant_edit' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon', permission: 'tenant_delete' },
          { action: 'authzConfig', tip: '授权配置', icon: 'ShieldIcon', permission: 'tenant_authz_config', name: '授权配置' },
          { action: 'productConfig', tip: '产品包配置', icon: 'PackageIcon', permission: 'tenant_product_config', name: '产品包配置' },
        ],
        columns: [
          {
            label: '租户名称',
            prop: 'tenantName',
            rules: {
              rule: 'required',
              message: '请输入租户名称',
            },
          },
          {
            label: '联系人',
            prop: 'linkman',
            rowShow: false,
            rules: {
              rule: 'required',
              message: '请输入联系人',
            },
          },
          {
            label: '联系电话',
            prop: 'contactNumber',
            rowShow: false,
            searchShow: false,
          },
          {
            label: '联系地址',
            rowShow: false,
            prop: 'address',
            type: 'textarea',
            searchShow: false,
          },
          {
            label: '产品包',
            prop: 'packageId',
            addShow: false,
            editShow: false,
            viewShow: true,
            searchShow: true,
          },
          {
            label: '账号额度',
            prop: 'accountNumber',
            addShow: false,
            editShow: false,
            viewShow: false,
            searchShow: false,
          },
          {
            label: '过期时间',
            prop: 'expireTime',
            addShow: false,
            editShow: false,
            viewShow: false,
            searchShow: false,
          },
          {
            label: '绑定域名',
            prop: 'domain',
            searchShow: false,
          },
          {
            label: '创建时间',
            prop: 'createTime',
            addShow: false,
            editShow: false,
            viewShow: false,
            searchShow: false,
          },
          /* {
            label: '系统背景',
            prop: 'backgroundUrl',
            rowShow: false,
            type: 'upload',
            listType: 'picture-img',
            dataType: 'string',
            action: '/apollo-resource/oss/endpoint/put-file',
          }, */
        ],
      },
    }
  },
  watch: {
    'productFormData.packageId': function (value) {
      this.packageMenus = this.getPackageMunus(value)
      this.productFormData.packageId = value
    },
  },
  created() {
    getAllPackages().then(resp => {
      this.allPackages = resp.data.data || []
    })
    getMenuTree().then(resp => {
      this.allMenus = resp.data.data || []
    })
  },
  methods: {
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    addHandle(data, done) {
      add(data).then(() => {
        done()
      })
    },
    rowEdit(data, done) {
      update(data).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    rowSelected(rows) {
      this.selectedRows = rows || {}
    },
    rowAuthzConfig(data) {
      const { accountNumber, expireTime } = data
      this.authzFormData = {
        accountNumber,
        expireTime,
      }
      this.showAuthzModal = true
      this.selectedRows = [data]
    },
    rowProductConfig(data) {
      this.selectedRows = [data]
      this.productFormData = {
        packageId: data.packageId,
      }
      this.getPackageMunus(data.packageId)
      this.showProductModal = true
    },
    getPackageMunus(packageId) {
      if (!packageId) {
        return []
      }
      for (const pkg of this.allPackages) {
        if (pkg.id === packageId) {
          return pkg.menuId.split(',')
        }
      }
      return []
    },
    getPackage(packageId) {
      if (!packageId) {
        return {}
      }
      for (const pkg of this.allPackages) {
        if (pkg.id === packageId) {
          return pkg
        }
      }
      return {}
    },
    authzConfigHide() {
      this.showAuthzModal = false
      // this.selectedRows = undefined
    },
    authzConfig() {
      if (!this.selectedRows || this.selectedRows.length === 0) {
        this.$xtoast.warning('请至少选择一条数据！')
        return
      }
      if (this.selectedRows.length === 1) {
        this.rowAuthzConfig(this.selectedRows[0])
      } else {
        this.showAuthzModal = true
      }
    },
    authzConfigHandle() {
      this.$refs.authzRefFormObserver.validate().then(success => {
        if (success) {
          authzSetting(this.selectedRows.map(item => item.id).join(','), this.authzFormData).then(() => {
            this.$refs.table.__searchHandle()
            this.selectedRows = undefined
          })
        }
      })
    },
    productConfigHide() {
      this.showProductModal = false
      // this.selectedRows = undefined
    },
    productConfig() {
      if (!this.selectedRows || this.selectedRows.length !== 1) {
        this.$xtoast.warning('请选择一条数据！')
        return
      }
      this.rowProductConfig(this.selectedRows[0])
    },
    productConfigHandle() {
      this.$refs.productRefFormObserver.validate().then(success => {
        if (success) {
          packageSetting(this.selectedRows[0].tenantId, this.productFormData.packageId).then(() => {
            this.$refs.table.__searchHandle()
            this.selectedRows = undefined
          })
        }
      })
    },
    handlePackageSetting() {
      this.packageSettingBox = true
    },
    handlePackagehide() {
      this.packageSettingBox = false
    },
  },
}
</script>

<style scoped>

</style>
