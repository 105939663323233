<template>
  <div>
    <x-el-table
      :permissions="{ add: 'product_add' }"
      :options="options"
      @search="searchHandle"
      @beforeShow="beforeShow"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete">
      <template slot-scope="{row}" slot="expireTime">
        <b-badge variant="primary">{{ row.expireTime ? row.expireTime : '不限制' }}</b-badge>
      </template>
    </x-el-table>
  </div>
</template>

<script>

import XElTable from '@core/components/cx/table/XElTable.vue'
import {
  getList, add, update, remove,
} from '@/api/system/tenant-package'
import { BBadge } from 'bootstrap-vue'

export default {
  name: 'Tenant',
  components: {
    XElTable,
    BBadge,
  },
  data() {
    return {
      options: {
        searchFold: true,
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon', permission: 'product_view' },
          { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon', permission: 'product_edit' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon', permission: 'product_delete' },
        ],
        columns: [
          {
            label: '名称',
            prop: 'packageName',
            rules: {
              rule: 'required',
              message: '请输入产品包名称',
            },
          },
          {
            label: '菜单列表',
            prop: 'menuId',
            type: 'tree',
            dictUrl: '/apollo-upms/menu/tree',
            rowShow: false,
            props: {
              label: 'title',
              value: 'id',
              multiple: true,
              emitPath: false,
              collapseTags: true,
            },
            rules: {
              rule: 'required',
              message: '请选择菜单',
            },
          },
          {
            label: '备注',
            prop: 'remark',
          },
        ],
      },
    }
  },
  methods: {
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    beforeShow(type, formData, columns) {
      if (type.isEdit || type.isView) {
        formData.menuId = formData.menuId.split(',')
        columns[1].props.collapseTags = type.isEdit
      }
    },
    addHandle(data, done) {
      data.menuId = data.menuId.join(',')
      add(data).then(() => {
        done()
      })
    },
    rowEdit(data, done) {
      data.menuId = data.menuId.join(',')
      update(data).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
  },
}
</script>

<style scoped>

</style>
