<template>
  <div class="card card-body">
    <b-overlay
      :show="showLoading"
      rounded="sm"
      variant="light"
      opacity="0.85"
      blur="2px"
      spinnerVariant="primary">
      <b-tabs align="center">
        <b-tab>
          <template #title>
            <span class="x-text-bold font-medium-2 ml-2 mr-2">营销设置</span>
          </template>
          <div v-if="marketingSetup" class="row d-flex flex-column align-items-center pt-5">
            <x-form-validator ref="formObserver" class="col-md-6">
              <div class="col-md-6 font-medium-2 x-text-bold d-flex flex-row justify-content-between mb-1">
                <div class="d-flex flex-row align-items-center">
                  <div class="x-indicator mr-50"></div>运营分销设置
                </div>
              </div>
              <x-form-feild label="技术服务费（%）" :tip="technicalTip" class="pb-1" require>
                <b-form-spinbutton :disabled="true" :value="marketingSetup.platform + marketingSetup.referee" min="0" max="36" step="0.1" placeholder="请选择运营抽成比例（%）"></b-form-spinbutton>
              </x-form-feild>
              <x-form-feild label="运营抽成比例 - 平台（%）" :tip="platformTip" class="pb-1" require>
                <b-form-input v-model="marketingSetup.platform" type="range" min="0" max="36" step="0.1" placeholder="请选择运营抽成比例（%）"></b-form-input>
                <b-form-spinbutton  v-model="marketingSetup.platform" min="0" max="36" step="0.1" placeholder="请选择运营抽成比例（%）"></b-form-spinbutton>
              </x-form-feild>
              <x-form-feild label="运营抽成比例 - 推荐人（%）" :tip="refereeTip" class="pb-1" require>
                <b-form-input v-model="marketingSetup.referee" type="range" min="0" max="80" step="0.1" placeholder="请选择运营抽成比例（%）"></b-form-input>
                <b-form-spinbutton v-model="marketingSetup.referee" min="0" max="36" step="0.1" placeholder="请选择运营抽成比例（%）"></b-form-spinbutton>
              </x-form-feild>
              <div class="col-md-6 font-medium-2 x-text-bold d-flex flex-row justify-content-between mb-1">
                <div class="d-flex flex-row align-items-center">
                  <div class="x-indicator mr-50"></div>软件分销设置
                </div>
              </div>
              <x-form-feild label="软件抽成比例（%）" :tip="softwareTip" class="pb-1" require>
                <b-form-input v-model="marketingSetup.software" type="range" min="0" max="80" step="0.1" placeholder="请选择软件抽成比例（%）"></b-form-input>
                <b-form-spinbutton v-model="marketingSetup.software" min="0" max="36" step="0.1" placeholder="请选择软件抽成比例（%）"></b-form-spinbutton>
              </x-form-feild>
              <div class="col-md-6 font-medium-2 x-text-bold d-flex flex-row justify-content-between mb-1">
                <div class="d-flex flex-row align-items-center">
                  <div class="x-indicator mr-50"></div>提现设置
                </div>
              </div>
              <x-form-feild label="提现门槛费（元）" :tip="minWithdrawalAmountTip" class="pb-1" require>
                <b-form-input v-model="marketingSetup.minWithdrawalAmount" type="number" placeholder="请输入提现门槛费（元）"></b-form-input>
              </x-form-feild>
            </x-form-validator>
          </div>
        </b-tab>
      </b-tabs>
      <div class="d-flex justify-content-center">
        <div class="btn btn-light-secondary" @click="cancel">取消</div>
        <div class="btn btn-primary ml-2" @click="setup">确定</div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay, BTabs, BTab, BFormInput, BFormSpinbutton,
} from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import {
  detail, setup,
} from '@/api/marketing/setup'

export default {
  components: {
    BOverlay,
    BTabs,
    BTab,
    XFormValidator,
    XFormFeild,
    BFormInput,
    BFormSpinbutton,
  },
  data() {
    return {
      showLoading: false,
      technicalTip: '技术服务费包含：平台运营抽成和推荐人运营抽成两部分',
      platformTip: '平台收取商户的运营佣金，主要是会员充值费用',
      refereeTip: '支付给推荐人的运营佣金，主要是会员充值费用',
      softwareTip: '支付给推荐人的软件销售佣金，主要是软件销售费用',
      minWithdrawalAmountTip: '商户提现的最小金额',
      commissionTip: '商户提现时平台收取的手续费',
      softwareSecondTip: '支付给推荐人的推荐人软件销售佣金，主要是软件销售费用',
      lastMarketingSetup: {
        // 运营佣金 - 给平台
        platform: 0,
        // 运营佣金 - 给推荐人
        referee: 0,
        // 软件销售佣金 - 给推荐人
        software: 0,
        // 软件销售佣金 -给推荐人的推荐人
        softwareSecond: 0,
        // 最小提现金额
        minWithdrawalAmount: 0,
      },
      marketingSetup: undefined,
    }
  },
  created() {
    this.marketingSetup = this._.cloneDeep(this.lastMarketingSetup)
    this.search()
  },
  methods: {
    search() {
      this.showLoading = true
      detail().then(resp => {
        console.log(resp)
        this.lastMarketingSetup = resp.data.data
        this.marketingSetup = this._.cloneDeep(this.lastMarketingSetup)
        this.showLoading = false
      })
    },
    cancel() {
      this.marketingSetup = this._.cloneDeep(this.lastMarketingSetup)
    },
    setup() {
      this.$refs.formObserver.validate().then(result => {
        if (result) {
          setup(this.marketingSetup).then(() => {
            this.$xtoast.success('设置成功！')
            this.search()
          })
        }
      })
    },
  },
}
</script>
