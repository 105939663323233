<template>
  <div v-if="dict.id">
    <x-el-table
      ref="childTable"
      :options="options"
      @beforeShow="beforeShow"
      @onFormHide="onFormHide"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowAddChild="rowAddChild"
      @rowDelete="rowDelete">
      <template slot="dictValue" slot-scope="scope">
        <b-badge variant="primary">{{scope.row.dictValue}}</b-badge>
      </template>
    </x-el-table>
  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import {
  getChildList, add, update, remove,
} from '@/api/system/dictbiz'
import { BBadge } from 'bootstrap-vue'

export default {
  name: 'Dict',
  components: {
    XElTable,
    BBadge,
  },
  props: {
    dict: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      child: undefined,
      childFlag: false,
      options: {
        actions: [
          { action: this.$x.biz.FormType.VIEW, name: '查看', icon: 'EyeIcon' },
          { action: this.$x.biz.FormType.EDIT, name: '编辑', icon: 'EditIcon' },
          { action: this.$x.biz.FormType.DELETE, name: '删除', icon: 'Trash2Icon' },
          { action: 'addChild', name: '新增下一级', icon: 'AnchorIcon' },
        ],
        columns: [
          {
            label: '字典编号',
            prop: 'code',
            addDisable: true,
            editDisable: true,
            viewDisable: true,
            rules: {
              rule: 'required',
              message: '请输入字典编号',
            },
          },
          {
            label: '字典名称',
            prop: 'dictValue',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入字典名称',
            },
          },

          {
            label: '上级字典',
            prop: 'parentId',
            searchShow: false,
            addDisable: true,
            editDisable: true,
            rowShow: false,
            type: 'tree',
            rowSelect: true,
            dictUrl: '/apollo-upms/dict-biz/tree?code=DICT',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
            },
          },
          {
            label: '字典键值',
            prop: 'dictKey',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入键值',
            },
          },
          {
            label: '排序',
            type: 'number',
            prop: 'sort',
            searchShow: false,
            rowShow: false,
            rules: {
              rule: 'required',
              message: '请输入排序',
            },
          },
          {
            label: '是否禁用',
            prop: 'isSealed',
            type: 'select',
            rowSelect: true,
            searchShow: false,
            dictData: [
              {
                label: '否',
                value: '0',
              },
              {
                label: '是',
                value: '1',
              },
            ],
            rules: {
              rule: 'required',
              message: '请选择是否封存',
            },
          },
          {
            label: '备注',
            type: 'textarea',
            prop: 'remark',
            searchShow: false,
            rowShow: false,
          },
        ],
      },
    }
  },
  methods: {
    beforeShow(type, formData) {
      formData.code = this.dict.code
      if (!type.isAdd) {
        if (formData.isSealed >= 0) {
          formData.isSealed = formData.isSealed.toString()
        }
      }
      if (this.childFlag) {
        formData.parentId = this.child.id
      } else {
        formData.parentId = this.dict.id
      }
    },
    onFormHide() {
      this.childFlag = false
      this.child = undefined
    },
    searchHandle(page, params, done) {
      getChildList(page.pageIndex, page.pageSize, this.dict.id, params).then(resp => {
        done(resp.data.data, resp.data.data.length)
      })
    },
    addHandle(data, done) {
      add(data).then(() => {
        done()
      })
    },
    rowEdit(data, done) {
      update(data).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowAddChild(data) {
      this.child = data
      this.childFlag = true
      this.$refs.childTable.showAddForm()
    },
  },
}
</script>

<style scoped>

</style>
