import request from '@/auth/jwt/useJwt'

export const getList = (current, size) => request.axiosIns({
  url: '/apollo-jxz/feedback/page',
  method: 'get',
  params: {
    current,
    size,
  },
})

export const getList2 = (current, size) => request.axiosIns({
  url: '/apollo-jxz/feedback/page',
  method: 'get',
  params: {
    current,
    size,
  },
})
