import request from '@/auth/jwt/useJwt'

export const getALL = () => request.axiosIns({
  url: '/apollo-jxz/package/all',
  method: 'get',
})

export const setupPersonalPackage = data => request.axiosIns({
  url: '/apollo-jxz/package/personal/setup',
  method: 'put',
  data,
})

export const setupCommercialPackage = data => request.axiosIns({
  url: '/apollo-jxz/package/commercial/setup',
  method: 'put',
  data,
})
