<template>
  <div>
    <x-el-table
      ref="table"
      :permissions="{ add: 'sms_add' }"
      :options="options"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowEnable="rowEnable"
      @rowDebug="rowDebug"
      @rowDelete="rowDelete"
      @beforeShow="beforeShow"
    ></x-el-table>
    <b-modal
      :visible="showModal"
      title-class="x-text-bold"
      ok-title="发送"
      cancel-title="取消"
      cancel-variant="flat-secondary"
      title="上传附件"
      centered
      @hidden="hide"
      @ok="send">
      <div class="d-flex justify-content-center">
        <x-form-validator ref="refFormObserver" class="card card-body">
          <x-form-feild label="发送手机" require>
            <b-form-input
              v-model="sendForm.phones"
              type="number"
              placeholder="请输入发送手机号"
            />
          </x-form-feild>
          <x-form-feild label="发送参数" require>
            <b-form-input
              v-model="sendForm.params"
              placeholder="例: {'code':4E5X,'title':'短信通知'}"
            />
          </x-form-feild>
        </x-form-validator>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BFormInput,
} from 'bootstrap-vue'
import XElTable from '@core/components/cx/table/XElTable.vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import { getList, add, update, remove, enable, send } from '@/api/resource/sms'

export default {
  name: 'Attach',
  components: {
    BModal,
    BFormInput,
    XElTable,
    XFormValidator,
    XFormFeild,
  },
  data() {
    return {
      showModal: false,
      debugRow: undefined,
      sendForm: {},
      options: {
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon', permission: 'sms_view' },
          { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon', permission: 'sms_edit' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon', permission: 'sms_delete' },
          { action: 'debug', tip: '调试', icon: 'CodeIcon', permission: 'sms_debug' },
          { action: 'enable', tip: '启用', icon: 'PlayIcon', permission: 'sms_enable' },
        ],
        columns: [
          {
            label: '服务商',
            prop: 'category',
            type: 'select',
            rowSelect: true,
            dictUrl: '/apollo-upms/dict/dictionary?code=sms',
            rules: {
              rule: 'required',
              message: '请选择服务商',
            },
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            onDictSelected: (option, columns) => {
              columns[4].viewShow = option !== 1
              columns[4].addShow = option !== 1
              columns[4].editShow = option !== 1
              columns[5].viewShow = option === 3
              columns[5].addShow = option === 3
              columns[5].editShow = option === 3
              // if (type === this.$x.biz.FormType.EDIT || type === this.$x.biz.FormType.ADD) {
              // }
            },
          },
          {
            label: '短信模版',
            prop: 'templateId',
            rules: {
              rule: 'required',
              message: '请输入模版ID或模版内容',
            },
          },
          {
            label: '短信签名',
            prop: 'signName',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入短信签名',
            },
          },
          {
            label: 'accessKey',
            prop: 'accessKey',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入accessKey',
            },
          },
          {
            label: 'secretKey',
            prop: 'secretKey',
            searchShow: false,
            rowShow: false,
            addShow: false,
            rules: {
              rule: 'required',
              message: '请输入accessKey',
            },
          },
          {
            label: 'regionId',
            prop: 'regionId',
            searchShow: false,
            rowShow: false,
            addShow: false,
          },
          {
            label: '是否启用',
            prop: 'status',
            type: 'select',
            rowSelect: true,
            addShow: false,
            editShow: false,
            dictData: [
              { label: '否', value: '1' },
              { label: '是', value: '2' },
            ],
            selectVariant: value => value === 1 ? 'danger' : 'success',
          },
          {
            label: '备注',
            prop: 'remark',
            rowShow: false,
            searchShow: false,
            type: 'textarea',
          },
        ],
      },
    }
  },
  methods: {
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    beforeShow(type, formData) {
      if (type.isAdd) {
        formData.category = '1'
      } else {
        formData.category = formData.category ? formData.category.toString() : formData.category
        formData.status = formData.status ? formData.status.toString() : formData.status
      }
    },
    addHandle(data, done) {
      add(data).then(() => {
        done()
      })
    },
    rowEdit(data, done) {
      update(data).then(() => {
        done()
      })
    },
    rowEnable(data, done) {
      enable(data.id).then(() => {
        done()
      })
    },
    rowDebug(data) {
      this.showModal = true
      this.debugRow = data
    },
    hide() {
      this.showModal = false
      this.sendForm = {}
      this.debugRow = undefined
    },
    send(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          send(this.debugRow.smsCode, this.sendForm.phones, this.sendForm.params).then(() => {
            this.$xtoast.success('发送成功！')
            this.hide()
          })
        }
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
  },
}
</script>

<style scoped>

</style>
