<template>
  <div>
    <x-el-table
      ref="table"
      :permissions="{ add: 'oss_add' }"
      :options="options"
      @beforeShow="beforeShow"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowEnable="rowEnable"
      @rowDebug="rowDebug"
      @rowDelete="rowDelete">
      <template slot="ossCode" slot-scope="scope">
        <div class="text-primary x-text-normal x-link d-inline" @click="() => $refs.table.actionClick(options.actions[0],scope)">{{ scope.row.ossCode }}</div>
      </template>
    </x-el-table>
    <b-modal
      :visible="showUploadModal"
      title-class="x-text-bold"
      ok-only
      ok-title="取消"
      ok-variant="flat-secondary"
      title="上传附件"
      centered
      @hidden="hide"
      @ok="hide">
      <div class="d-flex justify-content-center mt-4">
        <el-upload
          drag
          :action="debugUploadUrl"
          :headers="headers"
          :before-upload="beforeUpload"
          :on-success="uploadSuccess">
          <img v-if="imageUrl" class="x-w-100 x-h-100" style="object-fit: contain !important;" :src="imageUrl" />
          <div v-else>
            <i class="el-icon-upload text-primary"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </div>
          <b-alert show variant="warning" slot="tip">
            <div class="alert-body mt-1">
              <span class="el-icon-info"></span>
              只能上传jpg/png文件，且不超过5MB
            </div>
          </b-alert>
        </el-upload>
      </div>
    </b-modal>
  </div>
</template>

<script>

import {
  BModal, BAlert,
} from 'bootstrap-vue'
import XElTable from '@core/components/cx/table/XElTable.vue'
import { getList, add, update, remove, enable } from '@/api/resource/oss'
import { Headers } from '@/api/resource/attach'
import website from '@/config/website'

export default {
  name: 'Oss',
  components: {
    BModal,
    XElTable,
    BAlert,
  },
  data() {
    return {
      showUploadModal: false,
      headers: Headers,
      prefix: website.prefix,
      debugUploadUrl: '',
      imageUrl: undefined,
      options: {
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon', permission: 'oss_view' },
          { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon', permission: 'oss_edit' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon', permission: 'oss_delete' },
          { action: 'debug', name: '调试', tip: '调试', icon: 'CodeIcon', permission: 'oss_debug' },
          { action: 'enable', name: '启用', tip: '启用', icon: 'PlayIcon', permission: 'oss_enable' },
        ],
        columns: [
          {
            label: '资源编码',
            prop: 'ossCode',
            rules: {
              rule: 'required',
              message: '请输入bucket名称',
            },
          },
          {
            label: '服务商',
            prop: 'category',
            type: 'select',
            rowSelect: true,
            //   rowCls: 'badge badge-light-primary',
            dictUrl: '/apollo-upms/dict/dictionary?code=oss',
            rules: {
              rule: 'required',
              message: '请选择服务商',
            },
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            onDictSelected: (option, colums, data, type) => {
              if (type === this.$x.biz.FormType.EDIT || type === this.$x.biz.FormType.ADD) {
                let ioption = parseInt(option, 0)
                switch (ioption) {
                  case 4:
                    colums[6].addShow = true
                    colums[6].editShow = true
                    colums[7].addShow = true
                    colums[7].editShow = true
                    break
                  default:
                    colums[6].addShow = false
                    colums[6].editShow = false
                    colums[7].addShow = false
                    colums[7].editShow = false
                    break
                }
              }
            },
          },
          {
            label: '服务地址',
            prop: 'endpoint',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入服务地址',
            },
          },
          {
            label: 'bucket名称',
            prop: 'bucketName',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入bucket名称',
            },
          },
          {
            label: 'accessKey',
            prop: 'accessKey',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入accessKey',
            },
          },
          {
            label: 'secretKey',
            prop: 'secretKey',
            rowShow: false,
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入secretKey',
            },
          },
          {
            label: 'appId',
            prop: 'appId',
            rowShow: false,
            addShow: false,
            editShow: false,
          },
          {
            label: 'region',
            prop: 'region',
            rowShow: false,
            addShow: false,
            editShow: false,
            searchShow: false,
          },
          {
            label: '是否启用',
            prop: 'status',
            type: 'select',
            rowSelectDot: true,
            addShow: false,
            editShow: false,
            dictData: [
              { label: '否', value: '1' },
              { label: '是', value: '2' },
            ],
            selectVariant: value => value === 1 ? 'text-danger' : 'text-success',
          },
          {
            label: '备注',
            searchShow: false,
            prop: 'remark',
            rowShow: false,
            type: 'textarea',
          },
        ],
      },
    }
  },
  methods: {
    beforeShow(type, formData) {
      if (type.isView || type.isEdit) {
        formData.category = formData.category ? formData.category.toString() : formData.category
        formData.status = formData.status ? formData.status.toString() : formData.status
      }
    },
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    addHandle(data, done) {
      add(data).then(() => {
        done()
      })
    },
    rowEdit(data, done) {
      update(data).then(() => {
        done()
      })
    },
    rowEnable(data, done) {
      enable(data.id).then(() => {
        done()
      })
    },
    rowDebug(data) {
      this.debugUploadUrl = this.prefix + '/apollo-resource/oss/endpoint/put-file?' + data.ossCode
      this.showUploadModal = true
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$xtoast.error('上传图片大小不能超过5MB！')
      }
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isImage) {
        this.$xtoast.error('上传图片只能是JPG或PNG格式！')
      }
      return isLt5M && isImage
    },
    uploadSuccess(result) {
      this.imageUrl = result.data.link
    },
    hide() {
      this.showUploadModal = false
      this.imageUrl = undefined
    },
  },
}
</script>

<style scoped>

</style>
