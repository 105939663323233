import request from '@/auth/jwt/useJwt'

export const _ = undefined

export const getPage = (current, size, params) => request.axiosIns({
  url: '/apollo-jxz/game/area/page',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})
