import request from '@/auth/jwt/useJwt'

export function getList(current, size, params) {
  return request.axiosIns({
    url: '/apollo-upms/post/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}

export function getPostList(tenantId) {
  return request.axiosIns({
    url: '/apollo-upms/post/select',
    method: 'get',
    params: {
      tenantId,
    },
  })
}

export function getDetail(id) {
  return request.axiosIns({
    url: '/apollo-upms/post/detail',
    method: 'get',
    params: {
      id,
    },
  })
}

export function remove(ids) {
  return request.axiosIns({
    url: '/apollo-upms/post/remove',
    method: 'post',
    params: {
      ids,
    },
  })
}

export function add(row) {
  return request.axiosIns({
    url: '/apollo-upms/post/submit',
    method: 'post',
    data: row,
  })
}

export function update(row) {
  return request.axiosIns({
    url: '/apollo-upms/post/submit',
    method: 'post',
    data: row,
  })
}
