<template>
  <div>
    <x-el-table
      :options="options"
      @search="searchHandle">
      <template slot='title' slot-scope='scope'>
      <b-badge variant="primary"> {{scope.row.title}}</b-badge>
      </template>
    </x-el-table>
  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import { BBadge } from 'bootstrap-vue'
import {
  getUsualList,
} from '@/api/system/log'

export default {
  name: 'ApiLog',
  components: {
    XElTable,
    BBadge,
  },
  data() {
    return {
      options: {
        addBtn: false,
        actions: [
          { action: 'view', tip: '查看', icon: 'EyeIcon' },
        ],
        columns: [
          {
            label: '服务IP',
            prop: 'serverIp',
          },
          {
            label: '软件环境',
            prop: 'env',
          },
          {
            label: '日志标题',
            prop: 'title',
            searchShow: false,
          },
          {
            label: '日志级别',
            prop: 'logLevel',
            searchShow: false,
          },
          {
            label: '请求方法',
            prop: 'method',
          },
          {
            label: '请求接口',
            prop: 'requestUri',
          },
          {
            label: '日志时间',
            prop: 'createTime',
          },
        ],
      },
    }
  },
  methods: {
    beforeShow(type, formData) {
      formData.category = formData.category.toString()
    },
    searchHandle(page, params, done) {
      getUsualList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
  },
}
</script>

<style scoped>

</style>
