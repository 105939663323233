<template>
  <x-form-validator ref="refFormObserver" class="card card-body">
    <x-form-feild label="父区划编号" require>
      <b-form-input
        v-model="formData.parentCode"
        disabled
        placeholder="请输入父区划编号"
      />
    </x-form-feild>
    <x-form-feild label="父区划名称" require>
      <b-form-input
        v-model="formData.parentName"
        disabled
        placeholder="请输入父区划名称"
      />
    </x-form-feild>
    <x-form-feild label="区划编号" require>
      <el-input :disabled="disabled" placeholder="请输入区划子编号" v-model="formData.subCode">
        <template slot="prepend">{{formData.parentCode}}</template>
      </el-input>
    </x-form-feild>
    <x-form-feild label="区划名称" require>
      <b-form-input
        v-model="formData.name"
        :disabled="disabled"
        placeholder="请输入区划名称"
      />
    </x-form-feild>
    <x-form-feild label="区划等级" require>
      <div>
        <el-radio-group v-model="formData.regionLevel"
                        :disabled="disabled">
          <el-radio v-for="(level,index) in regionLevels" :label="parseInt(level.dictKey,0)" :key="index">{{level.dictValue}}</el-radio>
        </el-radio-group>
      </div>
    </x-form-feild>
    <x-form-feild label="排序" require>
      <b-form-input
        v-model="formData.sort"
        type="number"
        :disabled="disabled"
        placeholder="请输入排序值"
      />
    </x-form-feild>
    <x-form-feild label="备注">
      <b-form-textarea
        v-model="formData.remark"
        :disabled="disabled"
        placeholder="请输入备注"
      />
    </x-form-feild>
    <div class="d-flex justify-content-center x-w-100">
      <div class="mr-2 btn" @click="reset()" :class="disabled ?  'disabled bg-light-danger btn-text-danger' : 'btn-light-danger'">
        重置
      </div>
      <b-button v-permission="`region_edit`" variant="primary" @click="enter()"
                :disabled="disabled">
        提交
      </b-button>
    </div>
  </x-form-validator>
</template>

<script>
import {
  BFormInput, BFormTextarea, BButton,
} from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import { getDictData } from '@/api/system/dict'

export default {
  name: 'RegionForm',
  components: {
    XFormValidator,
    XFormFeild,
    BFormInput,
    BFormTextarea,
    BButton,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    data() {
      this.formData = this._.cloneDeep(this.data) || {}
    },
  },
  data() {
    return {
      formData: this._.cloneDeep(this.data) || {},
      regionLevels: [],
    }
  },
  created() {
    getDictData('/apollo-upms/dict/dictionary?code=region').then(resp => {
      this.regionLevels = resp.data.data
    })
  },
  methods: {
    enter() {
      this.$refs.refFormObserver.validate().then(() => {
        this.$emit('enter', this.formData, () => {
          Object.keys(this.formData).forEach(key => {
            this.formData[key] = null
          })
          console.log(this.formData)
          this.$refs.refFormObserver.reset()
        })
      })
    },
    reset() {
      this.$refs.refFormObserver.reset()
      this.$emit('reset')
    },
  },
}
</script>

<style scoped>

</style>
