<template>
  <div>
    <x-el-table
      ref="table"
      :options="options"
      @search="searchHandle">
    </x-el-table>
  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
// import { BBadge, BSidebar, BAvatar } from 'bootstrap-vue'
import {
  getList,
} from '@/api/data/area'

export default {
  name: 'Area',
  components: {
    XElTable,
  },
  props: {
    queryShopId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      options: {
        addBtn: false,
        actions: [],
        columns: [
          {
            label: '分区名称',
            prop: 'name',
          },
          {
            label: '所属商户',
            prop: 'mtId',
            type: 'select',
            rowSelect: true,
            dictUrl: '/apollo-jxz/gm/merchant/all',
            props: {
              label: 'nickName',
              value: 'id',
            },
            searchShow: false,
          },
          {
            label: '所属店铺',
            prop: 'shopId',
            type: 'select',
            rowSelect: true,
            searchShow: false,
            dictUrl: '/apollo-jxz/shop/all',
            props: {
              label: 'name',
              value: 'id',
            },
          },
          {
            label: '机器收费(小时)',
            prop: 'price',
            searchShow: false,
          },
          {
            label: '包夜开始时间',
            prop: 'nightStartTime',
            searchShow: false,
          },
          {
            label: '包夜结束时间',
            prop: 'nightEndTime',
            searchShow: false,
          },
          {
            label: '包夜价格',
            prop: 'nightPrice',
            searchShow: false,
          },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      if (this.queryShopId) {
        params.shopId = this.queryShopId
      }
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
  },
}
</script>

<style lang="scss">
</style>
