import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-jxz/machine/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const remove = ids => request.axiosIns({
  url: '/apollo-jxz/machine/remove',
  method: 'post',
  params: {
    ids,
  },
})

export const removeById = id => request.axiosIns({
  url: `/apollo-jxz/machine/remove/${id}`,
  method: 'post',
})

export const add = row => request.axiosIns({
  url: '/apollo-jxz/machine/submit',
  method: 'post',
  data: row,
})

export const edit = row => request.axiosIns({
  url: '/apollo-jxz/machine/edit',
  method: 'post',
  data: row,
})

// 解绑
export const unBind = id => request.axiosIns({
  url: '/apollo-jxz/machine/unBind',
  method: 'post',
  params: {
    id,
  },
})
