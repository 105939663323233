<template>
  <div class="xtable__form pt-1 pl-1 pr-1" v-if="showForm">
    <validation-observer ref="formObserver">
      <b-form>
        <b-row>
          <b-col cols="12" v-for="(column,index) in formColumns" :key="index">
            <div  v-if="(isView && column.viewShow !== false) || ((isAdd && column.addShow !== false) || (!isAdd && !isView && column.editShow !== false))">
              <div v-if="column.children" >
                <div class="font-medium-1 x-text-bold d-flex flex-row  mb-1 text-body justify-content-between "  v-b-toggle="`id-${propPrefix}-${column.prop}`">
                  <div class="d-flex flex-row ">
                    <div class="x-indicator"></div>
                    <div class="d-flex flex-row align-items-center">
                      {{column.label}}
                      <i v-if="column.tip" class="el-icon-question ml-50 text-secondary text-lighten-5 font-medium-1" v-b-tooltip.hover.top="column.tip"></i>
                    </div>
                  </div>
                  <div :class="column.____visible ? 'xtable__form-arrowdown' : 'xtable__form-arrowup'">
                    <feather-icon class="text-primary" icon="ChevronDownIcon" size="20"/>
                  </div>
                </div>
                <b-collapse v-model="column.____visible" :id="`id-${propPrefix}-${column.prop}`" class="ml-1 mb-1">
                  <XTableForm v-model="data[column.prop]" :propPrefix="`${propPrefix}-${column.prop}`" :isAdd="isAdd" :isView="isView" :expandAll="expandAll" :groupType="groupType"
                              :labelVertical="labelVertical" :columns="column.children" :isTop="false">
                    <slot :slot="`form${propPrefix}-${column.prop}-${child.prop}`" v-for="(child) in column.children" slot-scope="scope">
                      <slot :name="`form${propPrefix}-${column.prop}-${child.prop}`" :data.sync="scope.data" :row.sync="data" :type="getFormType()"></slot>
                    </slot>
                  </XTableForm>
                </b-collapse>
              </div>
              <b-form-group v-else :label-cols-md="labelVertical ? 0 : 2" :label="column.labelShow && !labelVertical ? column.label + '：' : ''">
                <label v-if="labelVertical">
                  <span v-if="column.rules && column.rules.rule" class="x-require-indicator font-medium-2 x-text-bold">*</span>
                  <span class="font-medium-1 x-text-bold text-body">{{column.labelShow ? column.label + '：': ''}}</span>
                  <i v-if="column.tip" class="el-icon-question ml-50 text-secondary text-lighten-5 font-medium-1" v-b-tooltip.hover.top="column.tip"></i>
                </label>
                <validation-provider
                  #default="{ errors }"
                  :name="column.label"
                  :rules="column.rules ? column.type === 'upload' && data[column.prop].length > 0 ? '' : column.rules.rule : ''">
                  <x-form-repeater
                    v-if="column.type === 'repeater'"
                    :id="column.prop"
                    :isView="isView"
                    :data="data[column.prop]">
                    <slot slot="form-repeater" slot-scope="scope">
                      <slot :name="`form${propPrefix}-${column.prop}`" :row="scope.row" :data="scope.data" :type="getFormType()">
                      </slot>
                    </slot>
                  </x-form-repeater>
                  <slot v-else :name="`form${propPrefix}-${column.prop}`" :data="data[column.prop]" :row="data" :type="getFormType()">
                    <v-select v-if="column.type === 'select'"
                              :id="column.prop"
                              v-model="data[column.prop]"
                              :disabled="isView || (isAdd && column.addDisable) || (!isAdd && column.editDisable)"
                              :options="column.dictData"
                              label="label"
                              v-on:option:selected="column.onDictSelected(data[column.prop], formColumns, data, getFormType())"
                              :reduce="option => option.value || option[column.props.value]"
                              :getOptionLabel="column.getDictLabel"
                              :placeholder="column.placeholder">
                      <template slot="no-options">{{$t('noData')}}</template>
                    </v-select>
                    <div v-else-if="column.type === 'tree'">
                      <el-cascader  v-model="data[column.prop]"
                                    class="x-w-100"
                                    :disabled="isView || (isAdd && column.addDisable) || (!isAdd && column.editDisable)"
                                    :collapse-tags="column.props.collapseTags"
                                    :options="column.dictData" :props="column.props" clearable>
                      </el-cascader>
                    </div>
                    <b-form-radio-group v-else-if="column.type === 'radio'" class="ml-1 mr-1"
                                        :id="column.prop"
                                        :disabled="isView || (isAdd && column.addDisable) || (!isAdd && column.editDisable)"
                                        v-model="data[column.prop]"
                                        :options="getSelectOptions(column.dictData)">
                    </b-form-radio-group>
                    <b-form-checkbox v-else-if="column.type === 'switch'" :id="column.prop"
                                     :disabled="isView || (isAdd && column.addDisable) || (!isAdd && column.editDisable)"
                                     v-model="data[column.prop]" switch inline
                                     @change="column.onSwitchChange(data[column.prop], formColumns)">
                    </b-form-checkbox>
                    <b-form-checkbox-group v-else-if="column.type === 'checkbox'" class="ml-1 mr-1 "
                                           :id="column.prop"
                                           :disabled="isView || (isAdd && column.addDisable) || (!isAdd && column.editDisable)"
                                           v-model="data[column.prop]"
                                           :options="getSelectOptions(column.dictData)">
                    </b-form-checkbox-group>
                    <x-icon-selector v-else-if="column.type === 'icons'" :id="column.prop" v-model="data[column.prop]"
                                   :disabled="isView || (isAdd && column.addDisable) || (!isAdd && column.editDisable)"></x-icon-selector>
                    <el-date-picker
                      v-else-if="column.type === 'date' || column.type === 'datetime' || column.type === 'datetimerange'"
                      v-model="data[column.prop]"
                      :disabled="isView || (isAdd && column.addDisable) || (!isAdd && column.editDisable)"
                      :type="column.type"
                      :placeholder="column.placeholder"
                      :picker-options="column.pickerOptions ? column.pickerOptions : pickerOptions"
                      :value-format="column.format || 'yyyy-MM-dd HH:mm:ss'"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      class="w-100"
                      align="right"
                    >
                    </el-date-picker>
                    <b-form-textarea
                      v-else-if="column.type === 'textarea'"
                      :id="column.prop"
                      v-model="data[column.prop]"
                      :disabled="isView || (isAdd && column.addDisable) || (!isAdd && column.editDisable)"
                      :placeholder="column.placeholder"
                      :rows="column.rows"
                    />
                    <el-upload
                      :id="column.prop"
                      v-else-if="column.type === 'upload'"
                      :action="column.upload.url"
                      :headers="column.upload.headers"
                      :data="column.upload.data"
                      :name="column.upload.name"
                      :accept="column.upload.accept"
                      :disabled="isView || (isAdd && column.addDisable) || (!isAdd && column.editDisable)"
                      :on-preview="handlePreview"
                      :before-upload="column.upload.beforeUpload"
                      :before-remove="beforeRemove"
                      :on-remove="(file, fileList) => column.upload.onRemove(onUploadRemove(file,data,column.prop), fileList)"
                      :on-success="(response, file, fileList) => column.upload.onSuccess(response, setUploadFormData(response.data.link,data,column.prop), file, fileList)"
                      :on-error="column.upload.onError"
                      :limit="column.upload.limit"
                      :on-exceed="handleExceed"
                      :file-list="data[column.prop]">
                      <b-button :disabled="isView || (isAdd && column.addDisable) || (!isAdd && column.editDisable)" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm">点击上传</b-button>
                      <!--<i class="el-icon-upload"></i>-->
                      <!--<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>-->
                      <div v-if="column.upload.tip" slot="tip" class="el-upload__tip">
                        <b-alert variant="primary" show>
                          <div class="alert-body d-flex align-items-end ">
                            <!--<i class="el-icon-warning" style="margin-right: .2rem"></i>-->
                            <feather-icon class="text-primary mr-25" icon="AlertCircleIcon"/>
                            <span>{{column.upload.tip}}</span>
                          </div>
                        </b-alert>
                      </div>
                    </el-upload>
                    <div v-else>
                      <cleave
                        v-if="column.mask"
                        :id="column.prop"
                        v-model="data[column.prop]"
                        :disabled="isView || (isAdd && column.addDisable) || (!isAdd && column.editDisable)"
                        :raw="false"
                        :options="column.mask"
                        :placeholder="column.placeholder"/>
                      <b-form-input
                        v-else
                        :id="column.prop"
                        :disabled="isView || (isAdd && column.addDisable) || (!isAdd && column.editDisable)"
                        v-model="data[column.prop]"
                        :placeholder="column.placeholder"/>
                    </div>
                  </slot>
                  <small class="text-danger" v-if="errors[0]">{{column.rules && column.rules.message ? column.rules.message : column.label + '不能为空' }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'
import XIconSelector from '@core/components/cx/icons/XIconSelector.vue'
import vSelect from 'vue-select'
import {
  BRow, BCol, BButton, BForm, BFormInput, BFormGroup, BFormTextarea, BFormRadioGroup, BFormCheckboxGroup, BCollapse, VBToggle, BAlert,
  BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import XFormRepeater from './XFormRepeater.vue'
import { setAddFormData, setUpload } from './xform'

export default {
  name: 'XTableForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    Cleave,
    vSelect,
    BFormTextarea,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BCollapse,
    BAlert,
    BFormCheckbox,
    XFormRepeater,
    XIconSelector,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  props: {
    /**
     * 是否新增表单
     */
    isAdd: {
      type: Boolean,
      default: true,
    },
    /**
     * 是否查看表单
     */
    isView: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否展开所有
     */
    expandAll: {
      type: Boolean,
      default: true,
    },
    /**
     * 分组表单类型: collapse(折叠面板) / step(分布表单)
     */
    groupType: {
      type: String,
      default: 'collapse',
    },
    /**
     * 是否label垂直
     */
    labelVertical: {
      type: Boolean,
      default: true,
    },
    /**
     * 列字段
     */
    columns: {
      type: Array,
      default: () => [],
    },
    /**
     * 表单数据
     */
    value: {
      type: Object,
      default: () => {},
    },
    /**
     *  prop属性值前缀，默认为空
     */
    propPrefix: {
      type: String,
      default: '',
    },
    /**
     *  是否为顶层form 非递归form
     */
    isTop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      data: {},
      formColumns: {},
      showForm: !!this.value,
      required,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          },
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          },
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          },
        }],
      },
    }
  },
  watch: {
    data: {
      handler() {
      },
      deep: true,
    },
  },
  created() {
    // 新增不重新clone 负责会导致form数据更新 页面不刷新问题
    this.data = this.value
    if (this.isTop) {
      this.formColumns = this._.cloneDeep(this.columns)
    } else {
      this.formColumns = this.columns
    }
    this.labelVertical = this.labelVertical !== false
    this.reset()
    if (this.isAdd) {
      this.$emit('beforeShow', { isAdd: true }, this.data, this.formColumns)
    } else {
      this.$emit('beforeShow', this.isView ? { isView: true } : { isEdit: true }, this.data, this.formColumns)
    }
    this.$nextTick(() => {
      this.showForm = true
    })
  },
  methods: {
    validate() {
      return this.$refs.formObserver.validate()
    },
    // 获取form类型： 新增、编辑、删除
    getFormType() {
      if (this.isAdd) {
        return this.$x.biz.FormType.ADD
      }
      return this.isView ? this.$x.biz.FormType.VIEW : this.$x.biz.FormType.EDIT
    },
    // 清楚未显示列数据
    clearUnShowColumnData(columns, formData) {
      columns.forEach(item => {
        // if ((this.isAdd && item.addShow !== true) || (!this.isView && item.editShow !== true)) {
        //   formData[item.prop] = null
        // }
        if (item.children) {
          this.clearUnShowColumnData(item.children, formData[item.prop])
        }
        if (item.type === 'upload' && formData[item.prop] && formData[item.prop].length > 0) {
          formData[item.prop] = formData[item.prop][0].url
        }
      })
    },
    // 获取form数据 深拷贝
    getFormData() {
      let formData = this._.cloneDeep(this.data)
      this.clearUnShowColumnData(this.formColumns, formData)
      return formData
    },
    // 上传文件组件移除文件回调
    onUploadRemove: (file, data, prop) => {
      if (data[prop] && data[prop].length > 0) {
        data[prop] = []
      }
      return file
    },
    // 格式化上传文件数据
    setUploadFormData: (url, data, prop) => {
      console.log(url)
      data[prop] = [{
        name: url ? url.substr(url.lastIndexOf('/') + 1) : '',
        url,
      }]
      return data
    },
    // 表单舒服复位
    reset() {
      if (!this.isAdd) {
        this.formColumns.forEach(item => this.setEditFormData(this.data, item))
      } else {
        if (this.isTop) {
          this.formColumns.forEach(item => {
            setAddFormData(this.data, item, this.expandAll)
            this.resetDict(item)
          })
        }
        this.resetValidator()
      }
    },
    setEditFormData(data, item) {
      if (item.children) {
        item.____visible = this.expandAll !== false
      }
      if (item.type === 'checkbox' && !data[item.prop]) {
        data[item.prop] = []
      } else if (item.type === 'upload') {
        setUpload(data, item, this.isAdd)
      }
      this.resetDict(item)
    },
    // 重置dict中可能修改column显隐
    resetDict(column) {
      if (column.onDictSelected) {
        column.onDictSelected(this.data[column.prop], this.formColumns, this.data, this.$x.biz.FormType.RESET)
      }
    },
    resetValidator() {
      if (this.$refs.formObserver) {
        this.$refs.formObserver.reset()
      }
    },
    getSelectOptions(dictData) {
      return dictData.map(item => {
        let data = {}
        data.text = item.label
        data.value = item.value
        return data
      })
    },
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$xtoast.warning(`当前限制选择 ${fileList.length} 个文件`)
      // this.$xtoast.warning(`当前限制选择 ${fileList.length} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove(file) {
      return this.$xmbox.confirm(`确定移除 ${file.name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'btn btn-outline-secondary',
        confirmButtonClass: 'btn btn-primary',
        type: 'warning',
      })
    },
  },
}
</script>

<style lang="scss">
  .xtable__form {
    &-arrowup{
      transform:rotate(0deg);
      transition: all .4s;
    }
    &-arrowdown{
       transform:rotate(-180deg);
       transition: all .4s;
    }
  }
</style>
