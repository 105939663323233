<template>
  <div class="row">
    <div class="col-md-3">
      <div class="card card-body">
        <div class="d-flex align-items-center justify-content-between">
          <span class="text-secondary">今日收入</span>
          <b-img height="30" :src="require('@/assets/jxz/income.svg')"/>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-1">
          <div class="font-large-1 x-text-bold text-body">{{today.income}}<span class="font-small-4 x-text-normal text-secondary ml-50">元</span></div>
          <div class="text-secondary">总收入<span class="font-medium-2 text-body x-text-bold ml-50 mr-50">{{total.income}}</span>元</div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div :class="todayPercentText(today.incomeDayPercent)" class="mt-50 d-flex align-items-center">
            <span class="text-secondary">日同比</span>
            <span class="ml-50 mr-50">{{ today.incomeDayPercent + '%' }}</span>
            <feather-icon :icon="todayPercentTrend(today.incomeDayPercent)"></feather-icon>
          </div>
          <div :class="todayPercentText(today.incomeMonthPercent)" class="mt-50 d-flex align-items-center">
            <span class="text-secondary">月同比</span>
            <span class="ml-50 mr-50">{{ today.incomeMonthPercent + '%' }}</span>
            <feather-icon :icon="todayPercentTrend(today.incomeMonthPercent)"></feather-icon>
          </div>
          <div class="btn btn-light-primary btn-sm pr-50" @click="$router.push('/data/finance')">账单<feather-icon icon="ChevronRightIcon" class="ml-50"></feather-icon></div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card card-body">
        <div class="d-flex align-items-center justify-content-between">
          <span class="text-secondary">今日新增会员</span>
          <b-img height="30" :src="require('@/assets/jxz/member.svg')"/>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-1">
          <div class="font-large-1 x-text-bold text-body">{{today.member}}</div>
          <div class="text-secondary">总共<span class="font-medium-2 text-body x-text-bold ml-50 mr-50">{{total.member}}</span>人</div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div :class="todayPercentText(today.memberDayPercent)" class="text-secondary mt-50 d-flex align-items-center">
            <span class="text-secondary">日同比</span>
            <span class="ml-50 mr-50">{{ today.memberDayPercent + '%' }}</span>
            <feather-icon :icon="todayPercentTrend(today.memberDayPercent)"></feather-icon>
          </div>
          <div :class="todayPercentText(today.memberMonthPercent)" class="mt-50 d-flex align-items-center">
            <span class="text-secondary">月同比</span>
            <span class="ml-50 mr-50">{{ today.memberMonthPercent + '%' }}</span>
            <feather-icon :icon="todayPercentTrend(today.memberMonthPercent)"></feather-icon>
          </div>
          <div class="btn btn-light-primary btn-sm pr-50" @click="$router.push('/data/member')">会员<feather-icon icon="ChevronRightIcon" class="ml-50"></feather-icon></div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card card-body">
        <div class="d-flex align-items-center justify-content-between">
          <span class="text-secondary">今日新增商户</span>
          <b-img height="30" :src="require('@/assets/jxz/mt.svg')"/>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-1">
          <div class="font-large-1 x-text-bold text-body">{{today.mt}}</div>
          <div class="text-secondary">总共<span class="font-medium-2 text-body x-text-bold ml-50 mr-50">{{total.mt}}</span>人</div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div :class="todayPercentText(today.mtDayPercent)" class="text-secondary mt-50 d-flex align-items-center">
            <span class="text-secondary">日同比</span>
            <span class="ml-50 mr-50">{{ today.mtDayPercent + '%' }}</span>
            <feather-icon :icon="todayPercentTrend(today.mtDayPercent)"></feather-icon>
          </div>
          <div :class="todayPercentText(today.mtMonthPercent)" class="text-secondary mt-50 d-flex align-items-center">
            <span class="text-secondary">月同比</span>
            <span class="ml-50 mr-50">{{ today.mtMonthPercent + '%' }}</span>
            <feather-icon :icon="todayPercentTrend(today.mtMonthPercent)"></feather-icon>
          </div>
          <div class="btn btn-light-primary btn-sm pr-50" @click="$router.push('/data/merchant')">商户<feather-icon icon="ChevronRightIcon" class="ml-50"></feather-icon></div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card card-body">
        <div class="d-flex align-items-center justify-content-between">
          <span class="text-secondary">今日销售软件（机器）数</span>
          <b-img height="30" :src="require('@/assets/jxz/machine.svg')"/>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-1">
          <div class="font-large-1 x-text-bold text-body">{{today.machine}}</div>
          <div class="text-secondary">总共<span class="font-medium-2 text-body x-text-bold ml-50 mr-50">{{total.machine}}</span>套</div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div :class="todayPercentText(today.machineDayPercent)" class="text-secondary mt-50 d-flex align-items-center">
            <span class="text-secondary">日同比</span>
            <span class="ml-50 mr-50">{{ today.machineDayPercent + '%' }}</span>
            <feather-icon :icon="todayPercentTrend(today.machineDayPercent)"></feather-icon>
          </div>
          <div :class="todayPercentText(today.machineMonthPercent)" class="text-secondary mt-50 d-flex align-items-center">
            <span class="text-secondary">月同比</span>
            <span class="ml-50 mr-50">{{ today.machineMonthPercent + '%' }}</span>
            <feather-icon :icon="todayPercentTrend(today.machineMonthPercent)"></feather-icon>
          </div>
          <div class="btn btn-light-primary btn-sm pr-50" @click="$router.push('/data/machine')">机器<feather-icon icon="ChevronRightIcon" class="ml-50"></feather-icon></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    dashboard: {
      type: Object,
      default: undefined,
    },
  },
  watch: {
    dashboard: {
      handler() {
        this.today = (this.dashboard && this.dashboard.today) || this.today
        this.total = (this.dashboard && this.dashboard.total) || this.total
      },
    },
  },
  data() {
    return {
      today: {
        mt: 0,
        mtDayPercent: 0,
        mtMonthPercent: 0,
        member: 0,
        memberDayPercent: 0,
        memberMonthPercent: 0,
        income: 0,
        incomeDayPercent: 0,
        incomeMonthPercent: 0,
        machine: 0,
        machineDayPercent: 0,
        machineMonthPercent: 0,
      },
      total: {
        mt: 0,
        member: 0,
        income: 0,
        machine: 0,
      },
    }
  },
  methods: {
    todayPercentTrend(percent) {
      if (!percent || percent === 0) {
        return 'MinusIcon'
      }
      if (percent > 0) {
        return 'TrendingUpIcon'
      }
      return 'TrendingDownIcon'
    },
    todayPercentText(percent) {
      if (!percent || percent === 0) {
        return 'text-secondary'
      }
      if (percent > 0) {
        return 'text-success'
      }
      return 'text-danger'
    },
  },
}
</script>
<style lang="scss" scoped>
  .jxz-dashboard {
  }
</style>
