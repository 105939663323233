<template>
  <e-charts
    ref="line"
    autoresize
    :options="option"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/pie'
import theme from './theme.json'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  watch: {
    optionData: {
      handler() {
        this.option = {
          tooltip: this.optionData.tooltip || {
            trigger: 'item',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: this.optionData.legend || {
            left: 0,
          },
          grid: this.optionData.grid,
          xAxis: this.optionData.xAxis,
          yAxis: this.optionData.yAxis,
          series: this.optionData.series,
        }
      },
    },
  },
  data() {
    return {
      option: {
        tooltip: this.optionData.tooltip || {
          trigger: 'item',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: this.optionData.legend || {
          left: 0,
        },
        grid: this.optionData.grid,
        xAxis: this.optionData.xAxis,
        yAxis: this.optionData.yAxis,
        series: this.optionData.series,
      },
    }
  },
}
</script>
