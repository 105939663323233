<template>
  <div >
    <b-row v-if="!hideSearchBar" >
      <b-col md="6" xl="4" sm="12" v-for="(column,index) in (fold ? columns : foldColumns)" :key="index" class="x-anim-fade-in">
        <b-form-group :label="column.labelShow ? column.label + '：' : ''" :label-cols-md="lableVertical ? 0 : 2">
          <slot :name="`search-${column.prop}`" :data="searchParams">
            <v-select v-if="column.type == 'select'"
                      id="productName"
                      v-model="searchParams[column.prop]"
                      :options="column.dictData"
                      label="label"
                      v-on:option:selected="column.onDictSelected(searchParams[column.prop], columns, null, $x.biz.FormType.SEARCH)"
                      :reduce="option => option.value"
                      :getOptionLabel="column.getDictLabel"
                      :placeholder="column.placeholder">
              <template slot="no-options">{{$t('noData')}}</template>
            </v-select>
            <div v-else-if="column.type === 'tree'">
              <el-cascader  v-model="searchParams[column.prop]"
                            class="x-w-100"
                            :collapse-tags="column.props.collapseTags"
                            :options="column.dictData" :props="column.props" clearable>
              </el-cascader>
            </div>
            <b-form-checkbox v-else-if="column.type === 'switch'" :id="column.prop"
                             v-model="searchParams[column.prop]" switch inline
                             @change="column.onSwitchChange(data[column.prop], columns)">
            </b-form-checkbox>
            <div v-else-if="column.type == 'radio'" class="d-flex flex-row align-items-end mt-1">
              <b-form-radio-group class="ml-1 mr-1"
                v-model="searchParams[column.prop]"
                :options="getSelectOptions(column.dictData)">
              </b-form-radio-group>
            </div>
            <div v-else-if="column.type == 'checkbox'"  class="d-flex flex-row align-items-end mt-1">
              <b-form-checkbox-group class="ml-1 mr-1"
                                     :id="column.prop"
                                     v-model="searchParams[column.prop]"
                                     :options="getSelectOptions(column.dictData)">
              </b-form-checkbox-group>
            </div>
            <el-date-picker
              v-else-if="column.type === 'datetime' || column.type === 'datetimerange'"
              v-model="searchParams[column.prop]"
              :type="column.type"
              :placeholder="column.placeholder"
              :picker-options="column.pickerOptions ? column.pickerOptions : pickerOptions"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              class="w-100"
              align="right"
            >
            </el-date-picker>
            <div v-else-if="column.__type !== 2">
              <cleave
                v-if="column.mask"
                :id="column.prop"
                v-model="searchParams[column.prop]"
                :raw="false"
                :options="column.mask"
                :placeholder="column.placeholder"/>
              <b-form-input
                v-else
                :id="column.prop"
                v-model="searchParams[column.prop]"
                :placeholder="column.placeholder"/>
            </div>
            <!--:selectable="option => ! option.value.includes('select_value')"-->
          </slot>
        </b-form-group>
      </b-col>
      <b-col  v-if="columns.length > 0" md="6" xl="4" sm="12" class="d-flex flex-row justify-content-end align-items-end">
        <b-form-group>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"  variant="primary" class="mr-1" @click="searchHandle">查询</b-button>
          <b-button v-if="options.resetBtn" v-ripple.400="'rgba(30, 30, 30, 0.15)'"  variant="outline-primary" class="mr-1" @click="resetHandle">重置</b-button>
          <b-button v-if="!hideStyleSwitch" v-ripple.400="$x.ripple.primary" variant="flat-primary" class="btn-icon rounded-circle mr-10" @click="switchTableType">
            <!--<span class="align-middle">Done</span>-->
            <feather-icon :icon="isList ? 'GridIcon' : 'ListIcon'" size="20"/>
          </b-button>
          <b-button v-if="columns.length > 2" v-ripple.400="$x.ripple.primary" variant="flat-primary" class="btn-icon rounded-circle" @click="fold = !fold">
            <!--<span class="align-middle">Done</span>-->
            <feather-icon :icon="fold ? 'ChevronDownIcon' : 'ChevronUpIcon'" size="20"/>
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-permission="permissions.add" v-if="showAddOnly && options.addBtn" >
      <b-col cols="12">
        <div v-if="options.normalAddBtn" class="d-flex flex-row ">
          <b-button v-permission="permissions.add"  v-if="options.addBtn"
                    v-ripple.400="$x.ripple.white"
                    variant="primary" @click="addHandle"><feather-icon size="16" icon="PlusIcon" class="mr-50" />新增</b-button>
          <slot name="actions"></slot>
        </div>
        <b-button
          v-else
          @click="addHandle"
          v-ripple.400="$x.ripple.primary"  block variant="outline-primary"  class="cx-dash-border mb-1 mt-1">
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
            size="16"
          />新增 </b-button>
      </b-col>
    </b-row>
    <b-row v-if="!showAddOnly">
      <b-col cols="12">
        <div class="d-flex flex-row ">
          <b-button v-permission="permissions.add"  v-if="options.addBtn"
                    v-ripple.400="$x.ripple.white"
                    variant="primary" class="mr-1" @click="addHandle"><feather-icon size="16" icon="PlusIcon" class="mr-50" />新增</b-button>
          <b-button v-permission="permissions.print"  v-if="options.printBtn"
                    v-ripple.400="$x.ripple.white"
                    variant="primary" class="mr-1" @click="printHandle"><feather-icon size="16" icon="PrinterIcon" class="mr-50"/>打印</b-button>
          <b-button v-permission="permissions.import"  v-if="options.importBtn"
                    v-ripple.400="$x.ripple.white"
                    variant="primary" class="mr-1" @click="importHandle"><feather-icon size="16" icon="UploadIcon" class="mr-50"/>导入</b-button>
          <b-button v-permission="permissions.export"  v-if="options.exportBtn"
                    v-ripple.400="$x.ripple.white"
                    variant="primary" class="mr-1" @click="exportHandle"><feather-icon size="16" icon="DownloadIcon" class="mr-50"/>导出</b-button>
          <slot name="actions"></slot>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BButton, BFormRadioGroup, BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  props: {
    /**
     *  表格类型
     */
    tableType: {
      type: String,
      default: 'list',
    },
    /**
     *  表格配置
     */
    options: {
      type: Object,
      default: () => {},
    },
    /**
     *  权限列表
     */
    permissions: {
      type: Object,
      default: () => {},
    },
    /**
     * 是否隐藏搜索栏
     */
    hideSearchBar: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否隐藏样式切换
     */
    hideStyleSwitch: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    Cleave,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          },
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          },
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          },
        }],
      },
      // 搜索实体类
      searchParams: {},
      // 搜索条件超过2个以上 是否折叠显示
      searchFold: true,
      // 是否显示搜索： 1一个以及一个以上显示搜素
      // search: true,
      // label 是否垂直显示
      lableVertical: true,
      // 搜索项
      columns: [],
      // 折叠后的项
      foldColumns: [],
      // 当前是否折叠状态
      fold: false,
      // 是否只显示新增
      showAddOnly: false,
      // 表格样式是否为列表
      isList: true,
    }
  },
  created() {
    if (!this.options) {
      return
    }
    this.isList = this.tableType === 'list'
    this.showAddOnly = this.options.addBtn && !this.options.exportBtn && !this.options.printBtn
    this.searchFold = this.options.searchFold !== false
    this.lableVertical = this.options.lableVertical !== false
    if (this.options.columns) {
      this.options.columns.forEach(col => {
        if (col.searchValue) {
          this.searchParams[col.prop] = col.searchValue
        }
        if (col.searchShow) {
          this.columns.push(col)
        }
      })
      if (this.columns.length !== 0) {
        let lack = 2 - this.columns.length % 3
        for (let i = 0; i < lack; i++) {
          this.columns.push({
            __type: 2, // 1搜索项 2 填充项 3 操作
          })
        }
        // 折叠项
        let foldLack = this.columns.length >= 2 ? 2 : this.columns.length
        for (let i = 0; i < foldLack; i++) {
          this.foldColumns.push(this.columns[i])
        }
        // 折叠填充
        foldLack = 2 - this.columns.length
        for (let i = 0; i < foldLack; i++) {
          this.foldColumns.push({
            __type: 2, // 1搜索项 2 填充项 3 操作
          })
        }
      }
    }
  },
  methods: {
    switchTableType() {
      this.$emit('switchTableType')
      this.isList = !this.isList
    },
    getSearchParams() {
      return this.searchParams
    },
    searchHandle() {
      this.$emit('search', this.searchParams)
    },
    resetHandle() {
      this.searchParams = {}
      this.$emit('reset')
    },
    addHandle() {
      this.$emit('add')
    },
    printHandle() {
      this.$emit('print')
    },
    importHandle() {
      this.$emit('import')
    },
    exportHandle() {
      this.$emit('export')
    },
    getSelectOptions(dictData) {
      return dictData.map(item => {
        let data = {}
        data.text = item.label
        data.value = item.value
        return data
      })
    },
  },
}
</script>

<style lang="scss">
  .vs__selected-options{
    input {
    }
  }
</style>
