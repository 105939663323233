import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-jxz/withdrawal/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const pass = (id, opinion) => request.axiosIns({
  url: `/apollo-jxz/withdrawal/pass/${id}`,
  method: 'PUT',
  params: {
    opinion,
  },
})

export const reject = (id, opinion) => request.axiosIns({
  url: `/apollo-jxz/withdrawal/reject/${id}`,
  method: 'PUT',
  params: {
    opinion,
  },
})

export const withdrawalById = (id, opinion) => request.axiosIns({
  url: '/apollo-jxz/pay/payBank',
  method: 'POST',
  params: {
    opinion,
    partnerTradeNo: id,
  },
})
