<template>
  <div class="card">
    <x-el-table
      no-page
      lazy
      :options="options"
      @search="searchHandle"
      @rowConfig="rowConfig"
      @lazyLoad="lazyLoad">
    </x-el-table>
    <b-sidebar
      :visible="showConfigView"
      bg-variant="white"
      right
      backdrop
      shadow
      width="50%"
      @hidden="hideConfigView">
      <div class="m-2">
        <div class="d-flex align-items-center">
          <span class="font-medium-4 text-muted x-text-bold">接口权限配置</span>
          <span class="font-medium-4 text-muted x-text-bold x-ml-5px x-mr-5px">●</span>
          <span class="svg-icon svg-icon-muted">
            <inline-svg src="media/icons/duotune/arrows/arr023.svg" />
          </span>
          <span class="font-medium-4 text-body x-text-bold">{{selectedRow.name}}</span>
        </div>
        <el-divider></el-divider>
        <x-el-table
          v-if="showConfigView"
          :options="permsOptions"
          @search="searchPermsHandle"
          @rowAdd="addPermsHandle"
          @rowEdit="rowPermsEdit"
          @rowDelete="rowPermsDelete"
          @beforeShow="beforeShow">
        </x-el-table>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
} from 'bootstrap-vue'
import XElTable from '@core/components/cx/table/XElTable.vue'
import {
  getLazyMenuList,
} from '@/api/system/menu'
import {
  getListApiScope, addApiScope, updateApiScope, removeApiScope,
} from '@/api/system/scope'

export default {
  name: 'example',
  components: {
    XElTable,
    BSidebar,
  },
  data() {
    return {
      showConfigView: false,
      selectedRow: {},
      options: {
        addBtn: false,
        hideStyleSwitch: true,
        actions: [
          { action: 'config', tip: '权限配置', icon: 'SettingsIcon', permission: 'api_scope_setting' },
        ],
        columns: [
          {
            label: '菜单名称',
            prop: 'name',
          },
          {
            label: '路由地址',
            prop: 'path',
            searchShow: false,
          },
          {
            label: '菜单编号',
            prop: 'code',
          },
          {
            label: '排序值',
            prop: 'sort',
            searchShow: false,
          },
        ],
      },
      permsOptions: {
        hideStyleSwitch: true,
        actions: [
          { action: this.$x.biz.FormType.VIEW, name: '查看', icon: 'EyeIcon', svg: 'media/icons/duotune/general/gen016.svg' },
          { action: this.$x.biz.FormType.EDIT, name: '编辑', icon: 'EditIcon', svg: 'media/icons/duotune/general/gen055.svg' },
          { action: this.$x.biz.FormType.DELETE, name: '删除', icon: 'Trash2Icon', svg: 'media/icons/duotune/abstract/abs012.svg' },
        ],
        columns: [
          {
            label: '权限名称',
            prop: 'scopeName',
            rules: {
              rule: 'required',
              message: '请输入权限名称',
            },
          },
          {
            label: '权限编号',
            prop: 'resourceCode',
            rules: {
              rule: 'required',
              message: '请输入权限编号',
            },
          },
          {
            label: '权限路径',
            prop: 'scopePath',
            rules: {
              rule: 'required',
              message: '请输入权限编号',
            },
          },
          {
            label: '接口类型',
            prop: 'scopeType',
            rowSelect: true,
            type: 'select',
            dictUrl: '/apollo-upms/dict/dictionary?code=api_scope_type',
            rules: {
              rule: 'required',
              message: '请选择接口类型',
            },
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
          },
          {
            label: '备注',
            prop: 'remark',
            type: 'textarea',
            rowShow: false,
            searchShow: false,
          },
        ],
      },
    }
  },
  methods: {
    searchHandle(page, params, done) {
      getLazyMenuList('0', Object.assign(params, this.query)).then(resp => {
        done(resp.data.data, resp.data.data.length)
      })
    },
    rowConfig(row) {
      this.selectedRow = row
      this.showConfigView = true
    },
    lazyLoad(row, treeNode, resolve) {
      const parentId = row.id
      getLazyMenuList(parentId).then(res => {
        resolve(res.data.data)
      })
    },
    hideConfigView() {
      this.showConfigView = false
      this.selectedRow = {}
    },
    searchPermsHandle(page, params, done) {
      getListApiScope(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    beforeShow(type, formData) {
      if (type.isAdd) {
        formData.scopeColumn = '-'
        formData.scopeField = '*'
      } else {
        formData.scopeType = formData.scopeType ? formData.scopeType.toString() : formData.scopeType
      }
    },
    addPermsHandle(data, done) {
      addApiScope(data).then(() => {
        done()
      })
    },
    rowPermsEdit(data, done) {
      updateApiScope(data).then(() => {
        done()
      })
    },
    rowPermsDelete(data, done) {
      removeApiScope(data.id).then(() => {
        done()
      })
    },
  },
}
</script>

<style scoped>

</style>
