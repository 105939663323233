import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-jxz/gm/member/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const profile = id => request.axiosIns({
  url: `/apollo-jxz/gm/member/${id}`,
  method: 'get',
})

export const remove = ids => request.axiosIns({
  url: '/apollo-resource/oss/remove',
  method: 'post',
  params: {
    ids,
  },
})

export const add = row => request.axiosIns({
  url: '/apollo-resource/oss/submit',
  method: 'post',
  data: row,
})

export const edit = row => request.axiosIns({
  url: '/apollo-resource/oss/submit',
  method: 'post',
  data: row,
})

export const enable = id => request.axiosIns({
  url: '/apollo-resource/oss/enable',
  method: 'post',
  params: {
    id,
  },
})

export const getMemberRealInfo = id => request.axiosIns({
  url: `/apollo-jxz/gm/member/real-name/${id}`,
  method: 'get',
})

export const setCoins = data => request.axiosIns({
  url: '/apollo-jxz/gm/member/coins',
  method: 'put',
  data,
})
