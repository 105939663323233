<template>
  <div>
    <div class="card card-body">
      <div class="d-flex align-items-center">
        <b-img height="30" :src="require('@/assets/jxz/package-chart.svg')"/>
        <span class="text-secondary ml-50">软件（机器）销售占比分析</span>
      </div>
      <div class="d-flex align-items-center justify-content-center row">
        <div class="col-md-5 text-center text-secondary font-italic">今日销售（套）</div>
        <div class="col-md-5 text-center text-secondary font-italic">总共销售（套）</div>
        <echart-pie class="chart col-md-5" :optionData="todayOptionData"></echart-pie>
        <echart-pie class="chart col-md-5" :optionData="totalOptionData"></echart-pie>
      </div>
    </div>
  </div>
</template>

<script>

import { BImg } from 'bootstrap-vue'
import EchartPie from '@/@core/components/charts/echart/AppEchartPie.vue'

export default {
  components: {
    BImg,
    EchartPie,
  },
  props: {
    dashboard: {
      type: Object,
      default: undefined,
    },
  },
  watch: {
    dashboard: {
      handler() {
        if (this.dashboard && this.dashboard.today) {
          this.todayOptionData = this.getOptionData(this.dashboard.today.personalMachine,
            this.dashboard.today.machine - this.dashboard.today.personalMachine)
        }
        if (this.dashboard && this.dashboard.total) {
          this.totalOptionData = this.getOptionData(this.dashboard.total.personalMachine,
            this.dashboard.total.machine - this.dashboard.total.personalMachine)
        }
      },
    },
  },
  data() {
    return {
      todayOptionData: this.getOptionData(0, 0),
      totalOptionData: this.getOptionData(0, 0),
    }
  },
  methods: {
    getOptionData(person, commercial) {
      return {
        legend: {
          orient: 'vertical',
          left: 0,
          top: '15%',
          itemWidth: 14,
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 2,
          },
        },
        series: [
          {
            name: '套餐占比',
            type: 'pie',
            right: 0,
            center: ['70%', '50%'],
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderColor: '#ffffff',
              borderWidth: 4,
              shadowBlur: 10,
              shadowColor: 'rgba(115,103,240, .4)',
              shadowOffsetY: 10,
              shadowOffsetX: 10,
            },
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 15,
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: person, name: '个人版' },
              { value: commercial, name: '商业版' },
            ],
          },
        ],
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  .chart {
    height: 200px;
  }
</style>
