<template>
  <b-overlay
    :show="showLoading"
    rounded="sm"
    variant="light"
    opacity="0.85"
    blur="2px"
    class="user-profile"
    spinnerVariant="primary">
    <div v-if="!showLoading">
      <div class="card card-body">
        <div class="d-flex align-items-center">
          <feather-icon class="text-primary x-link mr-1" icon="ChevronLeftIcon" size="30" @click.stop="back"></feather-icon>
          <div class="d-flex flex-column ml-1">
            <span class="x-text-bold font-medium-4">{{shopProfile.name}}</span>
            <div class="mt-50">
              <span class="badge badge-light-warning d-inline">{{shopProfile.phone ? shopProfile.phone : '暂未设置手机号'}}</span>
              <span class="badge badge-light-success d-inline ml-2">{{shopProfile.distributionState ? '分账' : '不分账'}}</span>
              <span class="badge badge-light-info d-inline ml-2" v-if="shopProfile.distributionState && shopProfile.disPercent">{{shopProfile.disPercent}}%</span>
            </div>
            <div class="mt-50">
              <feather-icon class="text-secondary mr-1" icon="UserIcon" size="15"></feather-icon>
              <span class="text-secondary mr-2">店铺管理员:</span>
              <div class="d-inline">
                <div class="d-inline" v-if="shopProfile.adminList && shopProfile.adminList.length > 0">
                  <span class="badge badge-light-primary d-inline" v-for="(aaa,index) in shopProfile.adminList"  :key="index">{{aaa.name}}</span>
                </div>
                <div class="d-inline" v-else>
                  <span class="badge badge-light-primary d-inline">暂未设置管理员</span>
                </div>
              </div>
            </div>
            <div class="mt-50">
              <feather-icon class="text-secondary mr-1" icon="FeatherIcon" size="15"></feather-icon>
              <span class="text-secondary">{{shopProfile.address}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-body">
        <b-tabs
          vertical
          active-nav-item-class="bg-light-primary nav-vertical-w justify-content-start x-anim-fade-in"
          nav-wrapper-class="nav-vertical">
          <b-tab lazy>
            <template #title>
              <span class="ml-1 mr-1">机器管理</span>
            </template>
            <div class="row w-100">
              <machine :query-shop-id="shopProfile.id" class="col-12"></machine>
            </div>
          </b-tab>
          <b-tab lazy>
            <template #title>
              <span class="ml-1 mr-1">分区管理</span>
            </template>
            <div class="row w-100">
              <shop-area :query-shop-id="shopProfile.id" class="col-12"></shop-area>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BTabs, BTab,
} from 'bootstrap-vue'
import {
  profile,
} from '@/api/data/shop'
import Machine from '../../machine/index.vue'
import ShopArea from './ShopArea.vue'

export default {
  components: {
    BOverlay,
    Machine,
    ShopArea,
    BTabs,
    BTab,
  },
  data() {
    return {
      showShop: false,
      showLoading: true,
      shopProfile: undefined,
    }
  },
  created() {
    if (!this.$route.query.id) {
      this.$xtoast.error('店铺不存在！')
      this.$router.go(-1)
      return
    }
    this.showLoading = true
    profile(this.$route.query.id).then(resp => {
      this.shopProfile = resp.data.data
      this.showLoading = false
    })
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    sideHidden() {
      this.showShop = false
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/@core/scss/base/bootstrap-extended/_variables';
  .info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed $border-color !important;
  }
  .info-cover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &-image {
      height: 200px;
    }
  }
</style>
