<template>
  <div class="d-flex flex-column  xtable__guide ">
    <div class="d-flex flex-row align-items-center mb-1">
      <feather-icon class="text-primary mr-1" icon="SendIcon" size="20"/>
      <span class="font-medium-4 text-primary x-text-bold">{{title || '提示'}}</span>
    </div>
    <div class="ml-2 bg-light-primary p-1 rounded">
      <div class="d-flex flex-column" v-for="(item,index) in content" :key="index">
        <div v-b-toggle="`item-${index}`">
          <slot name="guide-title" :data="item">
            <div class="d-flex flex-row align-items-center">
              <!--<feather-icon class="text-primary mr-1" icon="ArrowRightIcon" size="20"/>-->
              <i v-if="item.content" class="el-icon-caret-right text-primary font-medium-2"></i>
              <span class="font-medium-2 x-text-bold ">{{item.title}}</span>
            </div>
          </slot>
        </div>
        <b-collapse v-if="item.content" :id="`item-${index}`" class="ml-1 mb-1">
          <slot name="guide-content" :data="item">
            <span class="text-body ">{{item.content}}</span>
          </slot>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCollapse, VBToggle,
} from 'bootstrap-vue'

export default {
  props: {
    /**
     *  引导内容
     */
    content: {
      type: Array,
      default: () => [],
    },
    /**
     *  标题
     */
    title: {
      type: String,
      default: '提示',
    },
    /**
     *  子标题
     */
    subTitle: {
      type: String,
      default: '',
    },
    /**
     *  表格配置
     */
    options: {
      type: Object,
      default: () => {},
    },
    /**
     *  权限列表
     */
    permissions: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      bg: 'primary',
    }
  },
  methods: {
    expandContent() {

    },
  },
}
</script>

<style lang="scss">
  .xtable__guide{
    &__icon{
      width: 4% !important;
    }
  }
</style>
