import request from '@/auth/jwt/useJwt'

export const getListDataScope = (current, size, params) => request.axiosIns({
  url: '/apollo-upms/data-scope/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const removeDataScope = ids => request.axiosIns({
  url: '/apollo-upms/data-scope/remove',
  method: 'post',
  params: {
    ids,
  },
})

export const addDataScope = row => request.axiosIns({
  url: '/apollo-upms/data-scope/submit',
  method: 'post',
  data: row,
})

export const updateDataScope = row => request.axiosIns({
  url: '/apollo-upms/data-scope/submit',
  method: 'post',
  data: row,
})

export const getMenuDataScope = id => request.axiosIns({
  url: '/apollo-upms/data-scope/detail',
  method: 'get',
  params: {
    id,
  },
})

export const getListApiScope = (current, size, params) => request.axiosIns({
  url: '/apollo-upms/api-scope/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const removeApiScope = ids => request.axiosIns({
  url: '/apollo-upms/api-scope/remove',
  method: 'post',
  params: {
    ids,
  },
})

export const addApiScope = row => request.axiosIns({
  url: '/apollo-upms/api-scope/submit',
  method: 'post',
  data: row,
})

export const updateApiScope = row => request.axiosIns({
  url: '/apollo-upms/api-scope/submit',
  method: 'post',
  data: row,
})

export const getMenuApiScope = id => request.axiosIns({
  url: '/apollo-upms/api-scope/detail',
  method: 'get',
  params: {
    id,
  },
})
