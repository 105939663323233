import request from '@/auth/jwt/useJwt'

export function getList(current, size, params) {
  return request.axiosIns({
    url: '/apollo-upms/dept/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}

export function getLazyList(parentId, params) {
  return request.axiosIns({
    url: '/apollo-upms/dept/lazy-list',
    method: 'get',
    params: {
      ...params,
      parentId,
    },
  })
}

export function remove(ids) {
  return request.axiosIns({
    url: '/apollo-upms/dept/remove',
    method: 'post',
    params: {
      ids,
    },
  })
}

export function add(row) {
  return request.axiosIns({
    url: '/apollo-upms/dept/submit',
    method: 'post',
    data: row,
  })
}

export function update(row) {
  return request.axiosIns({
    url: '/apollo-upms/dept/submit',
    method: 'post',
    data: row,
  })
}

export function getDept(id) {
  return request.axiosIns({
    url: '/apollo-upms/dept/detail',
    method: 'get',
    params: {
      id,
    },
  })
}

export function getDeptTree(tenantId) {
  return request.axiosIns({
    url: '/apollo-upms/dept/tree',
    method: 'get',
    params: {
      tenantId,
    },
  })
}

export function getDeptLazyTree(parentId) {
  return request.axiosIns({
    url: '/apollo-upms/dept/lazy-tree',
    method: 'get',
    params: {
      parentId,
    },
  })
}

// 获取机构类型
export function getCategorys() {
  return request.axiosIns({
    url: '/apollo-upms/dict/dictionary?code=org_category',
    method: 'get',
  })
}

// 获取租户列表
export function getTenants() {
  return request.axiosIns({
    url: '/apollo-upms/tenant/select',
    method: 'get',
  })
}
