import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-upms/tenant-package/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const getAll = () => request.axiosIns({
  url: '/apollo-upms/tenant-package/select',
  method: 'get',
})

export const getDetail = id => request.axiosIns({
  url: '/apollo-upms/tenant-package/detail',
  method: 'get',
  params: {
    id,
  },
})

export const remove = ids => request.axiosIns({
  url: '/apollo-upms/tenant-package/remove',
  method: 'post',
  params: {
    ids,
  },
})

export const add = row => request.axiosIns({
  url: '/apollo-upms/tenant-package/submit',
  method: 'post',
  data: row,
})

export const update = row => request.axiosIns({
  url: '/apollo-upms/tenant-package/submit',
  method: 'post',
  data: row,
})
