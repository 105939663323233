<template>
  <div>
    <x-el-table
      ref="table"
      :options="options"
      @search="searchHandle"
      @rowDetail="rowDetail">
      <template slot="mtId" slot-scope="scope">
        <b-avatar v-viewer :src="scope.row.mtUser.avatar" variant="light-primary"></b-avatar>
        <span class="ml-1 text-primary x-link x-text-normal" @click="$router.push(`/data/merchant/profile?id=${scope.row.mtUser.id}`)">{{ scope.row.mtUser.nickName }}</span>
      </template>
      <template slot="amount" slot-scope="scope">
        <div class="x-text-bold text-success font-medium-4">
          <span class="text-secondary font-small-4 x-text-normal"> ￥ </span>{{ scope.row.amount }}
        </div>
      </template>
    </x-el-table>
    <b-modal
      hide-footer
      cancel-variant="outline-secondary"
      centered
      title="提现详情"
      :visible.sync="detailFlag"
      @hidden="sideHidden">
       <div class="pl-2 pr-2 pt-1 pb-2" v-if="actionRow">
       <b-row>
         <b-col md="12">
           <div v-if="actionRow.state === 1" class="alert alert-primary">
             <div class="alert-body text-center">处理中</div>
           </div>
           <div v-if="actionRow.state === 2" class="alert alert-success">
             <div class="alert-body text-center">提现成功</div>
           </div>
           <div v-if="actionRow.state === 3" >
             <div class="alert alert-danger">
               <div class="alert-body text-center">提现失败</div>
             </div>
             <div class="text-danger mb-1"><span class="x-text-bold">失败原因：</span>{{ actionRow.opinion }}</div>
           </div>
         </b-col>
         <b-col md="5">
           <div class="d-flex align-items-center">
             <b-avatar v-viewer :src="actionRow.mtUser.avatar" variant="light-primary" size="20" rounded></b-avatar>
              <span class="x-text-bold ml-1 font-medium-1">{{ actionRow.mtUser.nickName }}</span>
          </div>
         </b-col>
         <b-col md="7">
           <div class="d-flex align-items-center">
             <feather-icon icon="AwardIcon" class="text-primary" size="18"></feather-icon>
             <span class=" text-secondary ml-50">流水号：</span>
             <span class="x-text-bold ml-1 font-medium-1">{{ actionRow.id }}</span>
           </div>
          </b-col>
       </b-row>
       <b-row class="mt-2">
          <b-col md="5">
          <div class="d-flex align-items-center">
            <feather-icon icon="DollarSignIcon" class="text-primary" size="18"></feather-icon>
            <span class=" text-secondary ml-50">提现金额：</span>
            <span class="x-text-bold ml-2 font-medium-1">￥<span class="font-medium-2 text-success"> {{ actionRow.amount }} </span></span><span class="text-secondary">/元</span>
          </div>
          </b-col>
          <b-col md="7">
            <div class="d-flex align-items-center">
              <feather-icon icon="ClockIcon" class="text-primary" size="18"></feather-icon>
              <span class=" text-secondary ml-50">申请时间：</span>
              <span class="x-text-bold ml-2 font-medium-1">{{ actionRow.createTime }}</span>
            </div>
           </b-col>
         <b-col md="12">
           <div class="card x-border-dash x-border-primary mt-2">
             <span class="card-header x-text-bold">卡信息:</span>
             <div v-if="actionRowCard" class="card-body">
               <div class="ml-2"><span class="text-secondary">姓名：</span><span class="x-text-bold ml-50">{{ actionRowCard.holder }}</span></div>
               <div class="ml-2 mt-50"><span class="text-secondary">身份证：</span><span class="x-text-bold ml-50">{{ actionRowCard.idNo }}</span></div>
               <div class="ml-2 mt-50"><span class="text-secondary">卡号：</span><span class="x-text-bold ml-50">{{ actionRowCard.cardNo }}</span></div>
               <div class="ml-2 mt-50"><span class="text-secondary">手机号：</span><span class="x-text-bold ml-50">{{ actionRowCard.phone }}</span></div>
             </div>
           </div>
         </b-col>
        </b-row>
              <div class="d-flex justify-content-center align-items-center mt-3" v-if="actionRow.state === 1 && actionFlag === 'approve'">
                 <b-button  variant="success" @click="pass">
                   <feather-icon icon="CheckIcon" ></feather-icon>
                    通 过
                 </b-button>
                  <b-button  variant="danger" class="ml-3" @click="reject">
                    <feather-icon icon="XIcon" ></feather-icon>
                     驳 回
                  </b-button>
              </div>
            </div>
    </b-modal>
  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import { BModal, BAvatar, BButton, BRow, BCol } from 'bootstrap-vue'
import {
  getList, withdrawalById, reject,
} from '@/api/data/withdrawal'

export default {
  name: 'Machine',
  components: {
    XElTable,
    BModal,
    BAvatar,
    BButton,
    BRow,
    BCol,
  },
  data() {
    return {
      actionFlag: undefined,
      actionRow: undefined,
      actionRowCard: undefined,
      opinion: undefined,
      detailFlag: false,
      options: {
        addBtn: false,
        actions: [
          { action: 'detail', tip: '详情', icon: 'CodeIcon' },
        ],
        actionFilter: action => {
          return action === 'detail'
        },
        columns: [
          {
            label: '流水号',
            prop: 'id',
            searchShow: false,
          },
          {
            label: '所属商户',
            prop: 'mtId',
            type: 'select',
            rowSelect: true,
            searchShow: true,
            dictUrl: '/apollo-jxz/gm/merchant/all',
            props: {
              label: 'nickName',
              value: 'id',
            },
          },
          {
            label: '金额',
            prop: 'amount',
            searchShow: false,
          },
          {
            label: '申请时间',
            prop: 'createTime',
            searchShow: false,
          },
          {
            label: '提现状态',
            prop: 'state',
            searchShow: true,
            type: 'select',
            dictUrl: '/apollo-upms/dict-biz/dictionary?code=withdrawalApprove',
            rowSelectDot: true,
            selectVariant: value => {
              if (value === 1) {
                return 'text-warning'
              }
              if (value === 2) {
                return 'text-success'
              }
              if (value === 3) {
                return 'text-danger'
              }
              return 'text-warning'
            },
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
          },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    rowApprove(data) {
      this.actionFlag = 'approve'
      this.actionRow = data
      this.detailFlag = true
      this.opinion = data.opinion
    },
    rowDetail(data) {
      this.actionFlag = 'view'
      this.opinion = data.opinion
      this.actionRow = data
      this.actionRowCard = JSON.parse(data.card)
      this.detailFlag = true
    },
    sideHidden() {
      this.detailFlag = false
      this.actionFlag = undefined
      this.actionRow = undefined
      this.actionRowCard = undefined
      this.opinion = undefined
    },
    pass() {
      withdrawalById(this.actionRow.id, this.opinion).then(resp => {
        if (resp.resultCode && resp.resultCode === 'SUCCESS') {
          this.$xtoast.success('提现成功')
          this.$refs.table.__searchHandle()
          this.sideHidden()
        }
      })
    },
    reject() {
      if (!this.opinion) {
        this.$xtoast.error('请输入审核意见')
        return
      }
      reject(this.actionRow.id, this.opinion).then(() => {
        this.$xtoast.success('驳回成功')
        this.$refs.table.__searchHandle()
        this.sideHidden()
      })
    },
  },
}
</script>

<style lang="scss">
</style>
