<template>
  <div>
    <x-el-table
      ref="table"
      :permissions="{ add: 'dict_add' }"
      :options="options"
      @beforeShow="beforeShow"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowConfig="rowConfig"
      @rowDelete="rowDelete">
      <template slot="dictValue" slot-scope="scope">
        <div class="text-primary x-text-normal x-link d-inline" @click="() => $refs.table.actionClick(options.actions[0],scope)">{{ scope.row.dictValue }}</div>
      </template>
      <template slot="code" slot-scope="scope">
        <b-badge variant="light-primary">{{scope.row.code}}</b-badge>
      </template>
      <!--<template slot="dictValue" slot-scope="scope">-->
        <!--<b-badge variant="primary">{{scope.row.dictValue}}</b-badge>-->
      <!--</template>-->
    </x-el-table>
    <b-sidebar
      id="sidebar-right"
      ref="slider"
      idebar-class="sidebar-xl"
      bg-variant="white"
      title="字典配置"
      right
      backdrop
      shadow
      width="60%"
      :visible="childFlag"
      @hidden="sideHidden"
    >
      <dict-child v-if="dict" :dict="dict"/>
    </b-sidebar>
  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import {
  getParentList, add, update, remove,
} from '@/api/system/dict'
import { BBadge, BSidebar } from 'bootstrap-vue'
import dictChild from './dictChild.vue'

export default {
  name: 'Dict',
  components: {
    XElTable,
    BBadge,
    BSidebar,
    dictChild,
  },
  data() {
    return {
      // 新增下级modal
      childFlag: false,
      dict: {},
      options: {
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon', permission: 'dict_view' },
          { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon', permission: 'dict_edit' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon', permission: 'dict_delete' },
          { action: 'config', tip: '字典配置', icon: 'AlignCenterIcon', permission: 'dict_config' },
        ],
        columns: [
          {
            label: '字典编号',
            prop: 'code',
            rules: {
              rule: 'required',
              message: '请输入字典编号',
            },
          },
          {
            label: '字典名称',
            prop: 'dictValue',
            searchShow: true,
            rules: {
              rule: 'required',
              message: '请输入字典名称',
            },
          },
          {
            label: '排序',
            type: 'number',
            prop: 'sort',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入排序',
            },
          },
          {
            label: '是否禁用',
            prop: 'isSealed',
            type: 'select',
            rowSelectDot: true,
            selectVariant: value => value === 1 ? 'text-danger' : 'text-success',
            dictData: [
              {
                label: '否',
                value: '0',
              },
              {
                label: '是',
                value: '1',
              },
            ],
            rules: {
              rule: 'required',
              message: '请选择是否封存',
            },
          },
          {
            label: '备注',
            type: 'textarea',
            prop: 'remark',
            searchShow: false,
            rowShow: false,
          },
        ],
      },
    }
  },
  methods: {
    beforeShow(type, formData) {
      if (!type.isAdd) {
        if (formData.isSealed >= 0) {
          formData.isSealed = formData.isSealed.toString()
        }
      }
    },
    searchHandle(page, params, done) {
      getParentList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    addHandle(data, done) {
      add(data).then(() => {
        done()
      })
    },
    rowEdit(data, done) {
      update(data).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowConfig(data) {
      this.dict = data
      this.childFlag = true
    },
    sideHidden() {
      this.dict = undefined
      this.childFlag = false
    },
  },
}
</script>

<style scoped>

</style>
