import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-jxz/game/auth-package/list',
  method: 'get',
  params,
})

export const add = data => request.axiosIns({
  url: '/apollo-jxz/game/auth-package',
  method: 'post',
  data,
})

export const edit = data => request.axiosIns({
  url: '/apollo-jxz/game/auth-package',
  method: 'put',
  data,
})

export const remove = id => request.axiosIns({
  url: `/apollo-jxz/game/auth-package/${id}`,
  method: 'delete',
})
