<template>
  <div>
    <x-table
      :card="false"
      :options="options"
      @beforeShow="beforeShow"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete">
    </x-table>
  </div>
</template>

<script>
import XTable from '@core/components/cx/table/XTable.vue'
import { getList, add, update, remove } from '@/api/system/tenant-package'

export default {
  name: 'TenantPackage',
  components: {
    XTable,
  },
  data() {
    return {
      options: {
        actions: [
          { action: 'view', tip: '查看', icon: 'EyeIcon', permission: 'post_view' },
          { action: 'edit', tip: '编辑', icon: 'EditIcon', permission: 'post_edit' },
          { action: 'delete', tip: '删除', icon: 'Trash2Icon', permission: 'post_delete' },
        ],
        columns: [
          {
            label: '产品包名',
            prop: 'packageName',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入产品包名',
            },
          },
          {
            label: '菜单列表',
            prop: 'menuId',
            searchShow: false,
            type: 'tree',
            rowShow: false,
            dictUrl: '/apollo-upms/menu/tree',
            props: {
              label: 'title',
              value: 'id',
              multiple: true,
              emitPath: false,
              // collapseTags: true,
            },
            rules: {
              rule: 'required',
              message: '请选择菜单',
            },
          },
          {
            label: '备注',
            prop: 'remark',
            searchShow: false,
          },
        ],
      },
    }
  },
  methods: {
    beforeShow(type, formData) {
      if (type.isEdit || type.isView) {
        formData.menuId = formData.menuId.split(',')
      }
    },
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    addHandle(data, done) {
      data.menuId = data.menuId.join(',')
      add(data).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowEdit(data, done) {
      data.menuId = data.menuId.join(',')
      update(data).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
  },
}
</script>

<style scoped>

</style>
