<template>
  <div>
    <x-el-table
      ref="table"
      :options="options"
      @search="searchHandle">
      <template slot="userId" slot-scope="scope">
        <b-avatar v-viewer :src="scope.row.originStr.split(',')[1]" variant="light-primary"></b-avatar>
        <span class="ml-1 text-primary x-text-normal">{{ scope.row.originStr.split(',')[0] }}</span>
      </template>
       <template slot="type" slot-scope="scope">
         <span class="badge badge-light-primary" v-if="scope.row.type">商户端</span>
         <span class="badge badge-light-success" v-if="!scope.row.type">会员端</span>
       </template>
       <template slot="content" slot-scope="scope">
          <div class="x-text-cut">
            {{ scope.row.content }}
          </div>
        </template>
    </x-el-table>
  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import { BAvatar } from 'bootstrap-vue'
import {
  getList,
} from '@/api/data/feedback'

export default {
  name: 'feedback',
  components: {
    XElTable,
    BAvatar,
  },
  data() {
    return {
      options: {
        addBtn: false,
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon' },
        ],
        columns: [
          {
            label: '用户',
            prop: 'userId',
            searchShow: false,
          },
          {
            label: '发起端',
            prop: 'type',
            type: 'select',
            addShow: false,
            editShow: false,
            dictData: [{ label: '商户端', value: true }, { label: '会员端', value: false }],
            searchShow: false,
          },
          {
            label: '时间',
            prop: 'createTime',
            searchShow: false,
          },
          {
            label: '意见内容',
            type: 'textarea',
            prop: 'content',
            searchShow: false,
          },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
  },
}
</script>

<style lang="scss">
</style>
