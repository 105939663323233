import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-jxz/client/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

// 新增
export const add = data => request.axiosIns({
  url: '/apollo-jxz/client/submit',
  method: 'post',
  data,
})

// 发布
export const release = id => request.axiosIns({
  url: `/apollo-jxz/client/release/${id}`,
  method: 'post',
})

// 取消发布
export const unRelease = id => request.axiosIns({
  url: `/apollo-jxz/client/unRelease/${id}`,
  method: 'post',
})

export const remove = id => request.axiosIns({
  url: `/apollo-jxz/client/${id}`,
  method: 'delete',
})
