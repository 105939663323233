<template>
  <div class="jxz-dashboard pl-2 pr-2">
    <today-count :dashboard="dashboard"></today-count>
    <div class="row">
      <income-chart :dashboard="dashboard" class="col-md-6"></income-chart>
      <package-chart :dashboard="dashboard" class="col-md-6"></package-chart>
    </div>
    <div class="row">
      <member-trend-chart :dashboard="dashboard" class="col-md-6"></member-trend-chart>
      <mt-trend-chart :dashboard="dashboard" class="col-md-6"></mt-trend-chart>
    </div>
    <income-trend-chart :dashboard="dashboard"></income-trend-chart>
  </div>
</template>

<script>

import { } from 'bootstrap-vue'
import { queryDashboard } from '@/api/dashboards'
import TodayCount from './components/TodayCount.vue'
import IncomeChart from './components/IncomeChart.vue'
import PackageChart from './components/PackageChart.vue'
import MemberTrendChart from './components/MemberTrendChart.vue'
import MtTrendChart from './components/MtTrendChart.vue'
import IncomeTrendChart from './components/IncomeTrendChart.vue'

export default {
  components: {
    TodayCount,
    IncomeChart,
    PackageChart,
    MemberTrendChart,
    MtTrendChart,
    IncomeTrendChart,
  },
  data() {
    return {
      dashboard: undefined,
    }
  },
  created() {
    queryDashboard().then(resp => {
      this.dashboard = resp.data.data
    })
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
  .jxz-dashboard {
  }
</style>
