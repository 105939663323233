import { render, staticRenderFns } from "./XTableActions.vue?vue&type=template&id=566ecc60"
import script from "./XTableActions.vue?vue&type=script&lang=js"
export * from "./XTableActions.vue?vue&type=script&lang=js"
import style0 from "./XTableActions.vue?vue&type=style&index=0&id=566ecc60&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports