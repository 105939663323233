<template>
  <div>
    <slot name="xt-card-item" :row="data">
      <b-card class="ecommerce-card " no-body>
        <slot name="xt-card-item-header" :row="data">
          <div class="item-wrapper pt-2 pl-2 pr-2">
            <div>
              <div class="item-price">
                <b-avatar v-viewer :src="data[cardProps.icon.prop] ? data[cardProps.icon.prop] : defaultIconUrl"
                          :text="defaultIconText" :variant="defaultIconVariant">
                  <feather-icon v-if="!!defaultIcon" :icon="defaultIcon" />
                </b-avatar>
                <!--<feather-icon v-else icon="ServerIcon" class="mr-50" size="35" color="#7367F0"/>-->
                <a v-if="cardProps.name.column.linkUrl" v-tooltip="data[cardProps.name.prop]" class="text-primary x-text-bold xtable__link x-text-cut ml-50" @click="linkTo(cardProps.name.column.linkUrl,data)">
                  {{data[cardProps.name.prop]}}
                </a>
                <span v-else v-tooltip="data[cardProps.name.prop]" class="ml-1 x-text-bold">{{ data[cardProps.name.prop] }}</span>
              </div>
            </div>
          </div>
        </slot>
        <b-row class="ml-2 mt-1 mr-2" align="center" >
          <b-col :cols="col.cardColumnCols ? col.cardColumnCols : columnCols" :key="cindex" v-for="(col,cindex) in columns" >
            <div class="d-flex flex-column mb-2">
              <span class="x-text-cut text-secondary mb-1">{{col.label}}</span>
              <slot :name="col.prop" :data="data[col.prop]" :row="data">
                <div>
                  <div v-if="col.dictData && col.dictData.length > 0"
                       v-tooltip="col.getDictLabel ? col.getDictLabel(getRowDictLabel(col, data)) : data[col.prop]">
                    <b-badge
                      v-if="col.rowSelect"
                      class="x-text-cut"
                      :class="col.rowCls"
                      :variant="col | getRowSelectVariant(data[col.prop])">
                      {{col.getDictLabel ? col.getDictLabel(getRowDictLabel(col, data)) : data[col.prop]}}
                    </b-badge>
                    <div  v-else-if="col.rowSelectDot" class="d-flex flex-row align-items-center justify-content-center x-text-cut" :class="col.rowCls">
<!--                      <div class="x-dot" :class="col | getRowSelectVariant(data[col.prop])"></div>-->
                      <span class="font-medium-3 mr-50 animate__animated animate__infinite animate__pulse" :class="col | getRowSelectVariant(data[col.prop])">●</span>
                      {{col.getDictLabel ? col.getDictLabel(getRowDictLabel(col, data)) : data[col.prop]}}
                    </div>
                    <div v-else-if="col.linkUrl"  class="text-primary x-link x-text-cut" @click="linkTo(col.linkUrl,data)" :class="col.rowCls">
                      {{col.getDictLabel ? col.getDictLabel(getRowDictLabel(col, data)) : data[col.prop]}}
                    </div>
                    <div v-else class="x-text-bold x-text-cut" :class="col.rowCls">
                      {{col.getDictLabel ? col.getDictLabel(getRowDictLabel(col, data)) : data[col.prop]}}
                    </div>
                  </div>
                  <div v-tooltip="data[col.prop] ? new Date(data[col.prop]).cxFormat(col.format) : '/'" v-else-if="col.format && col.type === 'time' || col.type === 'date' || col.type === 'datetime' || col.type === 'datetimerange'" class="x-text-bold x-text-cut" :class="col.rowCls">
                    {{data[col.prop] ? new Date(data[col.prop]).cxFormat(col.format) : '/'}}
                  </div>
                  <div v-else-if="col.linkUrl" class="text-primary xtable__link x-text-cut" @click="linkTo(col.linkUrl,data)" :class="col.rowCls">
                    {{data[col.prop]}}
                  </div>
                  <div v-else v-tooltip="data[col.prop]" class="x-text-bold x-text-cut" :class="col.rowCls">{{data[col.prop]}}</div>
                </div>
              </slot>
            </div>
          </b-col>
        </b-row>
        <x-popconfirm ref="deletePopconfirm" :target="`${data.id}-delete`" content="确定要执行删除操作吗？" triggers="click"
                      @enter="deleteClick(data)" @hidden="hideClick">
        </x-popconfirm>
        <b-row v-if="showActions !== false" class="border-top ml-0 mr-0">
          <b-col :cols="12 / tableActionFoldCount" class="p-0 border-right d-flex justify-content-center align-items-center" v-for="(action,aindex) in actions" :key="aindex">
            <slot :name="`row-${action.action}`" :row="data">
              <b-button
                v-permission="action.permission"
                :disabled="!actionFilter(action.action,data)"
                :id="`${data.id}-${action.action}`"
                v-ripple.400="$x.ripple.primary"
                class="bottomBtn"
                variant="flat-primary"
                v-b-tooltip.hover.top="action.tip"
                @click="actionClick(action,{$index: index,index: index,item: data, row: data})">
                <span v-if="action.svg" class="svg-icon svg-icon-1x" :class="isDark.value ? 'svg-icon-white' : 'svg-icon-primary'">
                  <inline-svg :src="action.svg" />
                </span>
                <feather-icon v-else  class="x-text-bold" :icon="action.icon" />
                <span class="ml-50 x-text-bold">{{action.name}}</span>
              </b-button>
            </slot>
          </b-col>
          <b-col :cols="12 / tableActionFoldCount" class="p-0">
            <b-dropdown v-if="dropActions && dropActions.length > 0"
                        v-ripple.400="$x.ripple.primary"
                        @hide="rowDropActionClick"
                        variant="flat-primary2" dropup class="bottomBtn dropdown-icon-wrapper">
              <template #button-content>
                <span class="mr-50 x-text-bold" :class="isDark.value ? 'text-white': 'text-primary'">更多</span>
                <feather-icon icon="MoreVerticalIcon" class="x-text-bold" :class="isDark.value ? 'text-white': 'text-primary'"/>
              </template>
              <b-dropdown-item v-for="(drop,dindex) in dropActions"
                               v-permission="drop.permission"
                               :id="`${data.id}-${drop.action}`"
                               v-b-tooltip.hover.left="drop.tip"
                               :class="actionFilter(drop.action,data) ? '' : 'x-only-read'"
                               style="min-width: 8rem"
                               @click="actionFilter(drop.action,data) && actionClick(drop,{$index: index,index: index,item: data,row: data})" :key="dindex">

                <slot :name="`row-${drop.action}`" :row="data">
                  <div :class="actionFilter(drop.action,data) ? '' : 'text-secondary'" class="d-flex align-items-center">
                    <span v-if="drop.svg" class="svg-icon svg-icon-sm mr-50" :class="isDark.value ? 'svg-icon-white' : 'svg-icon-dark'">
                      <inline-svg :src="drop.svg" />
                    </span>
                    <feather-icon v-else :icon="drop.icon" class="mr-50"></feather-icon>
                    {{drop.name}}
                  </div>
                </slot>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-card>
    </slot>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BDropdown, BDropdownItem, BBadge, BButton, BAvatar, VBTooltip,
} from 'bootstrap-vue'
import XPopconfirm from '@core/components/cx/popconfirm/XPopconfirm.vue'
import userAppConfig from '@core/app-config/useAppConfig'
import website from '@/config/website'

export default {
  props: {
    /**
     * index
     */
    index: {
      type: Number,
      default: 0,
    },
    /**
     * 默认icon
     */
    defaultIcon: {
      type: String,
      default: undefined,
    },
    /**
     *  自定义form: 使用XTable中的弹窗， form数据托管给框架
     */
    customForm: {
      type: Boolean,
      default: false,
    },
    /**
     *  完全自定义form
     */
    customXForm: {
      type: Boolean,
      default: false,
    },
    /**
     * 默认icon url
     */
    defaultIconUrl: {
      type: String,
      default: '',
    },
    /**
     * 默认icon text
     */
    defaultIconText: {
      type: String,
      default: 'CX',
    },
    /**
     * 默认icon 颜色
     */
    defaultIconVariant: {
      type: String,
      default: 'light-primary',
    },
    /**
     * row 列
     */
    columns: {
      type: Array,
      default: () => [],
    },
    /**
     * 是否显示操作栏
     */
    showActions: {
      type: Boolean,
      default: true,
    },
    /**
     * 操作功能
     */
    actions: {
      type: Array,
      default: () => [],
    },
    /**
     * 折叠下拉操作功能
     */
    dropActions: {
      type: Array,
      default: () => [],
    },
    /**
     * 卡片头
     */
    cardProps: {
      type: Object,
      default: () => {},
    },
    actionFilter: {
      type: Function,
      default: () => true,
    },
    /**
     * 数据
     */
    data: {
      type: Object,
      default: () => {},
    },
    /**
     * card模式下 每个卡片中列的宽度
     */
    columnCols: {
      type: Number,
      default: 4,
    },
  },
  components: {
    BCard,
    BCol,
    BRow,
    BDropdown,
    BDropdownItem,
    BBadge,
    BButton,
    XPopconfirm,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    getRowSelectVariant: (item, value) => item.selectVariant(value),
  },
  model: {
    prop: 'data',
    event: 'change',
  },
  data() {
    return {
      // 是否为row删除点击
      isRowDelete: false,
      isDark: userAppConfig().isDark,
      tableActionFoldCount: website.tableActionFoldCount,
    }
  },
  created() {
    this.defaultIconUrl = this.defaultIconUrl || ''
    this.defaultIconText = this.defaultIconText || 'CX'
    this.defaultIconVariant = this.defaultIconVariant || 'light-primary'
    this.$emit('beforeCardCreate', this.data)
  },
  methods: {
    linkTo(url, item) {
      this.$router.push(url.cxSignMix(item))
    },
    actionClick(action, data) {
      switch (action.action) {
        case 'delete':
          this.isRowDelete = true
          break
        default:
          break
      }
      this.$emit('actionClick', action, data)
    },
    rowDropActionClick(bvEvt) {
      if (this.isRowDelete) {
        bvEvt.preventDefault()
      }
    },
    deleteClick(data) {
      this.$emit('rowDelete', data)
    },
    hideClick() {
      this.isRowDelete = false
    },
    getRowDictLabel: (col, data) => {
      let value = data[col.prop]
      let valueCol = (col.props && col.props.value) ? col.props.value : 'value'
      if (typeof value === 'object') {
        value = data[col.prop][valueCol]
      }
      // 字典查出值都为字符串
      value += ''
      for (let dict of col.dictData) {
        if ((dict.value + '') === value) {
          return dict
        }
      }
      return {}
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-ecommerce.scss";
  .btn-group [class*=btn-]:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]) {
    border: none !important;
  }
  .xtable__card{
    .btn-flat-primary {
      background-color: transparent !important;
      /*transition: all 0.25s ease-in-out;*/
    }
    /*.btn-flat-primary:hover {*/
      /*opacity: 0.7;*/
    /*}*/
  }
</style>
