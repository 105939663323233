<template>
  <div>
    <x-el-table
      ref="table"
      :options="options"
       @rowAdd="addHandle"
       @rowRelease="rowRelease"
       @rowDelete="rowDelete"
       @rowUnRelease="rowUnRelease"
       @rowEdit="rowEdit"
      @search="searchHandle">
      <template slot='versionNo' slot-scope='scope'>
        <b-badge variant="light-success" >
              {{ scope.row.versionNo }}
        </b-badge>
        </template>
      <template slot="form-versionNo" scope="scope">
          <b-form-input
            v-model="scope.row.versionNo"
            onkeyup="value=value.replace(/[^\d.]/g,'')"
            placeholder="请输入版本号(例：1.0.1)"
          />
      </template>
    </x-el-table>
  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import { BBadge, BFormInput } from 'bootstrap-vue'
import website from '@/config/website'
import {
  getList, add, release, unRelease, remove,
} from '@/api/data/upgrade'
import { Headers } from '@/api/resource/attach'

export default {
  name: 'Upgrade',
  components: {
    XElTable,
    BBadge,
    BFormInput,
  },
  data() {
    return {
      options: {
        addBtn: true,
        actions: [
          // { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon' },
          { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon' },
          { action: 'release', tip: '发布', icon: 'ToggleLeftIcon' },
          { action: 'unRelease', tip: '取消发布', icon: 'ToggleRightIcon' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon' },
        ],
        actionFilter: (action, row) => {
          if (row.releaseState === 1) {
            return action === 'edit' || action === 'release' || action === 'delete'
          }
          return action === 'unRelease'
        },
        columns: [
          {
            label: '标题',
            prop: 'title',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入标题',
            },
          },
          {
            label: '升级文件',
            prop: 'apkUrl',
            type: 'upload',
            rowShow: false,
            searchShow: false,
            upload: {
              url: `${website.prefix}/apollo-resource/oss/endpoint/put-file-attach`,
              headers: Headers,
              name: 'file',
              accept: 'apk/*',
            },
            rules: {
              rule: 'required',
              message: '请上传文件',
            },
          },
          {
            label: '版本号',
            prop: 'versionNo',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入正确的版本号',
            },
          },
          {
            label: '是否重大更新',
            prop: 'majorUpdate',
            type: 'select',
            rowSelect: true,
            searchShow: true,
            dictData: [
              {
                label: '正常更新',
                value: 1,
              },
              {
                label: '重大更新',
                value: 2,
              },
            ],
            props: {
              label: 'label',
              value: 'value',
            },
            selectVariant: propValue => {
              if (propValue === 1) {
                return 'badge badge-light-info'
              }
              return 'badge badge-light-warning'
            },
            rules: {
              rule: 'required',
              message: '请选择更新类型',
            },
          },
          {
            label: '所属分支',
            prop: 'branch',
            type: 'select',
            rowSelect: true,
            dictUrl: '/apollo-upms/dict-biz/dictionary?code=upgrade_branch',
            selectVariant: value => {
              if (value === 'production') {
                return 'badge badge-light-info'
              }
              if (value === 'development') {
                return 'badge badge-light-primary'
              }
              return 'badge badge-light-secondary'
            },
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
          },
          {
            label: '发布状态',
            prop: 'releaseState',
            searchShow: true,
            addShow: false,
            editShow: false,
            type: 'select',
            rowSelectDot: true,
            dictData: [
              {
                label: '未发布',
                value: 1,
              },
              {
                label: '已发布',
                value: 2,
              },
            ],
            props: {
              label: 'label',
              value: 'value',
            },
            selectVariant: propValue => {
              if (propValue === 1) {
                return 'text-warning'
              }
              return 'text-success'
            },
          },
          {
            label: '创建时间',
            prop: 'createTime',
            addShow: false,
            editShow: false,
            searchShow: false,
          },
          {
            label: '发布时间',
            prop: 'releaseTime',
            addShow: false,
            editShow: false,
            searchShow: false,
          },
          {
            label: '描述',
            type: 'textarea',
            prop: 'description',
            rowShow: false,
            searchShow: false,
          },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    addHandle(data, done) {
      let regxFlag = /^[1-9]\d?(\.([1-9]?\d)){2}$/.test(data.versionNo)
      if (!regxFlag) {
        this.$xtoast.error('请输入正确的版本号！')
        return
      }
      add(data).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowEdit(data, done) {
      let regxFlag = /^[1-9]\d?(\.([1-9]?\d)){2}$/.test(data.versionNo)
      if (!regxFlag) {
        this.$xtoast.error('请输入正确的版本号！')
        return
      }
      add(data).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowRelease(data, done) {
      release(data.id).then(() => {
        done()
      })
    },
    rowUnRelease(data, done) {
      unRelease(data.id).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
  },
}
</script>

<style lang="scss">
</style>
