import request from '@/auth/jwt/useJwt'

// 获取字典数据
export function getDictData(URL) {
  return request.axiosIns({
    url: URL,
    method: 'get',
  })
}

export function getList(current, size, params) {
  return request.axiosIns({
    url: '/apollo-upms/dict/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}

export function getParentList(current, size, params) {
  return request.axiosIns({
    url: '/apollo-upms/dict/parent-list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}

export function getChildList(current, size, parentId, params) {
  return request.axiosIns({
    url: '/apollo-upms/dict/child-list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
      parentId,
    },
  })
}

export function remove(ids) {
  return request.axiosIns({
    url: '/apollo-upms/dict/remove',
    method: 'post',
    params: {
      ids,
    },
  })
}

export function add(row) {
  return request.axiosIns({
    url: '/apollo-upms/dict/submit',
    method: 'post',
    data: row,
  })
}

export function update(row) {
  return request.axiosIns({
    url: '/apollo-upms/dict/submit',
    method: 'post',
    data: row,
  })
}

export function getDict(id) {
  return request.axiosIns({
    url: '/apollo-upms/dict/detail',
    method: 'get',
    params: {
      id,
    },
  })
}
export function getDictTree() {
  return request.axiosIns({
    url: '/apollo-upms/dict/tree?code=DICT',
    method: 'get',
  })
}

export function getDictionary(params) {
  return request.axiosIns({
    url: '/apollo-upms/dict/dictionary',
    method: 'get',
    params,
  })
}
