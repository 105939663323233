<template>
  <div>
    <b-row>
      <b-col md="4">
        <x-overlay v-model="showTreeLoading" show-toast>
          <div class="card card-body overflow-y-scroll x-scrollbar h-75">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" class="text-primary"/>
              </b-input-group-prepend>
              <b-form-input class="ml-n1"  v-model="filterText" placeholder="输入关键字进行过滤"/>
            </b-input-group>
            <el-tree
              ref="tree"
              lazy
              class="mt-1"
              node-key="id"
              :data="treeData"
              :props="treeProps"
              :load="loadNode"
              :expand-on-click-node="false"
              @node-click="nodeClick"
              :filter-node-method="filterNode">
              <div class="x-w-100 d-flex align-items-center justify-content-between" slot-scope="{ node, data }">
                <x-popconfirm
                  :target="`${data.id}-delete`"
                  content="确定要执行删除操作吗？"
                  triggers="click"
                  @enter="deleteRow(deleteRowId)">
                </x-popconfirm>
                <span>{{ node.label }}</span>
                <div class="mr-1">
                  <feather-icon v-permission="`region_add`" class="text-primary x-hover mr-1" icon="PlusIcon" v-tooltip.hover="`新增下级`" @click="addChildren"/>
                  <feather-icon v-permission="`region_delete`" :id="`${data.id}-delete`" class="text-danger x-hover" icon="XIcon" v-tooltip.hover="`删除`" @click="deleteClick(data.id)"/>
                </div>
              </div>
            </el-tree>
          </div>
        </x-overlay>
      </b-col>
      <b-col md="8">
        <x-overlay v-model="showLoading" show-toast>
          <region-form :data="regionForm" @cancel="reset" @enter="enter" :disabled="treeParentCode === topCode"></region-form>
        </x-overlay>
      </b-col>
    </b-row>
    <b-sidebar
      :visible="showAddView"
      bg-variant="white"
      right
      backdrop
      shadow
      width="50%"
      @hidden="hideAddView">
      <region-form :data="addRegionForm" @enter="enter"></region-form>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupPrepend, BFormInput, BRow, BCol, BSidebar,
} from 'bootstrap-vue'
import XPopconfirm from '@core/components/cx/popconfirm/XPopconfirm.vue'
import { getLazyTree, getDetail, submit, remove } from '@/api/resource/region'
import XOverlay from '@core/components/cx/overlay/XOverlay.vue'
import RegionForm from './RegionForm.vue'

export default {
  name: 'Region',
  components: {
    XOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BRow,
    BCol,
    RegionForm,
    BSidebar,
    XPopconfirm,
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  data() {
    return {
      topCode: '00',
      filterText: '',
      treeData: [],
      treeProps: {
        label: 'title',
        value: 'value',
      },
      treeCode: '',
      treeParentCode: '00',
      regionForm: { },
      addRegionForm: {},
      showLoading: false,
      showTreeLoading: false,
      showAddView: false,
      isAddClick: false,
      deleteRowId: undefined,
    }
  },
  created() {
  },
  methods: {
    initTree() {
      this.showTreeLoading = true
      this.treeData = []
      getLazyTree(this.topCode).then(res => {
        this.treeData = res.data.data.map(item => {
          return {
            ...item,
            leaf: !item.hasChildren,
          }
        })
        this.showTreeLoading = false
      }).catch(() => {
        this.showTreeLoading = false
      })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    loadNode(node, resolve) {
      const parentCode = (node.level === 0) ? '00' : node.data.id
      getLazyTree(parentCode).then(res => resolve(res.data.data.map(item => {
        return {
          ...item,
          leaf: !item.hasChildren,
        }
      })))
    },
    nodeClick(data) {
      this.showLoading = true
      this.treeCode = data.id
      this.treeParentCode = data.parentId
      getDetail(this.treeCode).then(res => {
        this.regionForm = res.data.data
        this.regionForm.subCode = this.regionForm.code.replace(this.regionForm.parentCode, '')
        this.showLoading = false
        if (this.isAddClick) {
          this.addRegionForm = this._.cloneDeep(this.regionForm)
          this.addRegionForm.parentCode = this.regionForm.code
          this.addRegionForm.parentName = this.regionForm.name
          this.addRegionForm.code = ''
          this.addRegionForm.subCode = ''
          this.addRegionForm.name = ''
          this.addRegionForm.regionLevel = (this.regionForm.regionLevel === 5) ? 5 : this.regionForm.regionLevel + 1
          this.showAddView = true
          this.isAddClick = false
        }
      }).catch(() => {
        this.showLoading = false
      })
    },
    enter(form, done) {
      const parentCode = form.parentCode === this.topCode ? '' : form.parentCode
      form.code = parentCode + form.subCode
      submit(form).then(() => {
        this.treeParentCode = this.topCode
        this.$xtoast.success('提交成功！')
        this.initTree()
        done()
      })
    },
    reset() {
    },
    deleteClick(id) {
      if (this.deleteRowId === id) {
        return
      }
      this.$root.$emit('bv::hide::popover', this.deleteRowId + '-delete')
      this.deleteRowId = id
    },
    deleteRow(id) {
      remove(id).then(() => {
        this.$xtoast.success('刪除成功！')
      })
    },
    addChildren() {
      this.isAddClick = true
    },
    hideAddView() {
      this.showAddView = false
      this.addRegionForm = {}
    },
  },
}
</script>

<style scoped>

</style>
