var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('x-el-table',{ref:"table",attrs:{"options":_vm.options},on:{"search":_vm.searchHandle,"rowDelete":_vm.rowDelete,"rowSelected":_vm.rowSelected},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
var data = ref.data;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{directives:[{name:"viewer",rawName:"v-viewer"}],attrs:{"src":row.link,"variant":"light-primary","size":"50","square":""}}),_c('a',{staticClass:"text-primary ml-1",attrs:{"href":row.link,"target":"_blank"}},[_vm._v(_vm._s(_vm._f("getName")(data)))])],1)]}},{key:"link",fn:function(ref){
var data = ref.data;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(("" + data)),expression:"`${data}`",modifiers:{"hover":true}}],staticClass:"text-primary x-text-cut",attrs:{"href":data,"target":"_blank"}},[_vm._v(_vm._s(data))])]}},{key:"attachSize",fn:function(ref){
var data = ref.data;
return [_c('div',[_c('span',{staticClass:"badge badge-light-primary"},[_vm._v(_vm._s((data / 1024).toFixed(0) + ' KB'))])])]}},{key:"extension",fn:function(ref){
var data = ref.data;
return [_c('div',[_c('span',{staticClass:"badge badge-light-primary"},[_vm._v(_vm._s(data))])])]}}])},[_c('template',{slot:"actions"},[_c('div',[_c('div',{directives:[{name:"permission",rawName:"v-permission",value:("attach_upload"),expression:"`attach_upload`"}],staticClass:"btn btn-primary mr-1",on:{"click":_vm.addHandle}},[_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}}),_vm._v(" 上传 ")],1),_c('div',{directives:[{name:"permission",rawName:"v-permission",value:("attach_delete"),expression:"`attach_delete`"}],staticClass:"btn btn-light-danger",on:{"click":_vm.deleteHandle}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}}),_vm._v(" 删除 ")],1)])])],2),_c('b-modal',{attrs:{"visible":_vm.showUploadModal,"title-class":"x-text-bold","ok-only":"","ok-title":"取消","ok-variant":"flat-secondary","title":"上传附件","centered":""},on:{"hidden":_vm.hide,"ok":_vm.hide}},[_c('div',{staticClass:"d-flex justify-content-center mt-4"},[_c('el-upload',{attrs:{"drag":"","action":(_vm.prefix + "/apollo-resource/oss/endpoint/put-file-attach"),"headers":_vm.headers,"before-upload":_vm.beforeUpload,"on-success":_vm.uploadSuccess}},[_c('i',{staticClass:"el-icon-upload text-primary"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("将文件拖到此处，或"),_c('em',[_vm._v("点击上传")])]),_c('b-alert',{attrs:{"slot":"tip","show":"","variant":"warning"},slot:"tip"},[_c('div',{staticClass:"alert-body mt-1"},[_c('span',{staticClass:"el-icon-info"}),_vm._v(" 附件大小不超过5MB ")])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }