<template>
  <b-overlay
    :show="showLoading"
    rounded="sm"
    variant="light"
    opacity="0.85"
    blur="2px"
    class="user-profile"
    spinnerVariant="primary">
    <div v-if="!showLoading">
      <div class="card card-body">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <feather-icon class="text-primary x-link mr-1" icon="ChevronLeftIcon" size="30" @click="$router.go(-1)"></feather-icon>
            <b-avatar v-viewer :src="userProfile.avatar" variant="light-primary" size="80"></b-avatar>
            <div class="d-flex flex-column ml-1">
              <span class="x-text-bold font-medium-4">{{userProfile.nickName}}</span>
              <div class="mt-50"><span class="badge badge-light-primary d-inline">{{userProfile.phone ? userProfile.phone : '暂未设置手机号'}}</span></div>
            </div>
          </div>
          <div v-if="userProfile.mts && userProfile.mts.length > 0" class="d-flex align-items-center ml-1">
            <div v-for="(item, index) in userProfile.mts" :key="index" class="x-card x-anim-card x-corner p-2 mr-1 mt-hover">
              <div class="d-flex align-items-center">
                <b-avatar v-viewer :src="item.mtAvatar" size="50"></b-avatar>
                <div class="text-primary ml-50">
                  <div class="x-link font-medium-1" @click="$router.push(`/data/merchant/profile?id=${item.mtId}`)">{{item.mtName}}</div>
                  <div class="text-secondary d-flex align-items-center"><feather-icon class="mr-50" icon="ClockIcon"/>{{item.createTime}}</div>
                </div>
              </div>
              <div class="x-text-bold text-success font-medium-4 mt-1">
                {{ item.coin }} <span class="text-secondary font-small-4 x-text-normal"> /币</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-body">
        <b-tabs
          vertical
          active-nav-item-class="bg-light-primary nav-vertical-w justify-content-start x-anim-fade-in"
          nav-wrapper-class="nav-vertical">
          <b-tab>
            <template #title>
              <span class="ml-1 mr-">游戏记录</span>
            </template>
            <div class="row w-100">
              <game-record :query-member-id="userProfile.id" class="col-12"></game-record>
            </div>
          </b-tab>
          <b-tab lazy>
            <template #title>
              <span class="ml-1 mr-1">充值记录</span>
            </template>
            <div class="row w-100">
              <bill :query-origin="userProfile.id" :query-type="1" class="col-12"></bill>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BTabs, BTab, BAvatar, BOverlay,
} from 'bootstrap-vue'
import {
  profile,
} from '@/api/data/member'
import GameRecord from '../../game-record/index.vue'
import Bill from '../../bill/index.vue'

export default {
  components: {
    BTabs,
    BTab,
    BAvatar,
    BOverlay,
    GameRecord,
    Bill,
  },
  data() {
    return {
      showLoading: true,
      userProfile: undefined,
    }
  },
  created() {
    if (!this.$route.query.id) {
      this.$xtoast.error('会员不存在！')
      this.$router.go(-1)
      return
    }
    this.showLoading = true
    profile(this.$route.query.id).then(resp => {
      this.userProfile = resp.data.data
      this.showLoading = false
    })
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.user-profile {
  .mt-hover {
    transform: skew(-20deg);
    transition: all 0.25s ease-in-out;
    &:hover {
      transform: translateY(-10px);
      z-index: 100000000000;
    }
  }
}
</style>
