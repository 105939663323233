<template>
  <div>
    <div class="card card-body">
      <div class="d-flex align-items-center">
        <b-img height="30" :src="require('@/assets/jxz/income-trend.svg')"/>
        <span class="text-secondary ml-50">收入增长趋势</span>
      </div>
      <echart-line class="chart" :optionData="optionData"></echart-line>
    </div>
  </div>
</template>

<script>

import { BImg } from 'bootstrap-vue'
// import ECharts from 'vue-echarts'
import EchartLine from '@/@core/components/charts/echart/AppEchartLine.vue'

export default {
  components: {
    BImg,
    EchartLine,
  },
  props: {
    dashboard: {
      type: Object,
      default: undefined,
    },
  },
  watch: {
    dashboard: {
      handler() {
        if (this.dashboard && this.dashboard.trend) {
          this.optionData = this.getOptionData(this.dashboard.trend.softwares, this.dashboard.trend.recharges, this.dashboard.trend.distributions)
        }
      },
    },
  },
  data() {
    return {
      optionData: this.getOptionData(),
    }
  },
  methods: {
    getOptionData(softwares, recharges, distributions) {
      return {
        legend: {
          data: ['软件销售', '会员充值', '运营抽成'],
        },
        xAxisData: this.$x.utils.Date.getLastWeek(),
        series: [
          {
            name: '软件销售',
            type: 'line',
            showSymbol: false,
            lineStyle: { width: 3 },
            areaStyle: { opacity: 0.6 },
            data: softwares || [0, 0, 0, 0, 0, 0, 0],
            smooth: true,
            stack: 'Total',
            emphasis: { focus: 'series' },
          },
          {
            name: '会员充值',
            type: 'line',
            showSymbol: false,
            lineStyle: { width: 3 },
            areaStyle: { opacity: 0.6 },
            data: recharges || [0, 0, 0, 0, 0, 0, 0],
            smooth: true,
            stack: 'Total',
            emphasis: { focus: 'series' },
          },
          // {
          //   name: '套餐购买',
          //   type: 'line',
          //   showSymbol: false,
          //   lineStyle: { width: 3 },
          //   areaStyle: { opacity: 0.6 },
          //   data: [1526, 542, 478, 614, 456, 258, 642],
          //   smooth: true,
          //   stack: 'Total',
          //   emphasis: { focus: 'series' },
          // },
          {
            name: '运营抽成',
            type: 'line',
            showSymbol: false,
            lineStyle: { width: 3 },
            areaStyle: { opacity: 0.6 },
            data: distributions || [0, 0, 0, 0, 0, 0, 0],
            smooth: true,
            stack: 'Total',
            emphasis: { focus: 'series' },
          },
        ],
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  .chart {
    height: 260px;
  }
</style>
