import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-jxz/advertisement/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const add = row => request.axiosIns({
  url: '/apollo-jxz/advertisement',
  method: 'post',
  data: row,
})

export const edit = data => request.axiosIns({
  url: '/apollo-jxz/advertisement',
  method: 'put',
  data,
})

export const enable = id => request.axiosIns({
  url: `/apollo-jxz/advertisement/enable/${id}`,
  method: 'put',
})

export const disable = id => request.axiosIns({
  url: `/apollo-jxz/advertisement/disable/${id}`,
  method: 'put',
})

export const remove = id => request.axiosIns({
  url: `/apollo-jxz/advertisement/${id}`,
  method: 'delete',
})
