import request from '@/auth/jwt/useJwt'

export function getApiList(current, size, params) {
  return request.axiosIns({
    url: '/apollo-log/api/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}

export function getErrorList(current, size, params) {
  return request.axiosIns({
    url: '/apollo-log/error/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}
export function getUsualList(current, size, params) {
  return request.axiosIns({
    url: '/apollo-log/usual/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}

export function getApiDetail(id) {
  return request.axiosIns({
    url: '/apollo-log/api/detail',
    method: 'get',
    params: {
      id,
    },
  })
}
export function getErrorDetail(id) {
  return request.axiosIns({
    url: '/apollo-log/error/detail',
    method: 'get',
    params: {
      id,
    },
  })
}
export function getUsualDetail(id) {
  return request.axiosIns({
    url: '/apollo-log/usual/detail',
    method: 'get',
    params: {
      id,
    },
  })
}
