import request from '@/auth/jwt/useJwt'

export const _init = {}

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-jxz/finance/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})
