import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-resource/sms/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const getDetail = id => request.axiosIns({
  url: '/apollo-resource/sms/detail',
  method: 'get',
  params: {
    id,
  },
})

export const remove = ids => request.axiosIns({
  url: '/apollo-resource/sms/remove',
  method: 'post',
  params: {
    ids,
  },
})

export const add = row => request.axiosIns({
  url: '/apollo-resource/sms/submit',
  method: 'post',
  data: row,
})

export const update = row => request.axiosIns({
  url: '/apollo-resource/sms/submit',
  method: 'post',
  data: row,
})

export const enable = id => request.axiosIns({
  url: '/apollo-resource/sms/enable',
  method: 'post',
  params: {
    id,
  },
})

export const send = (code, phones, params) => request.axiosIns({
  url: '/apollo-resource/sms/endpoint/send-message',
  method: 'post',
  params: {
    code,
    phones,
    params,
  },
})
