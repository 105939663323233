<template>
  <div>
    <x-el-table
      ref="table"
      :options="options"
      @search="searchHandle"
      @rowAdd="rowAdd"
      @rowEdit="rowEdit"
      @rowEnable="rowEnable"
      @rowDisable="rowDisable"
      @rowDelete="rowDelete">
      <template slot="title" slot-scope="scope">
        <div class="text-primary x-text-normal x-link" @click="() => $refs.table.actionClick(options.actions[0],scope)">{{ scope.row.title }}</div>
      </template>
      <template slot="image" slot-scope="{ data }">
        <div v-if="data" class="d-flex align-items-center">
          <el-image v-viewer class="table-item-img" :src="data" fit="cover" alt="图片"></el-image>
          <a v-tooltip.hover="`${data}`" class="text-primary ml-1 x-text-cut" :href="data" target="_blank">{{ data }}</a>
        </div>
      </template>
      <template slot="video" slot-scope="{ data }">
        <a v-if="data"  v-tooltip.hover="`${data}`" class="text-primary ml-1 x-text-cut" :href="data" target="_blank">{{ data }}</a>
      </template>
      <template slot="top" slot-scope="scope">
        <b-form-checkbox v-model="scope.data" switch inline disabled></b-form-checkbox>
      </template>
    </x-el-table>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'
import XElTable from '@core/components/cx/table/XElTable.vue'
import website from '@/config/website'
import {
  getList, add, edit, enable, disable, remove,
} from '@/api/marketing/advertisement'
import { Headers } from '@/api/resource/attach'

export default {
  name: 'Advertisement',
  components: {
    XElTable,
    BFormCheckbox,
  },
  data() {
    return {
      options: {
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon' },
          { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon' },
          { action: 'enable', name: '启用', icon: 'CheckIcon' },
          { action: 'disable', name: '禁用', icon: 'XIcon' },
        ],
        columns: [
          {
            label: '标题',
            prop: 'title',
            searchShow: true,
            rules: { rule: 'required', message: '请输入标题' },
          },
          {
            label: '商户',
            prop: 'mtId',
            type: 'select',
            rowCls: 'x-text-normal',
            linkUrl: '/data/merchant/profile?id={mtId}',
            searchShow: true,
            dictUrl: '/apollo-jxz/gm/merchant/all',
            rules: { rule: 'required', message: '请选择商户' },
            props: {
              label: 'nickName',
              value: 'id',
            },
          },
          {
            label: '图片',
            prop: 'image',
            type: 'upload',
            searchShow: false,
            upload: {
              url: `${website.prefix}/apollo-resource/oss/endpoint/put-file-attach`,
              headers: Headers,
              name: 'file',
              accept: 'image/*',
            },
          },
          {
            label: '视频',
            prop: 'video',
            type: 'upload',
            searchShow: false,
            upload: {
              url: `${website.prefix}/apollo-resource/oss/endpoint/put-file-attach`,
              headers: Headers,
              name: 'file',
              accept: 'video/*',
            },
          },
          {
            label: '文字内容',
            prop: 'content',
            type: 'textarea',
            searchShow: false,
          },
          {
            label: '发布状态',
            prop: 'state',
            type: 'select',
            rowSelectDot: true,
            selectVariant: value => value === 2 ? 'text-danger' : 'text-success',
            addShow: false,
            editShow: false,
            dictData: [{ label: '已发布', value: 1 }, { label: '未发布', value: 2 }],
          },
          {
            label: '是否顶部',
            prop: 'top',
            type: 'switch',
            searchShow: false,
          },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    rowAdd(data, done) {
      if (data.image === '' || data.image.length === 0) {
        data.image = undefined
      }
      if (data.video === '' || data.video.length === 0) {
        data.video = undefined
      }
      add(data).then(() => {
        done()
      })
    },
    rowEdit(data, done) {
      if (data.image === '' || data.image.length === 0) {
        data.image = undefined
      }
      if (data.video === '' || data.video.length === 0) {
        data.video = undefined
      }
      edit(data).then(() => {
        done()
      })
    },
    rowEnable(data, done) {
      enable(data.id).then(() => {
        done()
      })
    },
    rowDisable(data, done) {
      disable(data.id).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
  },
}
</script>

<style lang="scss">
  .table-item-img {
    width: 200px;
    height: 60px;
  }
</style>
