<template>
  <b-overlay
    :show="showLoading"
    rounded="sm"
    variant="light"
    opacity="0.85"
    blur="2px"
    class="user-profile"
    spinnerVariant="primary">
    <div v-if="!showLoading">
      <div class="card card-body">
        <div class="d-flex align-items-center">
          <feather-icon class="text-primary x-link mr-1" icon="ChevronLeftIcon" size="30" @click.stop="back"></feather-icon>
          <b-avatar v-viewer :src="mtProfile.avatar" variant="light-primary" size="80"></b-avatar>
          <div class="d-flex flex-column ml-1">
            <span class="x-text-bold font-medium-4">{{mtProfile.nickName}}</span>
            <div class="mt-50"><span class="badge badge-light-primary d-inline">{{mtProfile.phone ? mtProfile.phone : '暂未设置手机号'}}</span></div>
            <div class="text-secondary mt-50 d-flex align-items-center x-link x-text-normal" @click="showShop = true">
              点击查看店铺信息<feather-icon icon="EditIcon" class="ml-50"></feather-icon></div>
          </div>
        </div>
      </div>
      <div class="card card-body">
        <b-tabs
          vertical
          active-nav-item-class="bg-light-primary nav-vertical-w justify-content-start x-anim-fade-in"
          nav-wrapper-class="nav-vertical">
          <b-tab>
            <template #title>
              <span class="ml-1 mr-1">会员列表</span>
            </template>
            <div class="row w-100">
              <member  :query-mt-id="mtProfile.id" class="col-12"></member>
            </div>
          </b-tab>
          <b-tab lazy>
            <template #title>
              <span class="ml-1 mr-">游戏记录</span>
            </template>
            <div class="row w-100">
              <game-record :query-mt-id="mtProfile.id" class="col-12"></game-record>
            </div>
          </b-tab>
          <b-tab lazy>
            <template #title>
              <span class="ml-1 mr-1">商户账单</span>
            </template>
            <div class="row w-100">
              <bill :query-mt-id="mtProfile.id" class="col-12"></bill>
            </div>
          </b-tab>
          <b-tab lazy>
            <template #title>
              <span class="ml-1 mr-1">机器列表</span>
            </template>
            <div class="row w-100">
              <machine :query-mt-id="mtProfile.id" class="col-12"></machine>
            </div>
          </b-tab>
          <b-tab lazy>
            <template #title>
              <span class="ml-1 mr-1">我的店铺</span>
            </template>
            <div class="row w-100">
              <shop :query-mt-id="mtProfile.id" class="col-12"></shop>
            </div>
          </b-tab>
          <b-tab lazy>
            <template #title>
              <span class="ml-1 mr-1">代理店铺</span>
            </template>
            <div class="row w-100">
              <shop :query-mt-id="mtProfile.id" admin-shop="true" class="col-12"></shop>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <b-modal
        size="md"
        :visible="showShop"
        title-class="x-text-bold"
        body-class="x-scrollbar pl-4 pr-4"
        hide-footer
        title="店铺详情"
        centered
        @hidden="sideHidden"
        scrollable>
        <div class="info-item pt-1 pb-1">
          <span class="text-secondary">店铺名称：</span>
          <span class="x-text-bold">{{ mtProfile.mtShop && mtProfile.mtShop.name }}</span>
        </div>
        <div class="info-item pt-1 pb-1">
          <span class="text-secondary">联系电话：</span>
          <span class="x-text-bold">{{ mtProfile.mtShop && mtProfile.mtShop.phone }}</span>
        </div>
        <div class="info-item pt-1 pb-1">
          <span class="text-secondary">联系地址：</span>
          <span class="x-text-bold">{{ mtProfile.mtShop && mtProfile.mtShop.address }}</span>
        </div>
        <div class="info-cover pt-1 pb-1 mb-2">
          <span class="text-secondary">店铺图片：</span>
          <el-image v-viewer class="info-cover-image mt-1" :src="mtProfile.mtShop.photos"></el-image>
        </div>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import {
  BTabs, BTab, BAvatar, BOverlay, BModal,
} from 'bootstrap-vue'
import {
  profile,
} from '@/api/data/merchant'
import GameRecord from '../../game-record/index.vue'
import Bill from '../../bill/index.vue'
import Machine from '../../machine/index.vue'
import Member from '../../member/index.vue'
import Shop from '../../shop/index.vue'

export default {
  components: {
    BTabs,
    BTab,
    BAvatar,
    BOverlay,
    GameRecord,
    Bill,
    Machine,
    Member,
    BModal,
    Shop,
  },
  data() {
    return {
      showShop: false,
      showLoading: true,
      mtProfile: undefined,
    }
  },
  created() {
    if (!this.$route.query.id) {
      this.$xtoast.error('店铺不存在！')
      this.$router.go(-1)
      return
    }
    this.showLoading = true
    profile(this.$route.query.id).then(resp => {
      this.mtProfile = resp.data.data
      this.showLoading = false
    })
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    sideHidden() {
      this.showShop = false
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/@core/scss/base/bootstrap-extended/_variables';
  .info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed $border-color !important;
  }
  .info-cover {
     display: flex;
     flex-direction: column;
     justify-content: center;
     &-image {
       height: 200px;
     }
   }
</style>
