<template>
  <div>
    <x-el-table
      customXForm
      ref="table"
      :options="options"
      @search="searchHandle"
      @rowRole="rowRole"
      @rowView="rowView"
      @rowEdit="rowEdit"
      @rowSelected="rowSelected"
      @rowDelete="rowDelete">
      <template slot="account" slot-scope="scope">
        <div class="text-primary x-text-normal x-link" @click="() => $refs.table.actionClick(options.actions[0],scope)">{{ scope.row.account }}</div>
      </template>
      <template slot="deptName" slot-scope="scope">
        <b-badge variant="light-primary" class="mr-50" v-for="item in scope.row.deptName.split(',')" :key="item.index">{{item}}</b-badge>
      </template>
      <template slot="roleName" slot-scope="scope">
        <b-badge variant="light-primary" class="mr-50" v-for="item in scope.row.roleName.split(',')" :key="item.index">{{item}}</b-badge>
      </template>
      <template slot="actions">
        <div
          v-permission="`user_add`"
          v-ripple.400="$x.ripple.primary"
          class="btn btn-primary"
          @click="toAdd">
          <feather-icon
            size="16"
            icon="PlusIcon"
            class="mr-50"
          />新增
        </div>
        <div
          v-permission="`user_reset`"
          v-ripple.400="$x.ripple.warning"
          class="btn btn-light-warning ml-1"
          @click="toSetPassword">
          <feather-icon
            size="16"
            icon="RotateCcwIcon"
            class="mr-50"
          />密码重置
        </div>
        <div
          v-permission="`user_enable`"
          v-ripple.400="$x.ripple.danger"
          class="btn btn-light-danger ml-1"
          @click="toUnlock">
          <feather-icon
            size="16"
            icon="LockIcon"
            class="mr-50"
          />账号解封
        </div>
      </template>
    </x-el-table>
    <b-sidebar
      id="sidebar-right"
      ref="slider"
      idebar-class="sidebar-xl"
      bg-variant="white"
      title="新增用户"
      right
      backdrop
      shadow
      width="40%"
      :visible="visibleFlag"
      @hidden="sideHidden"
    >
      <user-form :form-data="formData" :is-view="isView" :is-new="isNew" @editSuccess="editSuccess"/>
    </b-sidebar>
    <b-modal
      ref="roleModal"
      cancel-variant="flat-secondary"
      ok-title="保存"
      cancel-title="取消"
      centered
      title="用户角色配置"
      :visible="roleModalFlag"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div class="mt-5 mb-5 d-flex justify-content-center">
        <el-cascader  class="w-75"
          v-model="roleIds"
          :options="roles"
          :props="{ label: 'title', value: 'id', multiple: true, emitPath: false }">
        </el-cascader>
      </div>
    </b-modal>
    <b-modal
      ref="passwordModal"
      cancel-variant="flat-secondary"
      ok-title="确定"
      cancel-title="取消"
      centered
      title="密码重置"
      :visible="passwordModalFlag"
      @hidden="resetModalPassword"
      @ok="handleOkPassword"
    >
      <b-alert
        variant="warning"
        show
      >
        <h4 class="alert-heading">
          <feather-icon
            size="16"
            icon="AlertCircleIcon"
            class="mr-50"
          />
          确定将选择用户的密码重置为123456？
        </h4>
      </b-alert>
    </b-modal>
    <b-modal
      ref="unlockModal"
      cancel-variant="flat-secondary"
      ok-title="确定"
      cancel-title="取消"
      centered
      title="账号解封"
      :visible="unlockModalFlag"
      @hidden="resetModalUnlock"
      @ok="handleOkUnlock"
    >
      <b-alert variant="warning" show>
        <div class="alert-body">
          <feather-icon size="16" icon="AlertCircleIcon" class="mr-50"/>
          确定将选择账号解封？
        </div>
      </b-alert>
    </b-modal>
  </div>
</template>

<script>

import XElTable from '@core/components/cx/table/XElTable.vue'
import {
  getList, remove, roleTree, grant, resetPassword, unlock,
} from '@/api/system/user'
import { BSidebar, BBadge, BModal, BAlert } from 'bootstrap-vue'
import userForm from './userForm.vue'

export default {
  name: 'User',
  components: {
    XElTable,
    BSidebar,
    userForm,
    BBadge,
    BModal,
    BAlert,
  },
  data() {
    return {
      roleModalFlag: false,
      // 密码重置
      passwordModalFlag: false,
      unlockModalFlag: false,
      // 选中行
      rowSelects: [],
      roleIds: undefined,
      roles: [],
      user: {},
      isView: false,
      isNew: false,
      formData: {
        tenantId: undefined,
        account: undefined,
        userType: undefined,
        password: undefined,
        password2: undefined,
        name: undefined,
        realName: undefined,
        phone: undefined,
        email: undefined,
        sex: undefined,
        birthday: undefined,
      },
      // sidebar
      visibleFlag: false,
      options: {
        // 多选框
        rowSelectable: true,
        addBtn: false,
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon', permission: 'user_view', customXForm: false },
          { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon', permission: 'user_edit' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon', permission: 'user_delete' },
          { action: 'role', tip: '权限配置', icon: 'ShieldIcon', permission: 'user_role' },
        ],
        columns: [
          {
            label: '登录账号',
            prop: 'account',
            searchShow: true,
            rowCls: 'text-primary x-link',
          },
          // {
          //   label: '所属租户',
          //   prop: 'tenantName',
          //   searchShow: false,
          //   rowSelect: true,
          // },
          {
            label: '用户姓名',
            prop: 'name',
            searchShow: false,
          },
          {
            label: '所属角色',
            prop: 'roleName',
            searchShow: false,
          },
          {
            label: '所属机构',
            prop: 'deptId',
            searchShow: true,
            editDisable: true,
            rowShow: false,
            editshow: false,
            type: 'tree',
            dictUrl: '/apollo-upms/dept/tree',
            rowSelect: true,
            props: {
              label: 'title',
              value: 'id',
              emitPath: false,
              checkStrictly: true,
            },
          },
          {
            label: '所属机构',
            prop: 'deptName',
            rowCls: 'badge badge-light-primary',
            searchShow: false,
          },
          // {
          //   label: '用户平台',
          //   prop: 'userTypeName',
          //   viewShow: true,
          //   searchShow: false,
          //   rowCls: 'badge badge-light-primary',
          // },
          // {
          //   label: '用户平台',
          //   prop: 'userType',
          //   rowShow: false,
          //   searchShow: true,
          //   viewShow: false,
          //   type: 'select',
          //   dictUrl: '/apollo-upms/dict/dictionary?code=user_type',
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          // },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    rowEdit(data) {
      let form = this._.cloneDeep(data)
      form.roleId = form.roleId.split(',')
      form.deptId = form.deptId.split(',')
      form.postId = form.postId.split(',')
      form.userType = form.userType.toString()
      this.formData = form
      this.isNew = false
      this.isView = false
      this.visibleFlag = true
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    toAdd() {
      this.isView = false
      this.isNew = true
      this.visibleFlag = true
    },
    rowView(data) {
      let form = this._.cloneDeep(data)
      form.roleId = form.roleId.split(',')
      form.deptId = form.deptId.split(',')
      form.postId = form.postId.split(',')
      form.userType = form.userType.toString()
      this.formData = form
      this.isView = true
      this.visibleFlag = true
    },
    editSuccess() {
      this.$refs.table.__searchHandle()
      this.visibleFlag = false
    },
    rowSelected(rows) {
      let ids = []
      rows.forEach(item => {
        ids.push(item)
      })
      this.rowSelects = ids
    },
    sideHidden() {
      this.visibleFlag = false
      this.isView = false
      this.isNew = false
      this.formData = {
        tenantId: undefined,
        account: undefined,
        userType: undefined,
        password: undefined,
        password2: undefined,
        name: undefined,
        realName: undefined,
        phone: undefined,
        email: undefined,
        sex: undefined,
        birthday: undefined,
      }
    },
    // 角色配置
    rowRole(data) {
      roleTree().then(resp => {
        this.roles = resp.data.data
      })
      this.user = data
      this.roleIds = data.roleId
      this.roleModalFlag = true
    },
    resetModal() {
      this.roleModalFlag = false
      this.user = undefined
      this.roleIds = undefined
    },
    handleOk() {
      if (this.roleIds.length > 0) {
        let ids = ''
        this.roleIds.forEach((item, index) => {
          if (index < this.roleIds.length - 1) {
            ids += item + ','
          } else {
            ids += item
          }
        })
        grant(this.user.id, ids).then(resp => {
          if (resp.data.success) {
            this.$xtoast.success('操作成功！')
            this.roleModalFlag = false
          }
        })
      } else {
        this.$xtoast.warning('请选择角色！')
      }
    },
    // 唤醒重置密码modal
    toSetPassword() {
      if (this.rowSelects.length === 0) {
        this.$xtoast.warning('请选择用户！')
      } else {
        this.passwordModalFlag = true
      }
    },
    resetModalPassword() {
      this.passwordModalFlag = false
    },
    handleOkPassword() {
      let ids = ''
      this.rowSelects.forEach((item, index) => {
        if (index < this.rowSelects.length - 1) {
          ids += item.id + ','
        } else {
          ids += item.id
        }
      })
      resetPassword(ids).then(resp => {
        if (resp.data.success) {
          this.$xtoast.success('操作成功！')
          this.resetModalPassword()
        }
      })
    },
    toUnlock() {
      if (this.rowSelects.length === 0) {
        this.$xtoast.warning('请选择用户！')
      } else {
        this.unlockModalFlag = true
      }
    },
    resetModalUnlock() {
      this.unlockModalFlag = false
    },
    handleOkUnlock() {
      let ids = ''
      this.rowSelects.forEach((item, index) => {
        if (index < this.rowSelects.length - 1) {
          ids += item.id + ','
        } else {
          ids += item.id
        }
      })
      unlock(ids).then(resp => {
        if (resp.data.success) {
          this.$xtoast.success('操作成功！')
          this.resetModalUnlock()
        }
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
