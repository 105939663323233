<template>
  <div>
    <x-el-table
      :permissions="{ add: 'param_add' }"
      :options="options"
      @beforeShow="beforeShow"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete">
    </x-el-table>
  </div>
</template>

<script>

import XElTable from '@core/components/cx/table/XElTable.vue'
import {
  getList, add, update, remove,
} from '@/api/system/param'

export default {
  name: 'Dept',
  components: {
    XElTable,
  },
  data() {
    return {
      options: {
        actions: [
          { action: 'view', tip: '查看', icon: 'EyeIcon', permission: 'param_view' },
          { action: 'edit', tip: '编辑', icon: 'EditIcon', permission: 'param_edit' },
          { action: 'delete', tip: '删除', icon: 'Trash2Icon', permission: 'param_delete' },
        ],
        columns: [
          {
            label: '参数名称',
            prop: 'paramName',
            searchShow: true,
            rowCls: 'x-text-bold',
            rules: {
              rule: 'required',
              message: '请输入参数名称',
            },
          },
          {
            label: '参数键名',
            prop: 'paramKey',
            searchShow: false,
            rowCls: 'badge badge-light-primary',
            rules: {
              rule: 'required',
              message: '请输入参数键名',
            },
          },
          {
            label: '参数键值',
            type: 'textarea',
            prop: 'paramValue',
            searchShow: true,
            rules: {
              rule: 'required',
              message: '请输入参数键值',
            },
          },
        ],
      },
    }
  },
  methods: {
    beforeShow(type, formData) {
      formData.deptCategory = formData.deptCategory.toString()
    },
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    addHandle(data, done) {
      add(data).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowEdit(data, done) {
      update(data).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
  },
}
</script>

<style scoped>

</style>
