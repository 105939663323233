<template>
  <e-charts
    v-if="line"
    ref="line"
    autoresize
    :options="line"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import theme from './theme.json'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  watch: {
    optionData: {
      immediate: true,
      handler(option) {
        if (option) {
          this.line = {
            // Make gradient line here
            visualMap: [{
              show: true,
              type: 'continuous',
              min: 0,
              max: 1500,
            }],
            legend: option.legend,
            grid: {
              left: '60px',
              top: option.legend ? '30px' : '20px',
              right: '50px',
              bottom: '30px',
              show: false,
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985',
                },
              },
            },
            xAxis: [{
              splitLine: {
                show: false,
              },
              boundaryGap: false,
              data: option.xAxisData,
              smooth: true,
            }],
            yAxis: {
              type: 'value',
              splitLine: { show: true },
              axisLine: { show: false },
            },
            series: option.series || {
              type: 'line',
              showSymbol: false,
              lineStyle: {
                width: 3,
                shadowBlur: 10,
                shadowColor: 'rgba(115,103,240, 1)',
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
              // areaStyle: {
              //   color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
              //     {
              //       offset: 0,
              //       color: 'rgba(58,77,233,0.5)',
              //     },
              //     {
              //       offset: 1,
              //       color: 'rgba(58,77,233,0.1)',
              //     },
              //   ]),
              // },
              data: option.yAxisData,
              smooth: option.smooth ? option.smooth : true,
            },
          }
        }
      },
    },
  },
  data() {
    return {
      line: undefined,
    }
  },
}
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>
