import request from '@/auth/jwt/useJwt'
import website from '@/config/website'
import { Base64 } from 'js-base64'
import { getToken } from '@core/auth/token'

// 获取个人信息
export function detail() {
  return request.axiosIns({
    url: '/apollo-ucenter/info',
    method: 'get',
  })
}

// 更新个人信息
export function submit(data) {
  return request.axiosIns({
    url: '/apollo-ucenter/update-info',
    method: 'POST',
    data,
  })
}
function getHeaders() {
  let hd = {
    Authorization: `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`,
  }
  hd[website.tokenHeader] = getToken()
  return hd
}

export const Headers = getHeaders()
