import request from '@/auth/jwt/useJwt'

export const queryDashboard = () => request.axiosIns({
  url: '/apollo-jxz/dashboard',
  method: 'get',
})

export const reStatisticsDashboard = data => request.axiosIns({
  url: '/apollo-jxz/dashboard/re-statistics',
  method: 'post',
  data,
})
