<template>
  <div>
    <div class="card card-body">
      <div class="d-flex align-items-center">
        <b-img height="30" :src="require('@/assets/jxz/mt-trend.svg')"/>
        <span class="text-secondary ml-50">商户增长趋势</span>
      </div>
      <echart-line class="chart" :optionData="optionData"></echart-line>
    </div>
  </div>
</template>

<script>

import { BImg } from 'bootstrap-vue'
import EchartLine from '@/@core/components/charts/echart/AppEchartLine.vue'

export default {
  components: {
    BImg,
    EchartLine,
  },
  props: {
    dashboard: {
      type: Object,
      default: undefined,
    },
  },
  watch: {
    dashboard: {
      handler() {
        if (this.dashboard && this.dashboard.trend) {
          this.optionData = {
            xAxisData: this.$x.utils.Date.getLastWeek(),
            yAxisData: this.dashboard.trend.mts,
          }
        }
      },
    },
  },
  data() {
    return {
      optionData: {
        xAxisData: this.$x.utils.Date.getLastWeek(),
        yAxisData: [0, 0, 0, 0, 0, 0, 0],
      },
    }
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
  .chart {
    height: 200px;
  }
</style>
