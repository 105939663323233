<template>
  <div>
    <x-el-table
      ref="table"
      :permissions="{ add: 'post_add' }"
      :options="options"
      @beforeShow="beforeShow"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete">
      <template slot="postName" slot-scope="scope">
        <div class="text-primary x-text-normal x-link d-inline" @click="() => $refs.table.actionClick(options.actions[0],scope)">{{ scope.row.postName }}</div>
      </template>
    </x-el-table>
  </div>
</template>

<script>

import XElTable from '@core/components/cx/table/XElTable.vue'
import {
  getList, add, update, remove,
} from '@/api/system/post'

export default {
  name: 'Post',
  components: {
    XElTable,
  },
  data() {
    return {
      options: {
        actions: [
          { action: 'view', tip: '查看', icon: 'EyeIcon', permission: 'post_view' },
          { action: 'edit', tip: '编辑', icon: 'EditIcon', permission: 'post_edit' },
          { action: 'delete', tip: '删除', icon: 'Trash2Icon', permission: 'post_delete' },
        ],
        columns: [
          {
            label: '岗位名称',
            prop: 'postName',
            searchShow: true,
            rules: {
              rule: 'required',
              message: '请输入岗位名称',
            },
          },
          {
            label: '岗位编号',
            prop: 'postCode',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入岗位编号',
            },
          },
          {
            label: '岗位类型',
            prop: 'category',
            searchShow: true,
            type: 'select',
            dictUrl: '/apollo-upms/dict/dictionary?code=post_category',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            rules: {
              rule: 'required',
              message: '请选择岗位类型',
            },
          },
          {
            label: '岗位排序',
            type: 'number',
            prop: 'sort',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入排序',
            },
          },
          {
            label: '岗位描述',
            type: 'textarea',
            prop: 'remark',
            searchShow: false,
          },
        ],
      },
    }
  },
  methods: {
    beforeShow(type, formData) {
      formData.category = formData.category.toString()
    },
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    addHandle(data, done) {
      add(data).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowEdit(data, done) {
      update(data).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
  },
}
</script>

<style scoped>

</style>
