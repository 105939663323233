<template>
  <div>
    <x-el-table
      no-page
      :permissions="{ add: 'menu_add' }"
      :lazy="false"
      :options="options"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete"
      @lazyLoad="lazyLoad">
    </x-el-table>
  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import {
  getList, getLazyMenuList, add, update, remove,
} from '@/api/system/menu'

export default {
  name: 'Menu',
  components: {
    XElTable,
  },
  data() {
    return {
      show: false,
      options: {
        hideStyleSwitch: true,
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon', permission: 'menu_view' },
          { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon', permission: 'menu_edit' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon', permission: 'menu_delete' },
        ],
        columns: [
          {
            label: '菜单名称',
            prop: 'name',
            rules: {
              rule: 'required',
              message: '请输入菜单名称',
            },
          },
          {
            label: '路由地址',
            prop: 'path',
          },
          {
            label: '上级菜单',
            prop: 'parentId',
            type: 'tree',
            dictUrl: '/apollo-upms/menu/tree',
            rowShow: false,
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
            },
          },
          {
            label: '菜单图标',
            prop: 'source',
            searchShow: false,
            type: 'icons',
          },
          {
            label: '菜单编号',
            prop: 'code',
            search: true,
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入菜单编号',
            },
          },
          {
            label: '菜单类型',
            prop: 'category',
            type: 'radio',
            rowSelect: true,
            searchShow: false,
            dictData: [
              {
                label: '菜单',
                value: '1',
              },
              {
                label: '按钮',
                value: '2',
              },
            ],
            rules: {
              rule: 'required',
              message: '请选择菜单类型',
            },
          },
          {
            label: '菜单别名',
            prop: 'alias',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请选择菜单别名',
            },
          },
          {
            label: '是否隐藏',
            prop: 'isOpen',
            type: 'radio',
            rowSelect: true,
            searchShow: false,
            dictData: [
              {
                label: '否',
                value: '1',
              },
              {
                label: '是',
                value: '2',
              },
            ],
            rules: {
              rule: 'required',
              message: '请选择新窗口打开',
            },
          },
          {
            label: '菜单排序',
            prop: 'sort',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入菜单排序',
            },
          },
          {
            label: '菜单备注',
            prop: 'remark',
            type: 'textarea',
            searchShow: false,
            rowShow: false,
          },
        ],
      },
      permProps: {
        label: 'title',
        value: 'key',
      },
    }
  },
  methods: {
    searchHandle(page, params, done) {
      if (params && params.parentId) {
        params.parentId = params.parentId.join(',')
      }
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data, resp.data.data.length)
      })
    },
    lazyLoad(row, treeNode, resolve) {
      const parentId = row.id
      getLazyMenuList(parentId).then(res => {
        resolve(res.data.data)
      })
    },
    addHandle(data, done) {
      if (data.parentId && data.parentId.length > 0) {
        data.parentId = data.parentId[data.parentId.length - 1]
      } else {
        data.parentId = ''
      }
      add(data).then(() => {
        done()
      })
    },
    rowEdit(data, done) {
      if (data.parentId && (typeof (data.parentId) === 'object') && data.parentId.length > 0) {
        data.parentId = data.parentId[data.parentId.length - 1]
      }
      if (data.parentId && data.parentId.length === 0) {
        data.parentId = '0'
      }
      update(data).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
  },
}
</script>

<style scoped>

</style>
