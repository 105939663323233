<template>
  <div>
    <x-el-table
      ref="table"
      :options="options"
      @search="searchHandle">
      <template slot="name" slot-scope="scope">
        <div class="text-primary x-text-normal x-link x-text-bold" @click="() => $refs.table.actionClick(options.actions[0],scope)">{{ scope.row.name }}</div>
      </template>
      <template slot="mtId" slot-scope="scope">
        <b-avatar v-viewer :src="scope.row.mtUser.avatar" variant="light-primary"></b-avatar>
        <span class="ml-1 text-primary x-link x-text-normal" @click="$router.push(`/data/merchant/profile?id=${scope.row.mtUser.id}`)">{{ scope.row.mtUser.nickName }}</span>
      </template>
      <template slot="distributionState" slot-scope="scope">
        <b-badge variant="light-warning" v-if="scope.row.distributionState === false">不分账</b-badge>
        <b-badge variant="light-success" v-if="scope.row.distributionState === true">分账</b-badge>
      </template>
      <template slot="adminList" slot-scope="scope">
        <div v-if="scope.row.adminList.length > 0">
          <div v-for="admin in scope.row.adminList" :key="admin.id">
            <span class="x-text-bold text-primary "> {{admin.profile.nickName}}</span>
          </div>
        </div>
        <div v-else>
        <b-badge variant="light-warning">暂未设置</b-badge>
        </div>
      </template>
      <template slot="disPercent" slot-scope="scope">
        <b-badge variant="light-primary"  v-if="scope.row.distributionState === false">无</b-badge>
        <b-badge variant="light-primary" v-else>{{scope.row.disPercent}}%</b-badge>
      </template>
      <template slot="address" slot-scope="scope">
        <div class="x-text-cut">
          {{scope.row.address}}
        </div>
      </template>
    </x-el-table>
  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import { BAvatar, BBadge } from 'bootstrap-vue'
import {
  getList, getAdminList,
} from '@/api/data/shop'

export default {
  name: 'Shop',
  components: {
    XElTable,
    BAvatar,
    BBadge,
  },
  props: {
    queryMtId: {
      type: String,
      default: undefined,
    },
    adminShop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: {
        addBtn: false,
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon', url: '/data/shop/profile?id={id}' },
        ],
        columns: [
          {
            label: '名称',
            prop: 'name',
          },
          {
            label: '所属商户',
            prop: 'mtId',
            type: 'select',
            rowSelect: true,
            searchShow: !this.queryMtId,
            dictUrl: '/apollo-jxz/gm/merchant/all',
            props: {
              label: 'nickName',
              value: 'id',
            },
          },
          {
            label: '是否分账',
            prop: 'distributionState',
            searchShow: false,
          },
          {
            label: '分账比例(%)',
            prop: 'disPercent',
            searchShow: false,
          },
          {
            label: '管理员',
            prop: 'adminList',
            searchShow: false,
          },
          {
            label: '地址',
            prop: 'address',
            searchShow: false,
          },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      if (this.queryMtId) {
        params.mtId = this.queryMtId
      }
      if (this.adminShop) {
        getAdminList(page.pageIndex, page.pageSize, params).then(resp => {
          done(resp.data.data.records, resp.data.data.total)
        })
      } else {
        getList(page.pageIndex, page.pageSize, params).then(resp => {
          done(resp.data.data.records, resp.data.data.total)
        })
      }
    },
  },
}
</script>

<style lang="scss">
</style>
