<template>
  <div>
    <x-el-table
      ref="table"
      id="table"
      :options="options"
      @search="searchHandle"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete">
      <template slot="actions">
        <div
          v-ripple.400="$x.ripple.primary"
          class="btn btn-primary"
          @click="toAdd">
          <feather-icon
            size="16"
            icon="PlusIcon"
            class="mr-50"
          />生成兑换码
        </div>
        <div
          v-ripple.400="$x.ripple.success"
          class="btn btn-success ml-50"
          @click="dataExport">
          <feather-icon
            size="16"
            icon="DownloadIcon"
            class="mr-50"
          />导出
        </div>
        <div
          v-ripple.400="$x.ripple.danger"
          class="btn btn-danger ml-50"
          @click="dataTrash">
          <feather-icon
            size="16"
            icon="TrashIcon"
            class="mr-50"
          />销毁
        </div>
      </template>
      <template slot="validity" slot-scope="scope">
        <b-badge variant="light-success">{{scope.row.validity}}个月</b-badge>
      </template>
    </x-el-table>
    <b-modal
      cancel-variant="flat-secondary"
      ok-title="确定"
      cancel-title="取消"
      centered
      title="批量生成兑换码"
      :visible="visible"
      @hidden="saveHide"
      @ok="saveBatch">
      <b-overlay
              :show="loading"
              rounded="sm"
              variant="light"
              opacity="0.85"
              blur="2px"
              spinnerVariant="primary">
              <template #overlay>
                <div class="card p-1 cx-shadow">
                  <div class="text-center d-flex align-items-center">
                    <b-spinner variant="primary"></b-spinner>
                    <span class="x-fs-5 x-ml-10px x-text-bold">正在生成中...</span>
                  </div>
                </div>
              </template>
              <x-form-validator ref="refFormObserver" class="card card-body x-no-shadow">
                      <x-form-feild label="设置生成数量" class="pb-1" require>
                        <b-form-input v-model="cdKeyBatch.qty" type="range" min="1" max="200" step="1" placeholder="设置生成数量"></b-form-input>
                        <b-form-spinbutton  v-model="cdKeyBatch.qty" min="1" max="200" step="1" placeholder="设置生成数量"></b-form-spinbutton>
                      </x-form-feild>
                      <x-form-feild label="所属商户">
                        <v-select
                          v-model="cdKeyBatch.mtId"
                          :options="mtUsers"
                          label="nickName"
                          placeholder="请选择所属商户"
                          :reduce=" option => option.id"
                        >
                          <template slot="no-options">
                            {{ $t('noData') }}
                          </template>
                        </v-select>
                      </x-form-feild>
                    </x-form-validator>
          </b-overlay>
    </b-modal>
    <b-modal
          cancel-variant="flat-secondary"
          ok-title="确定"
          cancel-title="取消"
          centered
          title="兑换码销毁"
          :visible="trashVisible"
          @hidden="trashHide"
          @ok="toTrash">
          <b-overlay
                  :show="trashLoading"
                  rounded="sm"
                  variant="light"
                  opacity="0.85"
                  blur="2px"
                  spinnerVariant="primary">
                  <template #overlay>
                    <div class="card p-1 cx-shadow">
                      <div class="text-center d-flex align-items-center">
                        <b-spinner variant="primary"></b-spinner>
                        <span class="x-fs-5 x-ml-10px x-text-bold">正在处理中...</span>
                      </div>
                    </div>
                  </template>
                  <x-form-validator ref="trashFormObserver" class="card card-body x-no-shadow">
                    <x-form-feild label="兑换码所属商户" require>
                      <v-select
                        v-model="trashMtId"
                        :options="mtUsers"
                        label="nickName"
                        placeholder="请选择所属商户"
                        :reduce=" option => option.id"
                      >
                        <template slot="no-options">
                          {{ $t('noData') }}
                        </template>
                      </v-select>
                    </x-form-feild>
                  </x-form-validator>
              </b-overlay>
        </b-modal>
  </div>
</template>

<script>
import { BModal, BFormInput, BFormSpinbutton, BBadge, BOverlay, BSpinner } from 'bootstrap-vue'
import vSelect from 'vue-select'
import XElTable from '@core/components/cx/table/XElTable.vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import * as XLSX from 'xlsx'
import * as XLSXStyle from 'xlsx-style'
import { saveAs } from 'file-saver'
import {
  getList, edit, remove, getMtUsers, saveBatch, destroy,
} from '@/api/marketing/cdKey'

export default {
  name: 'CDKey',
  components: {
    XElTable,
    BModal,
    XFormValidator,
    XFormFeild,
    BFormInput,
    BFormSpinbutton,
    vSelect,
    BBadge,
    BOverlay,
    BSpinner,
  },
  data() {
    return {
      loading: false,
      trashLoading: false,
      trashMtId: undefined,
      trashVisible: false,
      mtUsers: [],
      visible: false,
      cdKeyBatch: {
        qty: 1,
        mtId: undefined,
      },
      options: {
        addBtn: false,
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon' },
          { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon' },
        ],
        actionFilter: (action, row) => {
          if (row.state === 0) {
            return action === this.$x.biz.FormType.VIEW || action === this.$x.biz.FormType.DELETE || action === this.$x.biz.FormType.EDIT
          }
          return action === this.$x.biz.FormType.VIEW || action === this.$x.biz.FormType.EDIT
        },
        columns: [
          {
            label: '兑换码',
            prop: 'code',
            searchShow: false,
            editShow: false,
            rules: { rule: 'required', message: '请输入兑换码' },
          },
          {
            label: '有效期',
            prop: 'validity',
            searchShow: false,
            editShow: false,
          },
          {
            label: '商户',
            prop: 'mtId',
            type: 'select',
            rowSelect: true,
            searchShow: true,
            dictUrl: '/apollo-jxz/gm/merchant/all',
            props: {
              label: 'nickName',
              value: 'id',
            },
            selectVariant: propValue => {
              if (propValue === -1) {
                return 'badge badge-light-secondary'
              }
              return 'badge badge-light-primary'
            },
            getDictLabel: option => {
              if (typeof option === 'object') {
                return option.label ? option.label : '无'
              }
              return option
            },
          },
          {
            label: '创建时间',
            prop: 'createTime',
            searchShow: false,
            editShow: false,
          },
          {
            label: '兑换时间',
            prop: 'updateTime',
            searchShow: false,
            editShow: false,
          },
          {
            label: '状态',
            prop: 'state',
            type: 'select',
            rowSelectDot: true,
            selectVariant: value => value === 0 ? 'text-info' : 'text-success',
            addShow: false,
            editShow: false,
            dictData: [{ label: '未使用', value: 0 }, { label: '已兑换', value: 1 }],
          },
        ],
      },
    }
  },
  created() {
    getMtUsers().then(resp => {
      this.mtUsers = resp.data.data
    })
  },
  methods: {
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    rowEdit(data, done) {
      edit(data).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    saveHide() {
      this.cdKeyBatch = {
        qty: 1,
        mtId: undefined,
      }
      this.visible = false
      this.$refs.table.__searchHandle()
    },
    trashHide() {
      this.trashMtId = undefined
      this.trashVisible = false
      this.$refs.table.__searchHandle()
    },
    dataTrash() {
      this.trashVisible = true
    },
    toTrash(bvModalEvt) {
      bvModalEvt.preventDefault()
      return this.$xmbox.confirm('确定要执行销毁操作吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'btn btn-outline-secondary',
        confirmButtonClass: 'btn btn-primary',
        type: 'warning',
      }).then(() => {
          //确认操作
          this.$refs.trashFormObserver.validate().then(success => {
            if (success) {
              this.trashLoading = true
              destroy(this.trashMtId).then(resp => {
                this.trashLoading = false
                if (resp.data.code === 200) {
                  this.$xtoast.success('销毁成功！')
                  this.trashHide()
                } else {
                this.$xtoast.warning(resp.data.msg)
                }
              })
            }
          })
      }).catch((err) => {
          //捕获异常
          console.log(err);
      });
    },
    saveBatch(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.loading = true
          saveBatch(this.cdKeyBatch).then(resp => {
            this.loading = false
            if (resp.data.code === 200) {
              this.$xtoast.success('批量生成成功！')
              this.saveHide()
            }
          })
        }
      })
    },
    toAdd() {
      this.visible = true
    },
    // 导出
    dataExport() {
      this.exportToExcel('#table', 'cdKey导出文件');
    },
    s2ab(s) {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    },
    // 根据dom导出表格
    exportToExcel(idSelector, fileName, titleNum = 1) {
      // 设置导出的内容是否只做解析，不进行格式转换     false：要解析， true:不解析
      const xlsxParam = { raw: true }
      let table = document.querySelector(idSelector).cloneNode(true);

      // 因为element-ui的表格的fixed属性导致多出一个table，会下载重复内容，这里删除掉
      if (table.querySelector('.el-table__fixed-right'))
        table.removeChild(table.querySelector('.el-table__fixed-right'));
      if (table.querySelector('.el-table__fixed'))
        table.removeChild(table.querySelector('.el-table__fixed'));

      const wb = XLSX.utils.table_to_book(table, xlsxParam)
      let range = XLSX.utils.decode_range(wb.Sheets['Sheet1']['!ref']);
      let cWidth = [];
      for (let C = range.s.c; C < range.e.c; ++C) {   //SHEET列
        let len = 100; //默认列宽
        let len_max = 400; //最大列宽
        for (let R = range.s.r; R <= range.e.r; ++R) {  //SHEET行
          let cell = { c: C, r: R };                    //二维 列行确定一个单元格
          let cell_ref = XLSX.utils.encode_cell(cell);  //单元格 A1、A2
          if (wb.Sheets['Sheet1'][cell_ref]) {
            // if (R == 0){
            if (R < titleNum) {
              wb.Sheets['Sheet1'][cell_ref].s = {  //设置第一行单元格的样式 style
                alignment: {
                  horizontal: 'center',
                  vertical: 'center',
                },
              };
            } else {
              wb.Sheets['Sheet1'][cell_ref].s = {
                alignment: {
                  horizontal: 'center',
                  vertical: 'center',
                },
              };
            }
            //动态自适应：计算列宽
            let va = JSON.parse(JSON.stringify(wb.Sheets['Sheet1'][cell_ref].v));
            var card1 = JSON.parse(JSON.stringify(va)).match(/[\u4e00-\u9fa5]/g); //匹配中文
            var card11 = "";
            if (card1) {
              card11 = card1.join("")
            }
            var card2 = JSON.parse(JSON.stringify(va)).replace(/([^\u0000-\u00FF])/g, "");  //剔除中文
            let st = 0;
            if (card11) {
              // st += card11.length * 16  //中文字节码长度
              st += card11.length * 20  //中文字节码长度
            }
            if (card2) {
              // st += card2.length * 8  //非中文字节码长度
              st += card2.length * 10  //非中文字节码长度
            }
            if (st > len) {
              len = st;
            }
          }
        }
        if (len > len_max) {//最大宽度
          len = len_max;
        }

        cWidth.push({ 'wpx': len });     //列宽
      }
      wb.Sheets['Sheet1']['!cols'] = cWidth
      const wbout = XLSXStyle.write(wb, { bookType: 'xlsx', bookSST: false, type: 'binary' })
      try {
        saveAs(new Blob([this.s2ab(wbout)], { type: '' }), `${fileName}.xlsx`)
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout)
        }
      }
      return wbout
    }
  },
}
</script>

<style lang="scss">
  .table-item-img {
    width: 200px;
    height: 60px;
  }
</style>
