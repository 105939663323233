<template>
  <div>
    <x-el-table
      ref="table"
      :options="options"
      @search="searchHandle">
      <template slot="amount" slot-scope="scope">
        <span :class="scope.row.type === 4 ? 'text-danger' : 'text-success'" class="x-text-bold font-medium-2">
          <span v-if="scope.row.type === 1">
            {{'+ ' + (scope.row.originAmount > 0 ? scope.row.originAmount : '0') }}
          </span>
          <span v-else>
            {{((scope.row.type === 4 || scope.row.type === 7)? '- ' : '+ ') + (scope.row.amount > 0 ? scope.row.amount : '0') }}
          </span>
        </span>
        <span class="text-secondary font-small-4 x-text-normal"> /元 </span>
      </template>
      <template slot="origin" slot-scope="scope">
          <b-avatar v-viewer :src="scope.row.avatar" variant="light-primary"></b-avatar>
          <span class="ml-1 text-primary x-link x-text-normal" @click="toProfile(scope.row)">{{ scope.row.nickName }}</span>
      </template>
      <template slot="type" slot-scope="scope">
        <div :class="getTypeVariant(scope.row)">
          {{ getTypeLabel(scope.row) }}
        </div>
      </template>
      <template slot="state" slot-scope="scope">
        <div class="d-flex flex-row align-items-center x-text-cut">
          <span class="font-medium-3 mr-50 animate__animated animate__infinite animate__pulse" :class="getStateVariant(scope.row)">●</span>
          {{ getStateLabel(scope.row) }}
        </div>
      </template>
    </x-el-table>
  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import { BAvatar } from 'bootstrap-vue'
import {
  getList,
} from '@/api/data/bill'

export default {
  name: 'Bill',
  components: {
    XElTable,
    BAvatar,
  },
  props: {
    queryOrigin: {
      type: String,
      default: undefined,
    },
    queryMtId: {
      type: String,
      default: undefined,
    },
    queryType: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      options: {
        hideStyleSwitch: true,
        addBtn: false,
        actions: [
        ],
        columns: [
          {
            label: '流水号',
            prop: 'id',
            searchShow: false,
          },
          {
            label: '支付流水号',
            prop: 'orderId',
            searchShow: true,
          },
          {
            label: '商户',
            prop: 'mtId',
            type: 'select',
            // rowSelect: true,
            searchShow: !this.queryMtId,
            rowCls: 'x-text-normal',
            linkUrl: '/data/merchant/profile?id={mtId}',
            dictUrl: '/apollo-jxz/gm/merchant/all',
            props: {
              label: 'nickName',
              value: 'id',
            },
            selectVariant: () => {
              return 'badge badge-light-primary'
            },
          },
          {
            label: '来源',
            prop: 'origin',
            searchShow: false,
          },
          {
            label: '类型',
            prop: 'type',
            searchShow: !this.queryType,
            type: 'select',
            rowSelect: true,
            dictUrl: '/apollo-upms/dict-biz/dictionary?code=billType',
            // dictData: [{ value: 1, label: '会员充值' }, { value: 2, label: '软件分销' }, { value: 3, label: '运营分销' }, { value: 4, label: '提现' }, { value: 7, label: '技术服务费' }, { value: 8, label: '店铺运营' }],
            selectVariant: propValue => {
              if (propValue === 4) {
                return 'badge badge-light-danger'
              }
              if (propValue === 1) {
                return 'badge badge-light-success'
              }
              if (propValue === 7) {
                return 'badge badge-light-info'
              }
              return 'badge badge-light-primary'
            },
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
          },
          {
            label: '金额',
            prop: 'amount',
            searchShow: true,
          },
          {
            label: '状态',
            prop: 'state',
            searchShow: true,
            type: 'select',
            dictUrl: '/apollo-upms/dict-biz/dictionary?code=withdrawalState',
            rowSelectDot: true,
            selectVariant: value => {
              if (value === 1) {
                return 'text-warning'
              }
              if (value === 2) {
                return 'text-success'
              }
              if (value === 3) {
                return 'text-danger'
              }
              return 'text-success'
            },
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
          },
          {
            label: '时间',
            prop: 'createTime',
            searchShow: false,
          },
          {
            label: '时间开始',
            type: 'datetime',
            prop: 'startTime',
            rowShow: false,
            searchShow: true,
          },
          {
            label: '时间结束',
            type: 'datetime',
            prop: 'endTime',
            rowShow: false,
            searchShow: true,
          },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      if (this.queryOrigin) {
        params.origin = this.queryOrigin
      }
      if (this.queryMtId) {
        params.mtId = this.queryMtId
      }
      if (this.queryType) {
        params.type = this.queryType
      }
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    getTypeVariant(row) {
      if (row.type === 4) {
        return 'badge badge-light-danger'
      }
      if (row.type === 1) {
        return 'badge badge-light-success'
      }
      if (row.type === 7) {
        return 'badge badge-light-info'
      }
      return 'badge badge-light-primary'
    },
    getTypeLabel(row) {
      switch (row.type) {
        case 1:
          return '会员充值' + (row.state === 3 ? ' - 包夜' : '')
        case 2:
          return '营业分销'
        case 3:
          return '软件分销'
        case 4:
          return '提现'
        case 5:
          return '软件销售 - 平台'
        case 6:
          return '营业分销 - 平台'
        case 7:
          return '技术服务费'
        case 8:
          return '店铺运营'
        case 9:
          return '其他 - 人脸核身'
        default:
          return ''
      }
    },
    getStateVariant(row) {
      // type为 4 是提现
      if (row.type === 4 && row.state === 1) {
        return 'text-warning'
      }
      if (row.type === 4 && row.state === 3) {
        return 'text-danger'
      }
      return 'text-success'
    },
    getStateLabel(row) {
      // type为 4 是提现
      if (row.type === 4 && row.state === 1) {
        return '处理中'
      }
      if (row.type === 4 && row.state === 3) {
        return '已驳回'
      }
      if (row.type === 4 && row.state === 2) {
        return '已到账'
      }
      return '已完成'
    },
    toProfile(row) {
      this.$router.push(row.type === 1 ? `/data/member/profile?id=${row.origin}` : `/data/merchant/profile?id=${row.origin}`)
    },
  },
}
</script>

<style lang="scss">
</style>
