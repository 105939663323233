import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-upms/region/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const getLazyTree = (parentCode, params) => request.axiosIns({
  url: '/apollo-upms/region/lazy-tree',
  method: 'get',
  params: {
    ...params,
    parentCode,
  },
})

export const getDetail = code => request.axiosIns({
  url: '/apollo-upms/region/detail',
  method: 'get',
  params: {
    code,
  },
})

export const remove = id => request.axiosIns({
  url: '/apollo-upms/region/remove',
  method: 'post',
  params: {
    id,
  },
})

export const submit = row => request.axiosIns({
  url: '/apollo-upms/region/submit',
  method: 'post',
  data: row,
})
