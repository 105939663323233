<template>
  <div class="card card-body">
    <b-overlay
      :show="showLoading"
      rounded="sm"
      variant="light"
      opacity="0.85"
      blur="2px"
      spinnerVariant="primary">
      <b-tabs v-model="current" align="center">
        <b-tab>
          <template #title>
            <span class="x-text-bold font-medium-2 ml-2 mr-2">个人版</span>
          </template>
          <div v-if="personalPackage" class="row d-flex justify-content-center pt-5">
            <x-form-validator ref="personalFormObserver" class="col-md-6">
              <x-form-feild label="套餐价格（每台）" class="pb-1" require>
                <b-form-input v-model="personalPackage.price" type="number" placeholder="请输入套餐价格"></b-form-input>
              </x-form-feild>
              <x-form-feild label="套餐有效期（月）" class="pb-1" require>
                <b-form-input v-model="personalPackage.validity" type="range" min="1" max="36" step="1" placeholder="请选择套餐有效期"></b-form-input>
                <b-form-spinbutton v-model="personalPackage.validity" min="1" max="36" step="1" placeholder="请选择套餐有效期"></b-form-spinbutton>
              </x-form-feild>
              <x-form-feild label="套餐说明" class="pb-1">
                <b-form-textarea v-model="personalPackage.description" placeholder="请输入套餐说明"></b-form-textarea>
              </x-form-feild>
            </x-form-validator>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="x-text-bold font-medium-2 ml-2 mr-2">商业版</span>
          </template>
          <div class="row d-flex justify-content-center pt-5">
            <x-form-validator ref="commercialFormObserver" class="col-md-6">
              <x-form-feild label="套餐价格（每台）" class="pb-1" require>
                <b-form-input v-model="commercialPackage.price" type="number" placeholder="请输入套餐价格"></b-form-input>
              </x-form-feild>
              <x-form-feild label="套餐有效期（月）" class="pb-1" require>
                <b-form-input v-model="commercialPackage.validity" type="range" min="1" max="36" step="1" placeholder="请选择套餐有效期"></b-form-input>
                <b-form-spinbutton v-model="commercialPackage.validity" min="1" max="36" step="1" placeholder="请选择套餐有效期"></b-form-spinbutton>
              </x-form-feild>
              <x-form-feild label="套餐说明" class="pb-1">
                <b-form-textarea v-model="commercialPackage.description" placeholder="请输入套餐说明"></b-form-textarea>
              </x-form-feild>
              <x-form-feild label="新人满减" class="pb-1">
                <x-form-repeater
                  ref="reductionRepeater"
                  class="w-100"
                  :data="commercialPackage.reductionRules"
                  :itemAdd="reductionRuleAdd"
                  :noAnimation="true"
                  no-actions
                  isFirst>
                  <slot slot="form-repeater" slot-scope="scope">
                    <div class="row col-12 d-flex align-items-center justify-content-between">
                      <span class="x-text-bold text-danger">满</span>
                      <b-form-input v-model="scope.item.min" class="col-md-2" type="number"></b-form-input>
                      <span class="x-text-bold">(元)</span>
                      <span class="x-text-bold ml-2 text-success">减</span>
                      <b-form-input v-model="scope.item.reduceAmount" class="col-md-2" type="number"></b-form-input>
                      <span class="x-text-bold ml-1">(元)</span>
                      <div v-ripple.400="$x.ripple.danger" class="btn btn-light-danger rounded-pill" @click="removeReductionRule(scope.index)">删除</div>
                    </div>
                  </slot>
                </x-form-repeater>
              </x-form-feild>
              <x-form-feild label="折扣规则" class="pb-1">
                <x-form-repeater
                  ref="repeater"
                  class="w-100"
                  :data="commercialPackage.discountRules"
                  :itemAdd="discountRuleAdd"
                  :noAnimation="true"
                  no-actions
                  isFirst>
                  <slot slot="form-repeater" slot-scope="scope">
                    <div class="row col-12 d-flex align-items-center justify-content-between">
                      <span class="x-text-bold">购买</span>
                      <b-form-spinbutton v-model="scope.item.min" class="col-md-2" min="1" max="100" step="1"></b-form-spinbutton>
                      <span class="x-text-bold">台</span>
                      <span class="x-text-bold ml-1">～</span>
                      <b-form-spinbutton v-model="scope.item.max" class="col-md-2" min="1" max="100" step="1"></b-form-spinbutton>
                      <span class="x-text-bold">台</span>
                      <span class="x-text-bold ml-2">享</span>
                      <b-form-spinbutton v-model="scope.item.discount" class="col-md-2" min="1" max="100" step="1"></b-form-spinbutton>
                      <span class="x-text-bold ml-1">折</span>
                      <div v-ripple.400="$x.ripple.danger" class="btn btn-light-danger rounded-pill" @click="removeDiscountRule(scope.index)">删除</div>
                    </div>
                  </slot>
                </x-form-repeater>
              </x-form-feild>
            </x-form-validator>
          </div>
        </b-tab>
      </b-tabs>
      <div class="d-flex justify-content-center">
        <div class="btn btn-light-secondary" @click="cancel">取消</div>
        <div class="btn btn-primary ml-2" @click="setup">确定</div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay, BTabs, BTab, BFormInput, BFormTextarea, BFormSpinbutton,
} from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import XFormRepeater from '@/@core/components/cx/table/XFormRepeater.vue'
import {
  getALL, setupPersonalPackage, setupCommercialPackage,
} from '@/api/marketing/package'

export default {
  components: {
    BOverlay,
    BTabs,
    BTab,
    XFormValidator,
    XFormFeild,
    BFormInput,
    BFormTextarea,
    BFormSpinbutton,
    XFormRepeater,
  },
  data() {
    return {
      showLoading: false,
      current: 0,
      lastPersonalPackage: {
        price: undefined,
        validity: 12,
        description: undefined,
      },
      lastCommercialPackage: {
        price: undefined,
        validity: 12,
        description: undefined,
        discountRules: [],
        reductionRules: [],
      },
      personalPackage: undefined,
      commercialPackage: undefined,
    }
  },
  created() {
    this.personalPackage = this._.cloneDeep(this.lastPersonalPackage)
    this.commercialPackage = this._.cloneDeep(this.lastCommercialPackage)
    this.search()
  },
  methods: {
    search() {
      this.showLoading = true
      getALL().then(resp => {
        let packages = resp.data.data || []
        packages.forEach(item => {
          if (item.discountRules === '') {
            item.discountRules = []
          } else {
            item.discountRules = JSON.parse(item.discountRules)
          }
          if (item.reductionRules === '') {
            item.reductionRules = []
          } else {
            item.reductionRules = JSON.parse(item.reductionRules)
          }
          // 个人版
          if (item.type === 1) {
            this.lastPersonalPackage = item
          } else {
            this.lastCommercialPackage = item
          }
        })
        this.personalPackage = this._.cloneDeep(this.lastPersonalPackage)
        this.commercialPackage = this._.cloneDeep(this.lastCommercialPackage)
        this.showLoading = false
      })
    },
    cancel() {
      if (this.current === 0) {
        this.personalPackage = this.lastPersonalPackage
      } else {
        this.commercialPackage = this.lastCommercialPackage
      }
    },
    discountRuleAdd() {
      return {
        min: undefined,
        max: undefined,
        discount: 100,
      }
    },
    reductionRuleAdd() {
      return {
        min: undefined,
        reduceAmount: undefined,
      }
    },
    removeDiscountRule(index) {
      this.$refs.repeater.removeItem(index)
    },
    removeReductionRule(index) {
      this.$refs.reductionRepeater.removeItem(index)
    },
    setup() {
      if (this.current === 0) {
        this.$refs.personalFormObserver.validate().then(result => {
          if (result) {
            setupPersonalPackage(this.personalPackage).then(() => {
              this.$xtoast.success('设置成功！')
              this.search()
            })
          }
        })
        return
      }
      this.$refs.commercialFormObserver.validate().then(result => {
        if (result) {
          setupCommercialPackage(this.commercialPackage).then(() => {
            this.$xtoast.success('设置成功！')
            this.search()
          })
        }
      })
    },
  },
}
</script>
