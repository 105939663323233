<template>
  <div>
    <x-el-table
      ref="table"
      :permissions="{ add: 'dept_add' }"
      :options="options"
      @beforeShow="beforeShow"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowSaveChild="rowSaveChild"
      @rowDelete="rowDelete">
      <template slot="deptName" slot-scope="scope">
        <div class="text-primary x-text-normal x-link d-inline" @click="() => $refs.table.actionClick(options.actions[0],scope)">{{ scope.row.deptName }}</div>
      </template>
      <template slot="fullName" slot-scope="scope">
        <div class="text-primary x-text-normal x-link" @click="() => $refs.table.actionClick(options.actions[0],scope)">{{ scope.row.fullName }}</div>
      </template>
    </x-el-table>
    <b-modal
      id="modal-form"
      ref="my-modal"
      cancel-variant="outline-secondary"
      ok-title="保存"
      cancel-title="取消"
      centered
      :visible="childFlag"
      title="新增下级"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <x-form-validator ref="refFormObserver">
        <x-form-feild label="机构名称" require>
          <b-form-input
            v-model="childFormData.deptName"
            placeholder="请输入机构名称"
          />
        </x-form-feild>
        <x-form-feild label="机构全称" require>
          <b-form-input
            v-model="childFormData.fullName"
            placeholder="请输入机构全称"
          />
        </x-form-feild>
        <x-form-feild label="上级机构">
          <b-form-input
            v-model="dept.deptName"
            readonly
          />
        </x-form-feild>
        <x-form-feild label="机构类型" require>
          <v-select
            v-model="childFormData.deptCategory"
            :options="categorys"
            label="dictValue"
            placeholder="请选择机构类型"
            :reduce=" option => option.dictKey"
          >
            <template slot="no-options">
              {{ $t('noData') }}
            </template>
          </v-select>
        </x-form-feild>
        <x-form-feild label="所属租户" require>
          <v-select
            v-model="childFormData.tenantId"
            :options="tenants"
            label="tenantName"
            placeholder="请选择所属租户"
            :reduce=" option => option.tenantId"
          >
            <template slot="no-options">
              {{ $t('noData') }}
            </template>
          </v-select>
        </x-form-feild>
        <x-form-feild label="排序值" require>
          <b-form-input
            v-model="childFormData.sort"
            placeholder="请输入排序值"
          />
        </x-form-feild>
        <x-form-feild label="备注">
          <b-form-textarea
            v-model="childFormData.remark"
            placeholder="请输入备注内容"
          />
        </x-form-feild>
      </x-form-validator>
    </b-modal>
  </div>
</template>

<script>

import XElTable from '@core/components/cx/table/XElTable.vue'
import vSelect from 'vue-select'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import {
  getList, add, update, remove, getCategorys, getTenants,
} from '@/api/system/dept'
import { BModal, BFormInput, BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'Dept',
  components: {
    XElTable,
    XFormValidator,
    XFormFeild,
    BModal,
    BFormInput,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      // 新增下级modal
      childFlag: false,
      dept: {},
      // 机构类型
      categorys: [],
      // 租户列表
      tenants: [],
      // 下级对象
      childFormData: {},
      options: {
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon', permission: 'dept_view' },
          { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon', permission: 'dept_edit' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon', permission: 'dept_delete' },
          { action: 'saveChild', tip: '新增下一级', icon: 'AnchorIcon', permission: 'dept_add_next' },
        ],
        columns: [
          {
            label: '机构名称',
            prop: 'deptName',
            searchShow: true,
            rules: {
              rule: 'required',
              message: '请输入机构名称',
            },
          },
          {
            label: '机构全称',
            prop: 'fullName',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入机构全称',
            },
          },
          {
            label: '上级机构',
            prop: 'parentId',
            searchShow: false,
            rowShow: false,
            type: 'tree',
            rowSelect: true,
            dictUrl: '/apollo-upms/dept/tree',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
            },
          },
          {
            label: '机构类型',
            prop: 'deptCategory',
            searchShow: true,
            type: 'select',
            rowCls: 'badge badge-light-primary',
            dictUrl: '/apollo-upms/dict/dictionary?code=org_category',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            rules: {
              rule: 'required',
              message: '请选择机构类型',
            },
          },
          {
            label: '所属租户',
            prop: 'tenantId',
            searchShow: false,
            editDisable: true,
            editshow: false,
            type: 'select',
            rowCls: 'badge badge-light-primary',
            dictUrl: '/apollo-upms/tenant/select',
            props: {
              label: 'tenantName',
              value: 'tenantId',
            },
            rules: {
              rule: 'required',
              message: '请选择所属租户',
            },
          },
          {
            label: '排序值',
            type: 'number',
            prop: 'sort',
            searchShow: true,
            rules: {
              rule: 'required',
              message: '请输入排序',
            },
          },
          {
            label: '备注',
            type: 'textarea',
            prop: 'remark',
            rowShow: false,
            searchShow: false,
          },
        ],
      },
    }
  },
  methods: {
    beforeShow(type, formData) {
      if (formData.deptCategory) {
        formData.deptCategory = formData.deptCategory.toString()
      }
    },
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data, resp.data.data.length)
      })
    },
    addHandle(data, done) {
      if (data.parentId) {
        data.parentId = data.parentId[data.parentId.length - 1]
      }
      add(data).then(() => {
        done()
      })
    },
    rowEdit(data, done) {
      update(data).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowSaveChild(data) {
      this.dept = data
      this.childFormData.parentId = data.id
      getCategorys().then(resp => {
        this.categorys = resp.data.data
      })
      getTenants().then(resp => {
        this.tenants = resp.data.data
      })
      this.childFlag = true
    },
    resetModal() {
      this.childFormData = {}
      this.dept = {}
      this.childFlag = false
    },
    handleOk() {
      add(this.childFormData).then(() => {
        this.$xtoast.info('添加成功')
        this.$refs.table.__searchHandle()
      })
    },
  },
}
</script>

<style scoped>

</style>
