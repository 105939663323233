<template>
  <b-overlay
    :show.sync="showLoading"
    rounded="sm"
    variant="light"
    opacity="0.85"
    blur="2px"
    spinnerVariant="primary">
    <template #overlay>
      <div class="card p-1">
        <div class="text-center d-flex align-items-center">
          <b-spinner variant="primary"></b-spinner>
          <span class="x-fs-5 x-ml-10px x-text-bold">{{title}}</span>
        </div>
      </div>
    </template>
    <template>
      <slot></slot>
    </template>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BSpinner,
} from 'bootstrap-vue'

export default {
  name: 'XOverlay',
  components: {
    BOverlay,
    BSpinner,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '加载中..',
    },
    // 最大超时事件
    timeout: {
      type: Number,
      default: 5000,
    },
    showToast: {
      type: Boolean,
      default: false,
    },
    timeoutMsg: {
      type: String,
      default: '加载超时，请重试！',
    },
  },
  watch: {
    value() {
      this.showLoading = this.value
      if (this.timer) {
        clearTimeout(this.timer)
      }
      if (this.showLoading === true) {
        this.timer = setTimeout(() => {
          this.showLoading = false
          this.$emit('update:value', this.showLoading)
          if (this.showToast) {
            this.$xtoast.error(this.timeoutMsg)
          }
        }, this.timeout)
      }
    },
    // showLoading() {
    //   this.$emit('update:value', this.showLoading)
    // },
  },
  model: {
    prop: 'value',
    event: 'update:value',
  },
  data() {
    return {
      showLoading: this.value,
      timer: undefined,
    }
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  },
}
</script>

<style scoped>

</style>
