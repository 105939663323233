<template>
  <div >
    <x-el-table
      ref="table"
      :options="options"
      @search="searchHandle"
      @rowDelete="rowDelete"
      @rowSelected="rowSelected"
    >
      <template slot="actions">
        <div>
          <div v-permission="`attach_upload`" class="btn btn-primary mr-1" @click="addHandle">
            <feather-icon icon="UploadCloudIcon"></feather-icon>
            上传
          </div>
          <div v-permission="`attach_delete`" class="btn btn-light-danger" @click="deleteHandle">
            <feather-icon icon="Trash2Icon"></feather-icon>
            删除
          </div>
        </div>
      </template>
      <template slot="name" slot-scope="{ row,data }">
        <div class="d-flex align-items-center">
          <b-avatar v-viewer :src="row.link" variant="light-primary" size="50" square></b-avatar>
          <!--<el-image v-viwer class="attach-image" :src="row.link" fit="cover"></el-image>-->
          <a class="text-primary ml-1" :href="row.link" target="_blank">{{ data | getName }}</a>
        </div>
      </template>
      <template slot="link" slot-scope="{ data }">
        <a v-tooltip.hover="`${data}`" class="text-primary x-text-cut" :href="data" target="_blank">{{ data }}</a>
      </template>
      <template slot="attachSize" slot-scope="{ data }">
        <div>
          <span class="badge badge-light-primary">{{ (data / 1024).toFixed(0) + ' KB' }}</span>
        </div>
      </template>
      <template slot="extension" slot-scope="{ data }">
        <div>
          <span class="badge badge-light-primary">{{ data }}</span>
        </div>
      </template>
    </x-el-table>
    <b-modal
      :visible="showUploadModal"
      title-class="x-text-bold"
      ok-only
      ok-title="取消"
      ok-variant="flat-secondary"
      title="上传附件"
      centered
      @hidden="hide"
      @ok="hide">
      <div class="d-flex justify-content-center mt-4">
        <el-upload
          drag
          :action="`${prefix}/apollo-resource/oss/endpoint/put-file-attach`"
          :headers="headers"
          :before-upload="beforeUpload"
          :on-success="uploadSuccess">
          <i class="el-icon-upload text-primary"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <b-alert show variant="warning" slot="tip">
            <div class="alert-body mt-1">
              <span class="el-icon-info"></span>
              附件大小不超过5MB
            </div>
          </b-alert>
        </el-upload>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BAlert, BAvatar,
} from 'bootstrap-vue'
import XElTable from '@core/components/cx/table/XElTable.vue'
import { getList, remove, Headers } from '@/api/resource/attach'
import website from '@/config/website'

export default {
  name: 'Attach',
  components: {
    BModal,
    XElTable,
    BAlert,
    BAvatar,
  },
  data() {
    return {
      showUploadModal: false,
      selectedRows: [],
      headers: Headers,
      prefix: website.prefix,
      options: {
        rowSelectable: true,
        addBtn: false,
        actions: [
          { action: 'download', tip: '下载', icon: 'DownloadCloudIcon', permission: 'attach_download', website: '{link}' },
          // { action: 'preview', name: '预览', icon: 'AirplayIcon', svg2: 'media/icons/duotune/abstract/abs012.svg', tip: '预览' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon', permission: 'attach_delete' },
        ],
        columns: [
          {
            label: '图片',
            prop: 'name',
          },
          {
            label: '原名',
            prop: 'originalName',
          },
          {
            label: '地址',
            prop: 'link',
            type: 'link',
            searchShow: false,
          },
          {
            label: '附件扩展名',
            prop: 'extension',
          },
          {
            label: '附件大小',
            prop: 'attachSize',
            searchShow: false,
          },
          {
            label: '上传时间',
            prop: 'updateTime',
            searchShow: false,
          },
        ],
      },
    }
  },
  filters: {
    getName(name) {
      return name.substring(name.lastIndexOf('/') + 1)
    },
  },
  methods: {
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    addHandle() {
      this.showUploadModal = true
    },
    uploadSuccess() {
      this.$refs.table.__searchHandle()
    },
    deleteHandle() {
      if (!this.selectedRows || this.selectedRows.length === 0) {
        this.$xtoast.warning('请选择要删除的数据！')
        return
      }
      remove(this.selectedRows.map(item => item.id).join(',')).then(() => {
        this.$refs.table.__searchHandle()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    rowSelected(rows) {
      this.selectedRows = rows
    },
    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$xtoast.error('上传附近大小不能超过5MB！')
      }
      return isLt5M
    },
    hide() {
      this.showUploadModal = false
    },
  },
}
</script>

<style scoped>
.attach-image {
  width: 40px;
  height: 40px;
}
</style>
