import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-jxz/gm/merchant/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const profile = id => request.axiosIns({
  url: `/apollo-jxz/gm/merchant/detail/${id}`,
  method: 'get',
})

export const getAll = params => request.axiosIns({
  url: '/apollo-jxz/gm/merchant/all',
  method: 'get',
  params: {
    ...params,
  },
})

export const remove = ids => request.axiosIns({
  url: '/apollo-resource/oss/remove',
  method: 'post',
  params: {
    ids,
  },
})

export const add = row => request.axiosIns({
  url: '/apollo-resource/oss/submit',
  method: 'post',
  data: row,
})

export const edit = row => request.axiosIns({
  url: '/apollo-resource/oss/submit',
  method: 'post',
  data: row,
})

export const enable = id => request.axiosIns({
  url: '/apollo-resource/oss/enable',
  method: 'post',
  params: {
    id,
  },
})
// 开通商户
export const open = (id, data) => request.axiosIns({
  url: `/apollo-jxz/gm/merchant/open/package/${id}`,
  method: 'post',
  data,
})

// 设置商户分销比例
export const setScale = (id, scale, softwareScale) => request.axiosIns({
  url: `/apollo-jxz/gm/merchant/scale/set/${id}`,
  method: 'post',
  params: {
    scale,
    softwareScale,
  },
})

export const setActiveCode = id => request.axiosIns({
  url: `/apollo-jxz/gm/merchant/active/set/${id}`,
  method: 'post',
})
