import request from '@/auth/jwt/useJwt'

export function getList(current, size, params) {
  return request.axiosIns({
    url: '/apollo-upms/tenant/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  })
}

export function getDetail(id) {
  return request.axiosIns({
    url: '/apollo-upms/tenant/detail',
    method: 'get',
    params: {
      id,
    },
  })
}

export function remove(ids) {
  return request.axiosIns({
    url: '/apollo-upms/tenant/remove',
    method: 'post',
    params: {
      ids,
    },
  })
}

export function add(row) {
  return request.axiosIns({
    url: '/apollo-upms/tenant/submit',
    method: 'post',
    data: row,
  })
}

export function update(row) {
  return request.axiosIns({
    url: '/apollo-upms/tenant/submit',
    method: 'post',
    data: row,
  })
}

export function authzSetting(ids, form) {
  return request.axiosIns({
    url: '/apollo-upms/tenant/setting',
    method: 'post',
    params: {
      ...form,
      ids,
    },
  })
}

export function datasource(tenantId, datasourceId) {
  return request.axiosIns({
    url: '/apollo-upms/tenant/datasource',
    method: 'post',
    params: {
      tenantId,
      datasourceId,
    },
  })
}

export function info(domain) {
  return request.axiosIns({
    url: '/apollo-upms/tenant/info',
    method: 'get',
    params: {
      domain,
    },
  })
}

export function packageInfo(tenantId) {
  return request.axiosIns({
    url: '/apollo-upms/tenant/package-detail',
    method: 'get',
    params: {
      tenantId,
    },
  })
}

export function packageSetting(tenantId, packageId) {
  return request.axiosIns({
    url: '/apollo-upms/tenant/package-setting',
    method: 'post',
    params: {
      tenantId,
      packageId,
    },
  })
}
