<template>
  <div>
    <x-el-table
      ref="table"
      :options="options"
      @search="searchHandle"
      @rowAdd="rowAdd"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete">
    </x-el-table>
  </div>
</template>

<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import {
  getList, add, edit, remove,
} from '@/api/marketing/auth-package'

export default {
  name: 'AuthPackage',
  components: {
    XElTable,
  },
  data() {
    return {
      options: {
        actions: [
          { action: this.$x.biz.FormType.VIEW, tip: '查看', icon: 'EyeIcon' },
          { action: this.$x.biz.FormType.EDIT, tip: '编辑', icon: 'EditIcon' },
          { action: this.$x.biz.FormType.DELETE, tip: '删除', icon: 'Trash2Icon' },
        ],
        columns: [
          {
            label: '套餐价格',
            prop: 'price',
            searchShow: false,
            rules: { rule: 'required', message: '请输入套餐价格（元）' },
          },
          {
            label: '核验次数',
            prop: 'count',
            searchShow: false,
            rules: { rule: 'required', message: '请输入核验次数' },
          },
          {
            label: '创建时间',
            prop: 'createTime',
            searchShow: false,
            addShow: false,
            editShow: false,
          },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data, resp.data.data.length)
      })
    },
    rowAdd(data, done) {
      add(data).then(() => {
        done()
      })
    },
    rowEdit(data, done) {
      edit(data).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
  },
}
</script>

<style lang="scss">
</style>
