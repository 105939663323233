<template>
  <div>
    <x-el-table
      :permissions="{ add: 'client_add' }"
      :options="options"
      @beforeShow="beforeShow"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete">
      <template slot="authorizedGrantTypes" slot-scope="{row}">
        <b-badge variant="primary" v-for="item in row.authorizedGrantTypes.split(',')" class="mr-50" :key="item.index">
          {{item}}
        </b-badge>
      </template>
    </x-el-table>
  </div>
</template>

<script>

import XElTable from '@core/components/cx/table/XElTable.vue'
import {
  getList, add, update, remove,
} from '@/api/system/client'
import { BBadge } from 'bootstrap-vue'

export default {
  name: 'Client',
  components: {
    XElTable,
    BBadge,
  },
  data() {
    return {
      options: {
        formType: 'sidebar',
        actions: [
          { action: 'view', tip: '查看', icon: 'EyeIcon', permission: 'client_view' },
          { action: 'edit', tip: '编辑', icon: 'EditIcon', permission: 'client_edit' },
          { action: 'delete', tip: '删除', icon: 'Trash2Icon', permission: 'client_delete' },
        ],
        columns: [
          {
            label: '应用id',
            prop: 'clientId',
            searchShow: true,
            rules: {
              rule: 'required',
              message: '请输入应用id',
            },
          },
          {
            label: '应用密钥',
            prop: 'clientSecret',
            searchShow: true,
            rules: {
              rule: 'required',
              message: '请输入应用密钥',
            },
          },
          {
            label: '授权类型',
            type: 'checkbox',
            width: '90',
            dictData: [
              {
                label: 'refresh_token',
                value: 'refresh_token',
              },
              {
                label: 'password',
                value: 'password',
              },
              {
                label: 'authorization_code',
                value: 'authorization_code',
              },
              {
                label: 'captcha',
                value: 'captcha',
              },
              {
                label: 'social',
                value: 'social',
              },
            ],
            rowSelect: true,
            prop: 'authorizedGrantTypes',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请选择授权类型',
            },
          },
          {
            label: '授权范围',
            prop: 'scope',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入授权范围',
            },
          },
          {
            label: '令牌秒数',
            prop: 'accessTokenValidity',
            searchShow: false,
            rules: {
              rule: 'required',
              message: '请输入令牌秒数',
            },
          },
          {
            label: '刷新秒数',
            prop: 'refreshTokenValidity',
            searchShow: false,
            rowShow: false,
            rules: {
              rule: 'required',
              message: '请输入刷新秒数',
            },
          },
          {
            label: '回调地址',
            prop: 'webServerRedirectUri',
            searchShow: false,
            rowShow: false,
            rules: {
              rule: 'required',
              message: '请输入回调地址',
            },
          },
          {
            label: '资源集合',
            prop: 'resourceIds',
            searchShow: false,
            rowShow: false,
          },
          {
            label: '权限',
            prop: 'authorities',
            searchShow: false,
            rowShow: false,
          },
          {
            label: '自动授权',
            prop: 'autoapprove',
            searchShow: false,
            rowShow: false,
          },
          {
            label: '附加说明',
            prop: 'additionalInformation',
            searchShow: false,
            rowShow: false,
          },
        ],
      },
    }
  },
  methods: {
    beforeShow(type, formData) {
      if (type.isAdd) {
        formData.authorizedGrantTypes = ['refresh_token', 'password', 'authorization_code']
        formData.scope = 'all'
        formData.accessTokenValidity = 3600
        formData.refreshTokenValidity = 604800
      } else {
        formData.authorizedGrantTypes = formData.authorizedGrantTypes.split(',')
      }
    },
    searchHandle(page, params, done) {
      getList(page.pageIndex, page.pageSize, params).then(resp => {
        done(resp.data.data.records, resp.data.data.total)
      })
    },
    addHandle(data, done) {
      let authorizedGrantTypes = ''
      data.authorizedGrantTypes.forEach((item, index) => {
        if (index < data.authorizedGrantTypes.length - 1) {
          authorizedGrantTypes = authorizedGrantTypes + item + ','
        } else {
          authorizedGrantTypes += item
        }
      })
      data.authorizedGrantTypes = authorizedGrantTypes
      add(data).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowEdit(data, done) {
      let authorizedGrantTypes = ''
      data.authorizedGrantTypes.forEach((item, index) => {
        if (index < data.authorizedGrantTypes.length - 1) {
          authorizedGrantTypes = authorizedGrantTypes + item + ','
        } else {
          authorizedGrantTypes += item
        }
      })
      data.authorizedGrantTypes = authorizedGrantTypes
      update(data).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      }).catch(() => {
        done()
      })
    },
  },
}
</script>

<style scoped>

</style>
