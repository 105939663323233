import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-workbench/notice/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const remove = ids => request.axiosIns({
  url: '/apollo-workbench/notice/remove',
  method: 'post',
  params: {
    ids,
  },
})

export const add = row => request.axiosIns({
  url: '/apollo-workbench/notice/submit',
  method: 'post',
  data: row,
})

export const update = row => request.axiosIns({
  url: '/apollo-workbench/notice/submit',
  method: 'post',
  data: row,
})

export const getNotice = id => request.axiosIns({
  url: '/apollo-workbench/notice/detail',
  method: 'get',
  params: {
    id,
  },
})
