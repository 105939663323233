import request from '@/auth/jwt/useJwt'

export const getList = (current, size, params) => request.axiosIns({
  url: '/apollo-resource/oss/list',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  },
})

export const getDetail = id => request.axiosIns({
  url: '/apollo-resource/oss/detail',
  method: 'get',
  params: {
    id,
  },
})

export const remove = ids => request.axiosIns({
  url: '/apollo-resource/oss/remove',
  method: 'post',
  params: {
    ids,
  },
})

export const add = row => request.axiosIns({
  url: '/apollo-resource/oss/submit',
  method: 'post',
  data: row,
})

export const update = row => request.axiosIns({
  url: '/apollo-resource/oss/submit',
  method: 'post',
  data: row,
})

export const enable = id => request.axiosIns({
  url: '/apollo-resource/oss/enable',
  method: 'post',
  params: {
    id,
  },
})
