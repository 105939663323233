import request from '@/auth/jwt/useJwt'

export const setup = data => request.axiosIns({
  url: '/apollo-jxz/marketing/setup',
  method: 'put',
  data,
})

export const detail = () => request.axiosIns({
  url: '/apollo-jxz/marketing/setup',
  method: 'get',
})
